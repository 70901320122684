import React, { useState } from 'react'
import { LazyLoadImage } from "react-lazy-load-image-component";
import { LazyLoader } from './LazyLoader';
import { Box } from '@material-ui/core';

interface LazyLoadImageComponentProps {
    mainImage: string;
    blurImage: string;
  }



const LazyLoadImageComponent: React.FC<LazyLoadImageComponentProps>=({mainImage,blurImage})=>{

    const [isLoading, setIsLoading] = useState(true); 

    
    const handleBeforeLoad = () => {
      setIsLoading(true);
    };
  
    
    const handleAfterLoad = () => {

        setIsLoading(false);
    
    };




return(
    
<div>

   {isLoading && (
   <div
         style={{
           position: "absolute",
           right: "20%",
           top:"50%",
           zIndex: 1000,
         }}
         className='responsive'
       >
         <Box sx={{ display: "flex" }}>
         <LazyLoader />
         </Box>
         
       </div>
     )}




       <LazyLoadImage
               src={mainImage}
               style={{ width: "100%",objectFit: "cover",height: "calc(100vh - 71px)" ,objectPosition:"bottom"}}
               effect="blur"
               placeholderSrc={blurImage}          
               alt="Main Image"
               wrapperClassName="lazy-load-wrapper"
               beforeLoad={handleBeforeLoad} 
               onLoad={handleAfterLoad}  
                />

<style>
    {`
    
    .lazy-load-wrapper {
        width: 100%;
        height: calc(100vh - 71px);
      }

      @media (max-width:959px)
      {
      .responsive{
        position: absolute !important;
        right: 50% !important;
        z-index: 1000 !important;
        bottom: -39% !important;
        top: auto !important;
      }
    }
    
    `}
</style>


    </div>
)


}

export default LazyLoadImageComponent;