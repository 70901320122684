Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.dashboardGetUrl = "/bx_block_dashboard/candidates";
exports.companyListGetUrl = "account_block/company_list";
exports.dashboardApiEndPoint = "bx_block_customfeatureintegrationwithkyctoolsumsub/kyc_checks/check_list"
exports.analysisDataApiEndPoint = "bx_block_customfeatureintegrationwithkyctoolsumsub/kyc_checks/review_status_count"
exports.dashboarContentType = "application/json";
exports.dashboarApiMethodType = "GET";
exports.dashboardHost = "<calculated when request is sent>";
exports.dashboarUserAgent = "PostmanRuntime/7.26.5";
exports.dashboarAccept = "*/*";
exports.dashboarAcceptEncoding = "gzip, deflate, br";
exports.dashboarConnection = "keep-alive";
exports.dashboartoken = "";
exports.labelTitleText = "dashboard";
exports.totalCandidateslabelTitleText = "Total Candidates";
exports.viewDetailsBtn = "View Details";
exports.candidateslabelTitleText = "Candidates";
exports.baseUrl = ""
exports.labelBodyText = "dashboard Body";
exports.homeText = "Home";
exports.approvedVerify = "Approved Verification";
exports.numberApprove = "50";
exports.rejectedverify = "Rejected Verification";
exports.pendingVerify = "Pending Verification";
exports.clarificationText = "Need Clarification";
exports.uploadVerify = "Uploaded Verifications";
exports.nameText = "Name";
exports.kycDate = "KYC Date";
exports.passportNumber = "Passport No";
exports.documentText = "Documents";
exports.statusText = "Status";
exports.loadBtn = "Load more";
exports.noDataText = "No Data Found";
exports.approvedText = "Approved";
exports.pendingText = "Pending";
exports.passportPdf = "passport.pdf";

// Customizable Area End