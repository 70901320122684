import React from 'react'
import { Box, Dialog, DialogTitle, Grid, Typography,styled} from '@material-ui/core';
import CloseIcon from '@mui/icons-material/Close';
interface ProviderDialogProps {

    open: boolean;
    disc: PrivacyPolicy;
    onClose: ()=>void;
    logo:string;
  }

  interface PrivacyPolicy {
    id: string;
    type: string;
    attributes: Attributes;
  }
  
  interface Attributes {
    description: string;
    title: string;
  }
  

const PrivacyDialog: React.FC<ProviderDialogProps>=({open,onClose,disc,logo})=>{
    const handleIconClick = (event: React.MouseEvent) => {
        event.stopPropagation();
        onClose(); 
      };



return(


<Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>


  <TermsConditionsStyles style={{ width: "100%", height: "100%", overflowX:"hidden" }}>
    
    <Box className="headerFiled">
          <Box style={{color:'black',padding:'5px 10px 0px 0px',display:'flex',justifyContent:'flex-end',width:'calc(100% - 3px)',height:'50px'}}><CloseIcon onClick={handleIconClick} className="closeIcon" /></Box>       
      <Grid item xl={12} xs={12} lg={12} sm={12}>
        <Box className="headerWrapper" style={{display:'flex',justifyContent:'center'}}>
          <Typography className="headerName">Privacy Policy</Typography>
        </Box>
      </Grid>
    </Box>

    <Box style={{background:'#242424'}}>
          <Grid item xl={12} xs={12} lg={12} sm={12}>
            <Box className="contentWrapper">

             
            
                  <Typography className="condTitle">
                    <h2>{disc.attributes.title}</h2>
                  </Typography>
                    <Typography className="termsContent"  dangerouslySetInnerHTML={{ __html: disc?.attributes?.description }}
                    >
                      
                    </Typography>

                    
             
             
             
               
            </Box>
          </Grid>
        </Box>





  </TermsConditionsStyles>
</Dialog>



)


}


const TermsConditionsStyles = styled(Box)({
  "& .headerFiled":{
    height: '140px',
    top: '80px',
    gap: '10px',
    opacity: '0px',
    background: '#FFFFFF',
  },
  "& .headerName":{
    fontFamily: 'Lato',
    fontSize: '48px',
    fontWeight: 700,
    lineheight: '56px',
    letterSpacing: '-0.015em',
    textAlign: 'left',
    color: '#00FF00'

  },
  "& .headerWrapper":{
    display: "flex",
    alignItems: 'center',
    padding: '0px 112px',
    gap: '20px'
  },
  "& .contentWrapper":{
    top: '296px',
    gap: '40px',
    opacity: '0px',
    width:'calc(100% - 120px)',
    padding:'60px'
  },
  "& .condTitle":{
    fontFamily: 'Lato',
    fontWeight: 600,
    lineHeight: '16px',
    textAlign: 'left',
    color:'#D3D3D3',
    paddingBottom: '2px'
  },
  "& .termsContent":{
    fontFamily: 'Lato',
    fontWeight: 500,
    lineHeight: '16px',
    textAlign: 'justify',
    color:'#7C7C7C',
    fontSize:' 16px',
    paddingBottom: '2px',
  

  },
  "& .checkBox":{
  width: "20px",
  height: "20px",
  cursor: "pointer",  
  paddingLeft:"0px",
  marginRight: '8px',
  color: '#D3D3D3',
  '& .MuiSvgIcon-root': {
    fontSize: '20px',
  },
  '&.Mui-checked': {
    color: '#00FF00 !important', 
  },
},
'& .customBtn':{
  minWidth: '116px',
  height: '48px',
  marginTop: '16px',
},
'& .btntypo':{
  fontFamily: 'Lato',
  fontWeight: 700,
  fontSize: '16px',
  lineHeight: '24px',
  color: '#242424',
  textTransform: 'none',
  padding:"12px 20.5px"
},

"& .closeIcon": {
  width: '35px',
  height: '35px',
  cursor: 'pointer', 
},




});






export default PrivacyDialog;