// Customizable Area Start
import React from "react";
import {
    Box,
    Typography,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Button,
    Input,
    TableContainer,
    IconButton
} from "@material-ui/core";
// Customizable Area End

// Customizable Area Start
import { styled } from '@material-ui/styles'
import SearchIcon from '@material-ui/icons/Search';
import AddIcon from '@material-ui/icons/Add';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import CreateEmployeeController, {
    Props,

    configJSON,
} from "./CreateEmployeeController.web";
import Headers from '../../../components/src/Headers.web';
// Customizable Area End

export default class CreateEmployee extends CreateEmployeeController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    renderEmployeForm = () => {
        return (
            <MainContent>
                <PageTop className="pageMain">
                    <Typography className="headerText" >{configJSON.userInformation}</Typography>
                    <Box className="searchContainer">
                        <Input
                            className="searchInputfield"
                            fullWidth
                            placeholder="Search"
                            disableUnderline
                            name="cardNumber"
                            data-test-id="cardNumberTestId"
                            startAdornment={
                                <SearchIcon className="searchIcon" />
                            }
                        />
                        <Button startIcon={<AddIcon />} data-test-id="navigationToKyc" className="searchBtn" variant="contained">{configJSON.verifyText}</Button>
                    </Box>
                </PageTop>
                <StyledTableContainer>
                    <StyledTable>
                        <TableHead className="nameTableRow">
                            <TableRow>
                                <TableCell align={"left"} className="nameTableCell">{configJSON.employeeName}</TableCell>
                                <TableCell size={"small"} className="dobTableCell">{configJSON.emailId}</TableCell>
                                <TableCell className="nationalityTableCell">{configJSON.contactName}</TableCell>
                                <TableCell className="countryTableCell">{configJSON.contactNumber}</TableCell>
                                <TableCell className="addressTableCell">{configJSON.kycStatus}</TableCell>
                                <TableCell className="passportTableCell">{configJSON.actionPerform}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                this.state?.tableData?.map((singleData) => {
                                    return (
                                        <TableRow key={singleData.id}>
                                            <TableCell className="nameTxt">{singleData.name}</TableCell>
                                            <TableCell className="dobTableTxt">{singleData.emailId}</TableCell>
                                            <TableCell className="nationalityText">{singleData.contactName}</TableCell>
                                            <TableCell className="countryTableText">{singleData.phoneNumber}</TableCell>
                                            <TableCell className="addressText">{singleData.kycCheck}</TableCell>
                                            <IconButton><MoreVertIcon style={{ color: "white" }} /></IconButton>
                                            <TableCell>
                                            </TableCell>
                                        </TableRow>
                                    )
                                })
                            }
                        </TableBody>
                    </StyledTable>
                </StyledTableContainer>
                <Box className="loadMoreBtnBox">
                    <Button className="loadMoreBtn">{configJSON.loadText}</Button>
                </Box>
            </MainContent>
        )
    }
    // Customizable Area End

    render() {
        return (
            <>
                <Headers sliderButton={this.state.sideBarClose}
                    logOutToogle={this.logOutBtn}
                    data-testId="logoutTestId"
                    onToggleClick={this.handleSliderButton}
                    goToScreen={this.navigateToScreen}
                    isRouteData="CreateEmployee"
                >
                    {this.renderEmployeForm()}
                </Headers>
            </>
            // Customizable Area Start
            // Customizable Area End
        );
    }
}
// Customizable Area Start
const PageTop = styled(Box)({
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    height: "44px",
    paddingBottom: "20px",
    "& .searchContainer": {
        display: "flex",
        justifyContent: "flex-start",
        gap: "15px",
        outline: "none",
        alignItems: "center",
        border: "none",
        borderRadius: "0px",
    },
    "& .searchInputfield": {
        backgroundColor: "#505050",
        color: "#fff",
        height: "44px",
        fontSize: "16px",
        fontWeight: 400,
        fontFamily: "Lato",
        width: "320px",
        outline: "none",
        border: "none",
        borderRadius: "0",
        padding: "10px",
        "& .MuiInputBase-input": {
            width: "272px",
            height: "24px",
            fontFamily: "Lato",
        }
    },
    "& .MuiOutlinedInput-inputAdornedStart": {
        color: "#A7A7A7",
        height: "7px",
    },
    "& .searchIcon": {
        color: "#A7A7A7",
        marginRight: "20px",
        width: "17.05px",
        height: "17.06px"
    },
    "& .searchBtn": {
        height: "44px",
        minWidth: "181px",
        padding: "10px 18px",
        backgroundColor: "#00FF00",
        color: "#242424",
        borderRadius: "0px",
        textTransform: "none",
        fontSize: "16px",
        fontWeight: 600,
        fontFamily: "Lato"
    },
    "& .nameTableRow": {
        width: "100%",
    },
    "& .pageMain": {
        height: "44px",
        width: "1333px",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center"
    },

})
const StyledTableContainer = styled(TableContainer)({
    width: "Fixed (1,264px)px",
    height: "Fixed (41px)px",
    gap: "24px",
    opacity: "0px",

    "& .userImg": {
        width: "48px",
        height: "48px",
        borderRadius: "48px"
    }
});

const StyledTable = styled(Table)({
    backgroundColor: "#18191b",
    "& .MuiTableCell-root": {
        verticalAlign: "top",
        borderBottom: "1px solid #272B30",
    },
    "& .MuiTableCell-head": {
        color: "#fff",
        width: "max-content",
    },
    "& .MuiTableCell-body": {
        color: "rgba(111, 118, 126, 1)",
        fontSize: "14px",
        fontWeight: 600
    },
    "& .nameTxt": {
        color: "rgba(111, 118, 126, 1)",
        fontWeight: 600,
        fontFamily: 'Lato',
        height: "24px",
        width: "120px",
        fontSize: "14px",
    },
    "& .dobTableTxt": {
        fontWeight: 600,
        color: "rgba(111, 118, 126, 1)",
        fontSize: "14px",
        fontFamily: 'Lato',
        height: "81px",
        width: "175px"
    },
    "& .countryTableText": {
        color: "rgba(111, 118, 126, 1)",
        fontSize: "14px",
        fontWeight: 600,
        width: "118px",
        height: "24px",
        fontFamily: 'Lato',
        whiteSpace: "nowrap"
    },
    "& .passportTextTable": {
        width: "81px",
        height: "24px",
        color: "rgba(111, 118, 126, 1)",
        fontSize: "14px",
        fontFamily: 'Lato',
        fontWeight: 600,
    },
    "& .nationalityText": {
        width: "100px",
        color: "rgba(111, 118, 126, 1)",
        fontSize: "14px",
        fontWeight: 600,
        fontFamily: 'Lato',
        height: "24px"
    },
    "& .addressText": {
        color: "rgba(111, 118, 126, 1)",
        fontSize: "14px",
        fontWeight: 600,
        fontFamily: 'Lato',
        width: "249px",
        height: "48px"
    },
    "& .lato": {
        fontFamily: 'Lato'
    },
    "& .dobTableCell": {
        width: "200px",
        fontFamily: 'Lato',
        color: 'rgba(211, 211, 211, 1)',
        height: '16px',
        gap: '0px',
        opacity: "0px",
        fontWeight: 600,
        whiteSpace: "nowrap"

    },
    "& .nameTableCell": {
        width: "200px",
        fontFamily: 'Lato',
        color: 'rgba(211, 211, 211, 1)',
        height: '16px',
        gap: '0px',
        opacity: "0px",
        fontWeight: 600,
    },
    "& .nationalityTableCell": {
        color: 'rgba(211, 211, 211, 1)',
        width: "200px",
        fontFamily: 'Lato',
        height: '16px',
        gap: '0px',
        opacity: "0px",
        fontWeight: 600
    },
    "& .countryTableCell": {
        fontFamily: 'Lato',
        color: 'rgba(211, 211, 211, 1)',
        height: '16px',
        width: "200px",
        gap: '0px',
        opacity: "0px",
        fontWeight: 600
    },
    "& .addressTableCell": {
        width: "200px",
        color: 'rgba(211, 211, 211, 1)',
        fontFamily: 'Lato',
        height: '16px',
        gap: '0px',
        opacity: "0px",
        fontWeight: 600
    },
    "& .passportTableCell": {
        width: "37px",
        fontFamily: 'Lato',
        fontWeight: 600,
        color: 'rgba(211, 211, 211, 1)',
        height: '16px',
        gap: '0px',
        opacity: "0px",
    },
    "& .documentTableCell": {
        gap: '0px',
        width: "70px",
        fontFamily: 'Lato',
        color: 'rgba(211, 211, 211, 1)',
        height: '16px',
        opacity: "0px",
        fontWeight: 600
    },
    "& .statusTableCell": {
        width: "70px",
        fontFamily: 'Lato',
        color: 'rgba(211, 211, 211, 1)',
        fontWeight: 600,
        height: '16px',
        gap: '0px',
        opacity: "0px",
    },
    "& .documentNameText": {
        fontFamily: 'Lato',
        fontWeight: 600,
        color: "rgba(111, 118, 126, 1)"
    }
})

const MainContent = styled(Box)({
    padding: "20px",
    backgroundColor: "rgba(26, 29, 31, 1)",
    fontFamily: 'Lato',
    "& .headerText": {
        display: "flex",
        color: "rgba(252, 252, 252, 1)",
        fontSize: "40px",
        fontWeight: 600,
        fontFamily: 'Lato',
        height: "32px",
        marginBottom: "20px",
    },
    "& .loadMoreBtnBox": {
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginTop: "16px"
    },
    "& .loadMoreBtn": {
        textTransform: "none",
        color: "white",
        height: "48px",
        border: "2px solid rgba(39, 43, 48, 1)",
        fontSize: "15px",
        fontWeight: 700,
        borderRadius: "12px",
        fontFamily: 'Lato',
    },
})
// Customizable Area End
