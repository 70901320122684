import { ArrowBackIos, ArrowForwardIos } from '@material-ui/icons'
import { Box } from '@material-ui/core'
import { List, ListItem, ListItemIcon, ListItemText} from '@material-ui/core'
import React, { Component } from 'react'
import { styled } from '@material-ui/styles';
import { getStorageData } from '../../framework/src/Utilities';
import useMediaQuery from '@material-ui/core/useMediaQuery';
type MyProps = {
  sliderButton: boolean | string;
  onToggleClick?: () => void;
  logOutToogle?: () => void;
  goToScreen: (screenName: string) => void;
  isRouteData: string
}
interface MyState {
  userName: string;
  userProfile: string;
  profileImage: string;
  toggle: boolean;
}
export default class Headers extends Component<MyProps,MyState> {

  state = {
    userName: "",
    userProfile: "Project Manager",
    profileImage: "",
    toggle: localStorage.getItem("sideBarToggle")!="false" ? true : false
  };

  async componentDidMount() {
    const firstName = await getStorageData("first_name");
    const lastName = await getStorageData("last_name");
    const profileImage = await getStorageData("image");
    const employeeName = await getStorageData("employee_name");
    const designation = await getStorageData("designation");

    this.setState({
      userName: employeeName !== null ? employeeName : "",
      profileImage,
      userProfile: (designation !== null) ? designation : "Project Manager"
    });
  }
  toggleFuncton =()=>{
    localStorage.setItem("sideBarToggle", String(this.state.toggle==true ? false : true))
    this.setState((prevState) => ({ toggle: !prevState.toggle }))
  }
  removeFunction =()=>{ 
    if (this.props.logOutToogle) {
      this.props.logOutToogle(); 
    }   
    localStorage.removeItem("image");
    localStorage.removeItem("employee_name")

    this.setState({
      userName: "",
    });
  }
  
  renderSidebarPart = () => {
    const { goToScreen } = this.props;
    
    return (
      <Box className={"drawer"}>
        <Box className='drawerTestTwo'>
          <Box onClick={() => goToScreen("Dashboard")} className={"scaleBodys"}>
            <img onClick={() => goToScreen("Dashboard")} src={require("./1787812061XsWCJB8BYR6V3rZXuOVHwS.svg")} alt="Scale KYC Icons" className="scaleKYC" />
          </Box>
          <Box className="arrowBackBoxs">
            <Box className={"arrowIcon"} onClick={this.toggleFuncton}>
              <ArrowBackIos className={"arrowForward"} />
            </Box>
          </Box>
        </Box>
        <Box className={"box"}>
          <List className={"listBody"}>
            <Box onClick={() => goToScreen("Dashboard")}>
              <ListItem className={this.props.isRouteData === "Dashboard" ? "list activeBtn" : 'list'}>
                <ListItemIcon className={"listIcon"}>
                  <img src={this.props.isRouteData === "Dashboard" ? require("./vector_Green.svg") : require("./vector_.svg")} className={this.props.isRouteData === "Dashboard" ? "icon activeBtnTwo" : 'icon'}/>
                </ListItemIcon>
                <ListItemText className="listTxt">
                  <span className="employeeText">Home</span>
                </ListItemText>
              </ListItem>
            </Box>
            <Box onClick={() => goToScreen("CustomFormUserList")}>
              <ListItem className={this.props.isRouteData === "CustomFormUserList" ? "list activeBtn" : 'list'}>
                <ListItemIcon className={"listIcon"}>
                <img src={this.props.isRouteData === "CustomFormUserList" ? require("./groupGreen.svg"):require("./group_.svg")}  className={this.props.isRouteData === "CustomFormUserList" ? "icon activeBtnTwo" : 'icon'} />
                </ListItemIcon>
                <ListItemText className="userVerification">
                  <span className="userVerification">Users Verification</span>
                </ListItemText>
              </ListItem>
            </Box>
            <Box onClick={() => goToScreen("Contactus")}>
              <ListItem className={this.props.isRouteData === "Contactus" ? "list activeBtn" : 'list'}>
                <ListItemIcon className={"listIcon"}>
                <img src={this.props.isRouteData === "Contactus" ? require("./group_callGreen.svg"): require("./group_call.svg")} className={this.props.isRouteData === "Contactus" ? "icon activeBtnTwo" : 'icon'} />
                </ListItemIcon>
                <ListItemText className="contactUsText">
                  <span className='contactUsText'>Contact Us</span>
                </ListItemText>
              </ListItem>
            </Box>
          </List>
          <List className={"listLastBody"}>
            <ListItem className={"listLast"} onClick={this.removeFunction}>
              <ListItemIcon className={"listIcon"}>
                <img src={require("./group_layer.svg")} className={"iconLast"} />
              </ListItemIcon>
              <ListItemText className='logoutBtn' primary="Log Out" />
            </ListItem>
          </List>
        </Box>
      </Box>
    )
  }

  renderSidebarPartTwo = () => {
    const { goToScreen } = this.props;
    return (
      <Box className={"drawers"}>
        <Box className="arrowBackBoxTwo">
        <Box onClick={() => goToScreen("Dashboard")} className={"scaleBodys2"}>
            <img onClick={() => goToScreen("Dashboard")} src={require("./1787812061XsWCJB8BYR6V3rZXuOVHwS.svg")} alt="Scale KYC Icons" className="scaleKYC2" />
          </Box>
          {window.matchMedia('(min-width:1000px)').matches && <Box className={"arrowIcon2"} onClick={this.toggleFuncton}>
            <ArrowForwardIos />
          </Box>
          }
         </Box>
        <Box className={"box"}>
          <List className={"listBodys"}>
            <ListItem className={"lists"}>
              <Box onClick={() => goToScreen("Dashboard")} className="listItemWrapper">
                <ListItemIcon className={this.props.isRouteData === "Dashboard" ? "listIcon activeBtnTwo" : 'listIcon'}>
                <img src={this.props.isRouteData === "Dashboard" ? require("./vector_Green.svg") : require("./vector_.svg")} className={"icon"} />
                </ListItemIcon>
              </Box>
            </ListItem>
            <ListItem className={"lists"}>
              <Box onClick={() => goToScreen("CustomFormUserList")} className="listItemWrapper">
                <ListItemIcon className={"listIcon"}>
                <img src={this.props.isRouteData === "CustomFormUserList" ? require("./groupGreen.svg"):require("./group_.svg")} className={this.props.isRouteData === "CustomFormUserList" ? "icon activeBtnTwo" : 'icon'} />
                </ListItemIcon>
              </Box>
            </ListItem>
            <ListItem className={"lists"}>
              <Box onClick={() => goToScreen("Contactus")} className="listItemWrapper">
                <ListItemIcon className={"listIcon"}>
                <img src={this.props.isRouteData === "Contactus" ? require("./group_callGreen.svg"): require("./group_call.svg")} className={this.props.isRouteData === "Contactus" ? "icon activeBtnTwo" : 'icon'} alt="icons"/>
                </ListItemIcon>
              </Box>
            </ListItem>
          </List>
          <List className={"listLastBody"}>
            <ListItem className={"listLast"}>
              <ListItemIcon className={"listIconTwo"}>
                <img src={require("./group_layer.svg")} className={"iconLast"}  onClick={this.removeFunction} />
              </ListItemIcon>
            </ListItem>
          </List>
        </Box>
      </Box>
    )
  }

  renderHeaderPart = () => {
    const { goToScreen } = this.props;
    const { userName, userProfile, profileImage } = this.state;
    const imageSrc = profileImage ? profileImage : require("./blankProfile.jpeg");
    return (
      <Box className={"headerChild"}>
        <Box className={"headerRight"}>
          <Box>
            <Box className={"userName"}>{userName.charAt(0).toUpperCase() + userName.slice(1).toLowerCase()}</Box>
            <Box className={"userProfile"}>{userProfile}</Box>
          </Box>
          <Box onClick={() => goToScreen("UserProfileBasicWeb")}>
            <Box className={"imageBox"}>
              <img src={imageSrc} alt="User Icons" className="userImg" />
            </Box>
          </Box>
        </Box>
      </Box>
    )
  }

  render() {
    return (
      <HeaderContainer>
        <Box className={"headerContainer"} style={{ display: "flex",overflow:"hidden",height:"100%",width:"100%"  }}>
          <Box >
          {this.state.toggle && window.matchMedia('(min-width:1000px)').matches ? this.renderSidebarPart() : this.renderSidebarPartTwo() }

          </Box>
          <Box className={"bodyGrid"} style={{width: this.state.toggle && window.matchMedia('(min-width:1000px)').matches ? "calc(100vw - 332px)":"calc(100vw - 96px)"}}>
            <Box>
              {this.renderHeaderPart()}
            </Box>
            <Box className={'childGrid'}>
              <Box>{this.props.children}</Box>
            </Box>
          </Box>
        </Box>
      </HeaderContainer>
    )
  }
}

const HeaderContainer = styled(Box)({
  fontFamily: 'Lato',
  height: "100vh",
  overFlowY: "auto",
  "& .bodyGrid": {
    minHeight:"100vh",
  },
  "& .childGrid": {
    paddingTop: 0,
  },
  "& .headerChild": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    background: "#18191b",
    padding: "20px",
  },
  "& .arrowIcon": {
    width: "36px",
    height: "36px",
    cursor: "pointer",
    backgroundColor: "#29fd30",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "49%",
  },
  "& .arrowIcon2": {
    width: "36px",
    alignItems: "center",
    cursor: "pointer",
    backgroundColor: "#29fd30",
    display: "flex",
    height: "36px",
    justifyContent: "center",
    borderRadius: "50%",
    position:'absolute',
    right:94,
    top:62

  },
  "& .arrowBack": {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
  },
  "& .arrowBackBoxTwo": {
    position: "absolute",
    left: "10px",
    top: "-24px",

  },
  
  "& .arrowForward": {
    margin: "0px 0px 0px 8px",
    textAlign: "center",
  },
  "& .headerRight": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    color: "#ffffff",
    marginLeft: "auto",
  },
  "& .arrowBackBoxs": {
    position: "absolute",
    left: "310px",
    top: "30px"
  },
  "& .scaleBodys": {
    height: "70px",
    width: "208px",
    marginTop: "38px"
  },
  "& .scaleBodys2": {
    height: "70px",
    width: "208px",
    marginTop: "55px"
  },
  "& .scaleKYC2": {
    width: "139px",
    height: "54px",
    color: "#ffffff",
    objectFit: "cover",
    objectPosition: "center",
    cursor: "pointer",
  },
  "& .userName": {
    textAlign: "right",
    fontWeight: "bold",
    padding: "0px 10px",
    fontFamily: 'Lato',
  },
  "& .userProfile": {
    color: "#67686a",
    padding: "0px 10px",
    fontFamily: 'Lato',
  },
  "& .imageBox": {
    width: "48px",
    height: "48px",
    cursor: "pointer",
    overflow: "hidden",
    backgroundColor: "grey",
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: "auto",
  },
  "& .userImg": {
    width: "100%",
    height: "100%",
    objectFit: "cover"
  },
  "& .drawers": {
    width: "96px",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    backgroundColor: "#18191b",
  },
  "& .drawer": {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    backgroundColor: "#18191b",
    width: "284px",
    padding: "0px 24px 0px 24px",
    "@media (max-width: 1000px)": {
      height: "100vh",
    },
  },
  "& .drawerTestTwo": {
    display: "flex",
    alignItems: "center"
  },
  "& .draweTestTwos": {

  },
  "& .scaleBody": {
    width: "250px",
    height: "60px",
    margin: "auto",
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    padding: "30px 24px 30px 24px"
  },
  "& .scaleKYC": {
    width: "208px",
    height: "70px",
    color: "#ffffff",
    objectFit: "cover",
    objectPosition: "center",
    cursor: "pointer",
  },
  "& .box": {
    height: "100vh",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    cursor:"pointer"
  },
  "& .listBody": {
    marginTop: "20px",

  },
  "& .listBodys": {
    marginTop: "120px",
  },
  "& .listLastBody": {
    color: "red",
    padding: "30px"
  },
  "& .listLast": {
    cursor: "pointer",
    borderTopStyle: "solid",
    borderTopColor: "#7C7C7C",
    borderTopWidth: "1px",
  },
  "& .list": {
    cursor: "pointer",
    color: "#ffffff"
  },
  "& .lists": {
    marginTop: "12px",
    marginBottom: "12px"
  },
  "& .icon": {
    color: "#ffffff",
    width: "24px",
    height: "24px",
  },
  "& .iconLast": {
    color: "red",
    height:"24px",
    width:"24px"
  },

  "& .logoutBtn": {
    width: "Hug (91px)px",
    height: "Hug (24px)px",
    gap: "14px",
    opacity: "0px",
    fontFamily: 'Lato',
    "& .MuiTypography-body1":{
      fontFamily:'Lato'
    }
  },
  "& .listTxt": {
    fontSize: "15px",
    fontWeight: 600,
    height: "24px",
    width: "40px"
  },
  "& .activeListTxt": {
    color: "lime"
  },
  "& .contactUsText": {
    fontSize: "15px",
    fontWeight: 600,
    height: "24px",
    width: "40px"
  },
  "& .employeeText": {
    fontSize: "15px",
    fontWeight: 600,
    height: "24px",
    width: "40px"
  },
  "& .userVerification": {
    fontSize: "15px",
    fontWeight: 600,
    height: "24px",
    width: "115px"
  },
  "& .activeBtn": {
    width: "262px",
    height: "56px",
    borderRadius: "12px",
    padding: "16px",
    backgroundColor: "#282b30",
    color: "lime"
  },
  "& .activeBtnTwo": {
    backgroundColor: "#282b30",
    color: "#75fc4c",
    padding:"9px",
    borderRadius:"10px"
  },

  "& .listItemWrapper": {
    margin: "auto"
  },

  "& .listIcon": {
    minWidth: "48px",
    height: "48px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },

  '@media screen and (min-width:600px) and (max-width:959px)': {
    "& .scaleBody": {
      width: "180px",
    },
    "& .list": {
      padding: "10px",
    },

    "& .listIcon": {
      minWidth: "35px",
    },
    "& .listIconTwo": {
      minWidth: "35px",
      marginLeft: "-10px"
    },
  },

  '@media screen and (min-width:300px) and (max-width:599px)': {
    "& .scaleBody": {
      width: "150px",
    },
    "& .list": {
      padding: "10px",
    },

    "& .listIcon": {
      minWidth: "35px",
    },
  },
})
