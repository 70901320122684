//@ts-nocheck
import React from "react";
// Customizable Area Start
import { Box, Typography, Grid, Divider } from "@mui/material";
import { styled } from "@mui/system";
import { ScaleKycLogo } from "./assets";
// Customizable Area End

import InvoiceBillingController, {
  Props,
  configJSON,
} from "./InvoiceBillingController.web";

export default class InvoiceBilling extends InvoiceBillingController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <InvoiceBillingContainer>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <img src={ScaleKycLogo} alt="Scale KYC" className="scaleKycLogo" />
          </Grid>
          <Grid item xs={12} sm={6} className="invoiceTitleContainer">
            <Typography className="invoiceTitle">Invoice</Typography>
          </Grid>
        </Grid>

        <Grid container spacing={2} mt={5}>
          <Grid item xs={12} sm={2}>
            <Typography className="paidText">Paid $40.00</Typography>
            <Typography className="invoiceDetails">Date: 30/04/2024</Typography>
            <Typography className="invoiceDetails">Time: 16:00 IST</Typography>
            <Typography className="invoiceDetails">Invoice No.: 002241</Typography>
          </Grid>
          <Grid item xs={12} sm={10}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <Typography className="sectionTitle">Billed to</Typography>
                <Typography className="billDetails">Evano</Typography>
                <Typography className="billDetails">H3, Ground Floor, Mathura Road,</Typography>
                <Typography className="billDetails">Co-Operative Industrial Area, Haryana-110003</Typography>
                <Typography className="billDetails">GSTIN: 07AAACZJ7000X</Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography className="sectionTitle">From</Typography>
                <Typography className="billDetails">Scale KYC</Typography>
                <Typography className="billDetails">H3, Ground Floor, Mathura Road,</Typography>
                <Typography className="billDetails">Co-Operative Industrial Area, Haryana-110003</Typography>
                <Typography className="billDetails">GSTIN: 07AAACZJ7000X</Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid container spacing={2} mt={5}>
          <Grid item xs={false} sm={2} />
          <Grid item xs={12} sm={10}>
            <Box className="borderedSection">
              <Typography className="sectionTitle">Renew Monthly Membership</Typography>

              <Grid container spacing={2} mt={1} className="tableHeader">
                <Grid item xs={6}>
                  <Typography className="headerText">Item Description</Typography>
                </Grid>
                <Grid item xs={2}>
                  <Typography className="headerText">QTY</Typography>
                </Grid>
                <Grid item xs={2}>
                  <Typography className="headerText">Rate</Typography>
                </Grid>
                <Grid item xs={2}>
                  <Typography className="headerText">Amount</Typography>
                </Grid>
              </Grid>

              <Grid container spacing={2} mt={1}>
                <Grid item xs={6}>
                  <Typography className="itemDescription">Expert Plan</Typography>
                </Grid>
                <Grid item xs={2}>
                  <Typography className="quantity">1</Typography>
                </Grid>
                <Grid item xs={2}>
                  <Typography className="rate">$20.00</Typography>
                </Grid>
                <Grid item xs={2}>
                  <Typography className="amount">$20.00</Typography>
                </Grid>
              </Grid>

              <Divider className="divider" />

              <Grid container spacing={2} className="totalSection">
                <Grid item xs={4}>
                  <Typography className="totalText">Total (Rs)</Typography>
                </Grid>
                <Grid item xs={6}></Grid>
                <Grid item xs={2}>
                  <Typography className="totalAmount">$40.00</Typography>
                </Grid>
              </Grid>
            </Box>

            <Box className="borderedSection" mt={5}>
              <Typography className="sectionTitle">Plan Details</Typography>

              <Grid container spacing={2} mt={2}>
                <Grid item xs={4} sm={3} md={2}>
                  <Typography className="planDetailsHeadings">Plan Name</Typography>
                  <Typography className="planDetailsHeadings">Plan Duration</Typography>
                  <Typography className="planDetailsHeadings">Start Date</Typography>
                  <Typography className="planDetailsHeadings">Valid till</Typography>
                  <Typography className="planDetailsHeadings">Subscription Price</Typography>
                  <Typography className="planDetailsHeadings">Renewable date</Typography>
                </Grid>

                <Grid item xs={8} sm={9} md={10}>
                  <Typography className="planDetails">Expert Plan</Typography>
                  <Typography className="planDetails">Monthly</Typography>
                  <Typography className="planDetails">01 May 2024</Typography>
                  <Typography className="planDetails">30 May 2024</Typography>
                  <Typography className="planDetails">$40.00</Typography>
                  <Typography className="planDetails">01 June 2024</Typography>
                </Grid>
              </Grid>
            </Box>

            <Box className="borderedSection" mt={5}>
              <Typography className="sectionTitle">Payment Details</Typography>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={4}>
                  <Typography className="planDetails">
                    Paid from the Credit Card details
                  </Typography>
                  <Typography className="planDetails">mentioned here</Typography>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Typography className="planDetails">Cardholder Name:</Typography>
                  <Typography className="planDetails">Bank Name:</Typography>
                  <Typography className="planDetails">Card Number:</Typography>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Typography className="planDetails">Ajeet Singh</Typography>
                  <Typography className="planDetails">HDFC BANK</Typography>
                  <Typography className="planDetails">1234XXXXXXXX</Typography>
                </Grid>
              </Grid>
            </Box>

            <Box className="borderedSection" mt={5} mb={3}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={4}>
                  <Typography className="thanksText">Thanks for the business.</Typography>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Typography className="contactInfo">help@scalekyc.com</Typography>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Typography className="contactInfo">+91 999 42 4000X</Typography>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </InvoiceBillingContainer>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const InvoiceBillingContainer = styled(Box)({
  backgroundColor: "#242424",
  color: "#fff",
  padding: "24px",
  fontFamily: "Lato",
  "& .scaleKycLogo": {
    width: "120px",
  },
  "& .paidText": {
    fontSize: "20px",
    color: "#00ff00",
    marginTop: "20px",
  },
  "& .invoiceDetails, & .gstDetails, & .planDetails, & .billDetails": {
    fontSize: "14px",
    color: "#D3D3D3",
    marginTop: "5px",
  },
  "& .planDetailsHeafings": {
    fontSize: "14px",
    color: "#D3D3D3",
    marginTop: "5px",
    fontWeight: "bold",
  },
  "& .invoiceTitle": {
    fontSize: "40px",
    textAlign: "right",
    marginRight: "100px",
    LineHeight: "44px",
  },
  "& .sectionTitle": {
    fontSize: "18px",
    fontWeight: "bold",
    marginTop: "20px",
  },
  "& .itemDescription, & .quantity, & .rate, & .amount": {
    fontSize: "14px",
    color: "#D3D3D3",
  },
  "& .tableHeader .headerText": {
    fontSize: "14px",
    fontWeight: "bold",
    color: "#D3D3D3",
    textTransform: "uppercase",
    paddingBottom: "5px",
  },
  "& .totalSection": {
    marginTop: "20px",
  },
  "& .totalText": {
    fontSize: "18px",
    fontWeight: "bold",
  },
  "& .totalAmount": {
    fontSize: "18px",
    fontWeight: "bold",
    color: "#00ff00",
  },
  "& .thanksText": {
    fontSize: "14px",
    fontWeight: "bold",
  },
  "& .contactInfo": {
    fontSize: "14px",
    color: "#D3D3D3",
  },
  "& .divider": {
    backgroundColor: "#D7DAE0",
    margin: "20px 0",
    height: "1px",
  },
  "& .borderedSection": {
    borderLeft: "4px solid #1DFF4E",
    paddingLeft: "16px",
    borderRadius: "1px",
  },
});
// Customizable Area End
