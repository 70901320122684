import React from "react";
import { Message } from "../../framework/src/Message";
import MessageEnum, {
  getName,
} from "../../framework/src/Messages/MessageEnum";
import { getStorageData } from "../../framework/src/Utilities";
import { loadStripe, Stripe} from "@stripe/stripe-js";
import { BlockComponent } from "../../framework/src/BlockComponent";

import StripePaymentsModel from "./StripePaymentModel.web";

export interface ViewProps {
  stripePromise?: Promise<Stripe | null>;
  goToLoginScreen:()=> void;
  open: boolean;
  onClose: () => void;
  handleFailDialogBox: () => void;
  handleSuccessDialogBox: ()=> void;
  payAmount:string;
  email:string;
  payment_id:string;
  type:string;
  price:string;
  payId:number | undefined;
  paymentType:string|undefined;
  payPlan:string|undefined;
}

interface Props {
  navigation?: any;
  id?: string;
  open: boolean;
  onClose: () => void;
  payAmount:string;
  handleFailDialogBox:() => void;
  handleSuccessDialogBox: ()=> void;
  payId?:number | undefined;
  paymentType:string | undefined;
  payPlan:string|undefined;
}

interface S {
  stripePromise?: Promise<Stripe | null>;
  open:boolean;
  onClose: () => void;
  payAmount:string;
  email:string;
  payment_id:string;
  type:string;
  price:string;
  handleFailDialogBox:() => void;
  handleSuccessDialogBox: ()=> void;
  payId:number | undefined;
  paymentType:string |undefined;
  payPlan:string|undefined;
}

export interface SS {
  id: any;
}

class StripePaymentComponent extends BlockComponent<Props, S, SS> {
  private addstripeCallId: string;

  constructor(props: Props) {
    super(props);
    
    this.addstripeCallId = "";
    
    this.state = {
      stripePromise: loadStripe("pk_test_51P12hd2LpmFG1036znMJ2q7ae4rqpyd69MF6TG2AMW9CRFyub32favtBXHPF0ou8OPZurVU3tFUtTeKhjExOtiTy00U8fRWeF8"),
      open: this.props.open,
      payAmount: this.props.payAmount,
      email:"",
      payment_id:"",
      type:"",
      onClose: this.props.onClose,
      handleFailDialogBox:this.props.handleFailDialogBox,
      handleSuccessDialogBox: this.props.handleSuccessDialogBox,
      price:"",
      payId:this.props.payId,
      paymentType:this.props.paymentType,
      payPlan:this.props.payPlan
    };
  }
  async componentDidMount() {  
    super.componentDidMount(); 
    let email = await getStorageData("email");
    let payment_id = await getStorageData("payment_id");
    let type =await getStorageData("payment_type")
    let price =await getStorageData("payment_price")

    this.setState({email:email,payment_id:payment_id,type:type,price:price})
  }  

  async componentDidUpdate(prevProps: Props) {
    if (prevProps.open !== this.props.open) {
      this.setState({ open: this.props.open });
    }
    if (prevProps.payAmount !== this.props.payAmount) {
      this.setState({ payAmount: this.props.payAmount });
    }
    if (prevProps.payId !== this.props.payId) {
      this.setState({ payId: this.props.payId });
    }
    if (prevProps. paymentType !== this.props. paymentType) {
      this.setState({  paymentType: this.props. paymentType });
    }
    if (prevProps.payPlan !== this.props.payPlan) {
      this.setState({ payPlan: this.props.payPlan });
    }
  }

  goToLoginScreen = () => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), "LandingPage");
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  };

  render() {
    console.log("price",this.state.payAmount);
    const viewProps: ViewProps = {
      stripePromise: this.state.stripePromise,
      goToLoginScreen: this.goToLoginScreen,
      open: this.state.open,
      onClose: this.state.onClose,
      payAmount: this.state.payAmount,
      payId: this.state.payId,
      paymentType:this.state.paymentType,
      payPlan:this.state.payPlan,
      email:this.state.email,
      payment_id:this.state.payment_id,
      type:this.state.type,
      price:this.state.price,
      handleFailDialogBox:this.state.handleFailDialogBox,
      handleSuccessDialogBox: this.state. handleSuccessDialogBox
    };

    return <StripePaymentsModel {...viewProps} />;
  }
}

export default StripePaymentComponent;

