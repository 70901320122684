import React from "react";

// Customizable Area Start
import {
  Box,
  Button,
  Grid,
  Input,
  Typography,
  styled,
  InputAdornment,
  FormControlLabel,
  IconButton,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@material-ui/core";
import OtpInput from 'react-otp-input';
import { Clear,Visibility, VisibilityOff  } from "@material-ui/icons";
import { mainImage,logo,mainLogo,blurImage,ArrowIcon,uncheck,checked,verifiedTick } from "./assets";
import CircularProgress from '@material-ui/core/CircularProgress';
import ForgotPasswordController, {Props,configJSON} from "./ForgotPasswordController.web";
import { LazyLoadImage } from "react-lazy-load-image-component";
import LazyLoadImageComponentForgot from "../../../components/src/LazyLoadImageComponent"

// Customizable Area End

export default class ForgotPassword extends ForgotPasswordController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

   // Customizable Area Start

  renderStepFirst = () => {
    return (
      <ForgotPasswordStyle>
        <Box>
          <Grid item xl={12} xs={12} lg={12} sm={12}>
            <Box
              style={{
                backgroundColor: "#242424",
                width: "100%",
                borderBottom: "1px solid #505050",
                display:'flex',
                justifyContent:'flex-end',
              }}
            >
             <LazyLoadImage
               src={mainLogo}
               style={{ display: "flex",
               marginLeft: "auto",
               paddingRight: "2%",
              marginRight:'20px' }}
              effect="blur"
              alt="logo"
              wrapperClassName="lazy-load-wrapper-logo"
              placeholderSrc={logo}
                />
  <style>
  {
    `
    .lazy-load-wrapper-logo{
      backgroundColor: "#242424",
      width: "100%",
      borderBottom: "1px solid #505050",
    }
    `
}
</style>  
            
            </Box>
          </Grid>
        </Box>
        <Box>
          <Grid container spacing={0}>
            <Grid
              item
              xl={7}
              lg={7}
              md={7}
              sm={12}
              xs={12}
              style={{ backgroundColor: "#242424" }}
            >
              <Box className="main-container">
                <Box style={{ margin: "auto" }} className="boxContainer">
                  <Box style={{ color: "white" }}>
                  <img src={ArrowIcon} alt="Arrow Icon" style={{ marginBottom: '15px', cursor: "pointer" }} onClick={this.handleBackButtonClick} data-test-id="btn-back" />
                    <Typography className="createAccount" style={{ cursor: 'context-menu', marginBottom: "8px" }}>{configJSON.pswHead}</Typography>
                    <Typography className="lableName">{configJSON.subHeadingFPass}</Typography>
                  </Box>
                  <Box className="inputContainer">
                    <Input
                      name="email"
                      className={`inputStyle ${this.state.emailError ? 'errorStyle' : ''}`}
                      type="text"
                      placeholder="Your Email"
                      autoComplete="off"
                      data-test-id="emailTest"
                      value={this.state.inputData.email}
                      onChange={this.emailSend}
                    />
                    <Typography className="emailError">
                      {this.state.emailError}
                    </Typography>
                  </Box>

                  <Button onClick={this.forgetPassword}
                    data-test-id="confirmButton"
                    className="customButton"
                    style={
                      { backgroundColor: "#00FF00" }
                    }
                  >
                    {configJSON.buttonText}
                  </Button>
                  <Box
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginTop: "6%",
                    }}
                  >
                    <Typography
                      style={{
                        color: "#D3D3D3",
                        fontFamily: 'Lato',
                        fontSize: '16px',
                        fontWeight: 400,
                        lineHeight: '22px',
                        textAlign: 'left'
                      }}>
                      Back to
                    </Typography>
                    <Box onClick={this.naviagateLogin}
                      data-test-id="NavigateSelectRole"
                      style={{
                        color: "#00FF00",
                        paddingLeft: "1%",
                        fontFamily: 'Lato',
                        fontSize: '16px',
                        fontWeight: 700,
                        lineHeight: '24px',
                        textAlign: 'left',
                        cursor: "pointer",
                        textDecoration: "none",
                      }}
                    >
                      Log In
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Grid>
            <Grid item xl={5} lg={5} md={5} sm={12} xs={12} style={{background:'rgb(36, 36, 36)'}}>
            <LazyLoadImageComponentForgot mainImage={mainImage} blurImage={blurImage} />
            </Grid>
          </Grid>
        </Box>
      </ForgotPasswordStyle>
    )
  }
 

  renderStepTwo = () => {
    return (
      <OtpStyle>
        <Box>
          <Grid item xl={12} xs={12} lg={12} sm={12}>
            <Box style={{ backgroundColor: '#242424', width: '100%', borderBottom: '1px solid #505050' }}>
              <img alt="logo" src={logo} style={{ display: 'flex', marginLeft: 'auto', paddingRight: '2%' }} />
            </Box>
          </Grid>
        </Box>
        <Box>
          <Grid container>
            <Grid item xl={7} lg={7} md={7} sm={12} xs={12} style={{ backgroundColor: '#242424' }} >
              <Box className="main-container">
                <Box style={{ width: '55%', margin: 'auto' }}>
                  <img src={ArrowIcon} alt="Arrow Icon" style={{ marginBottom: '15px', cursor: "pointer" }} onClick={this.changeFileRender} data-test-id="btn-back" />
                  <Box style={{ color: 'white', paddingBottom: '4%' }}>
                    <Typography className="createAccount">
                      {configJSON.emailCheck}
                    </Typography>
                    <Typography className="subheading" >
                    We have sent the verification OTP to {this.state.PersonEmail}
                    </Typography>
                  </Box>
                  <Box style={{ marginTop: '2%' }}>
                    <OtpInput
                      containerStyle={{ display: 'flex', alignItems: 'center', width: '100%' }}
                      onChange={this.getOtp}
                      numInputs={4}
                      value={this.state.otpData.otp}
                      renderSeparator={<span style={{ width: "8px" }}></span>}
                      renderInput={
                        (props) => <input {...props}
                        />}
                      shouldAutoFocus={true}
                      inputStyle="inputStyle"
                      data-test-id="renderInput"
                    />
                  </Box>
                  <Button
                    data-test-id="customButtonTest"
                    disabled={this.state.otpData.otp.length !== 4}
                    style={this.state.otpData.otp.length !== 4 ? { backgroundColor: "#7C7C7C" } : { backgroundColor: "#00FF00" }}
                    onClick={this.otpLogin}
                    className="customButton">
                    {this.state.Loader ? <CircularProgress thickness={4} size={30} /> : " Continue"}
                  </Button>
                  <br />

                  <Box style={{ marginTop: '2%', alignItems: 'center' }}>
                    {(this.state.countTriger) ?
                      this.renderStepMain() :
                      <>
                        <Box style={!this.state.showTimer  ? {  visibility: "hidden" } : { color: '#00FF00'}} data-test-id="showTimerBoxId">
                          <Typography style={{ color: '#00FF00' }} className="TimeRemainingCSS">{configJSON.remainingTime} {this.state.timer}</Typography>
                        </Box>
                        <Box style={{ display: 'flex', marginTop: '2%', alignItems: 'center' }}>
                          <Typography style={{ color: '#D3D3D3' }} className="receiveTextCss">{configJSON.emailNotRecieved}</Typography>
                          <div style={{ color: '#00FF00', paddingLeft: '1%', pointerEvents: this.state.disableLink ? 'none' : 'auto' }}>
                            <Typography style={this.state.disableLink ? { color: "#7C7C7C" } : { color: "#00FF00", cursor: "pointer" }} onClick={this.restartTimer} className="ResetTestCSS" data-test-id="resendTxtID">{configJSON.resendTxt}</Typography>
                          </div>
                        </Box>
                      </>
                    }
                  </Box>
                  
                  <Box style={{ display: 'flex', marginTop: '2%', alignItems: 'center' }}>
                    <Typography style={{ color: '#D3D3D3' }} className='BackBtnCss'>{configJSON.backTbn}</Typography>
                    <div style={{ color: '#00FF00', paddingLeft: '1%' }}>
                      <Typography onClick={this.naviagateLogin} className='LogInTextCSS' data-test-id="NagivateLogin">Log In</Typography>
                    </div>
                  </Box>
                </Box>
              </Box>
            </Grid>
            <Grid item xl={5} lg={5} md={5} sm={12} xs={12} style={{background:'rgb(36, 36, 36)'}}>
            <LazyLoadImageComponentForgot mainImage={mainImage} blurImage={blurImage} />
            </Grid>
          </Grid>
        </Box>
      </OtpStyle>)
  }

  renderStepMain=()=>{
    const minutes = Math.floor(this.state.countTimer / 60);
    const seconds = this.state.countTimer % 60;
    return(
      <Box style={{ display: 'flex', marginTop: '2%', alignItems: 'left' }}>
      <Typography className="errorText" >Please try again after {`${minutes}:${seconds < 10 ? `0${seconds}` : seconds}`}</Typography>
    </Box> 
  )}

  renderStep6 = () => {
    return (
      <Box style={{ marginTop: "2%" }}>
        <Box className="inputContainer">
          <Typography className="lableName">Password</Typography>
          <Input
            className="inputStyle"
            name="password"
            autoComplete="off"
            placeholder="Enter Password"
            data-test-id="passwordField"

            type={this.state.enablePasswordField ? "password" : "text"}
            style={
              this.state.validPass
                ? { border: "1px solid #FFFFFF" }
                : { border: "1px solid red" }
            }
            inputProps={{ disableUnderline: true, maxLength: 32 }}
            value={this.state.formData.password}
            onChange={this.handleFormData}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={this.handlePasswordShow}
                  edge="end"
                  data-test-id="pwdEyeIcon"
                >
                  {this.state.enablePasswordField ? (
                    <VisibilityOff />
                  ) : (
                    <Visibility />
                  )}
                </IconButton>
              </InputAdornment>
            }
          />
          {this.state.validPass ? null : (
            <Typography className="errorMsg">
              {configJSON.conditionText}
            </Typography>
          )}
        </Box>
        <Box className="inputContainer">
          <Typography className="lableName">{configJSON.confirmPassword} </Typography>
          <Input
            className="inputStyle"
            name="reTypePassword"
            autoComplete="off"
            placeholder="Enter Confirm Password"

            data-test-id="confirmPass"
            type={this.state.enableReTypePasswordField ? "password" : "text"}
            style={
              this.state.confirmPass
                ? { border: "1px solid red" }
                : { border: "1px solid #FFFFFF" }
            }
            value={this.state.formData.reTypePassword}
            onChange={this.handleFormData}
            inputProps={{ disableUnderline: true, maxLength: 32 }}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={this.handleConfirmPasswordShow}
                  edge="end"
                >
                  {this.state.enableReTypePasswordField ? (
                    <VisibilityOff />
                  ) : (
                    <Visibility />
                  )}
                </IconButton>
              </InputAdornment>
            }
          />
          {this.state.confirmPass ? (
            <Typography className="errorMsg">{configJSON.samePassword} </Typography>
          ) : null}
        </Box>
        <Box><CheckboxLebel variant="h6" className="passCondLable">Your Password Must Contain</CheckboxLebel></Box>
        <Box className="controlContainer">
          <FormControlLabel
            className="checkBoxContainer"
            disabled
            control={
              <Box style={{ paddingRight: "2%" }}>
                {this.handleChecked("alphabet") ? (
                  <img src={checked} />
                ) : (
                  <img src={uncheck} />
                )}
              </Box>
            }
            label={
              <CheckboxLebel variant="h6" className="checkBoxlabel">
                {configJSON.checkAlphabet}
              </CheckboxLebel>
            }
          />
          <FormControlLabel
            disabled
            className="checkBoxContainer"
            control={
              <Box className="checkImg">
                {this.handleChecked("number") ? (
                  <img src={checked} />
                ) : (
                  <img src={uncheck} />
                )}
              </Box>
            }
            label={
              <CheckboxLebel variant="h6" className="checkBoxlabel">
                {configJSON.numberCheck}
              </CheckboxLebel>
            }
          />
          <FormControlLabel
            disabled
            className="checkBoxContainer"
            control={
              <Box className="checkImg">
                {this.handleChecked("specialSign") ? (
                  <img src={checked} />
                ) : (
                  <img src={uncheck} />
                )}
              </Box>
            }
            label={
              <CheckboxLebel variant="h6" className="checkBoxlabel">
                {configJSON.specialCheck}
              </CheckboxLebel>
            }
          />
          <FormControlLabel
            disabled
            className="checkBoxContainer"
            control={
              <Box className="checkImg">
                {this.handleChecked("capitalChar") ? (
                  <img src={checked} />
                ) : (
                  <img src={uncheck} />
                )}
              </Box>
            }
            label={
              <CheckboxLebel variant="h6" className="checkBoxlabel">
                {configJSON.capitalCheck}
              </CheckboxLebel>
            }
          />
          <FormControlLabel
            className="checkBoxContainer"
            disabled
            control={
              <Box className="checkImg">
                {this.handleChecked("minimumChar") ? (
                  <img src={checked} />
                ) : (
                  <img src={uncheck} />
                )}
              </Box>
            }
            label={
              <CheckboxLebel variant="h6" className="checkBoxlabel">
                {configJSON.characterCheck}
              </CheckboxLebel>
            }
          />
        </Box>
        <Button
          className={this.state.isCheckPassword ? "customButton" : "customButtonTwo"}
          data-test-id="ContinueBtn4"
          onClick={this.createAccount}
          disabled={!this.state.isCheckPassword}
        >
          {configJSON.continueBtn}
        </Button>
      </Box>
    );
  };

  renderModal = () => {
    return (
      <DialogBox
        open={this.state.openModal}
        onClose={this.naviagateLogin}
        aria-labelledby="dialog-title"
        aria-describedby="dialog-description"
        data-test-id="modalID"
      >
        <ClearBox>
          <Clear onClick={this.naviagateLogin} />
        </ClearBox>
        <TickBox>
          <img className="tick" src={verifiedTick} alt={"ResetTick"} />
        </TickBox>
        <DialogContent>
          <DialogContentText id="dialog-description">
            {configJSON.successFullText}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.naviagateLogin} color="primary">{configJSON.loginText}</Button>
        </DialogActions>
      </DialogBox>
    );
  };

  // Customizable Area End
  render() {
    return (
       // Customizable Area Start
       
      <>
        {this.renderModal()}
        {
          !this.state.navigateCheck && this.renderStepFirst()
        }
        {this.state.navigateCheck === 1 && this.renderStepTwo()}
        {this.state.navigateCheck === 2 &&

          <SignupStyling
            style={{ overflow: "hidden", width: "100%", height: "100%" }}
          >
            <Box>
              <Grid item xl={12} xs={12} lg={12} sm={12}>
                <Box
                  style={{
                    backgroundColor: "#242424",
                    borderBottom: "1px solid #505050",
                    width: "100%",
                  }}
                >
                  <img
                    alt="logo"
                    src={logo}
                    style={{
                      display: "flex",
                      paddingRight: "2%",
                      marginLeft: "auto",
                    }}
                  />
                </Box>
              </Grid>
            </Box>
            <Box>
              {this.state.step >= 0 ? (
                <Grid container>
                  <Grid
                    item
                    xl={7}
                    lg={7}
                    md={7}
                    sm={12}
                    xs={12}
                    style={{ backgroundColor: "#242424" }}
                  >
                    <Box className="main-container">
                      <Box style={{ width: "50%", margin: "auto" }}>
                        <img
                          src={ArrowIcon}
                          alt="Arrow Icon"
                          style={{ cursor: "pointer", marginBottom: "2px" }}
                          onClick={this.handleBackClick}
                          data-test-id="btnback2"
                        />
                        <Box style={{ color: "white" }}>
                          <Typography className="createAccount">
                            {configJSON.createText}
                          </Typography>
                        </Box>

                        {this.renderStep6()}
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xl={5} lg={5} md={5} sm={12} xs={12} style={{background:'rgb(36, 36, 36)'}}>
            <LazyLoadImageComponentForgot mainImage={mainImage} blurImage={blurImage} />
            </Grid>
                </Grid>
              ) : null}
            </Box>
          </SignupStyling>

        }
      </>
       // Customizable Area End
    );
    
  }
}
// Customizable Area Start    
const ClearBox = styled(Box)({
  display: "flex",
  justifyContent: "flex-end",
})

const TickBox = styled(Box)({
  display: "flex",
  justifyContent: "center",

  "& .tick": {
    width: "100px",
    height: "100px",
  },
})

const DialogBox = styled(Dialog)({

  "& .MuiTypography-colorTextSecondary": {
    color: "#fff",
    fontSize: "18px",
    fontWeight: "bold",
  },

  "& .MuiDialog-paper": {
    width: "500px",
    color: "#fff",
    backgroundColor: "#505050",
    padding: "20px",
    scrollBehavior: "auto"
  },
  "& .MuiDialogActions-root": {
    justifyContent: "center",
  },

  "& .MuiButton-textPrimary": {
    width: "130px",
    height: "40px",
    textTransform: "capitalize",
    fontSize: "16px",
    fontWeight: 600,
    color: "black",
    fontFamily: "Lato",
    backgroundColor: "#28fe36",
    borerRadius: "none",
  },

  "& .MuiDialogContent-root": {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
});

const Container = styled(Box)({
  height: "100vh",
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",

  "& .modalBtn": {
    width: "200px",
    height: "40px",
    fontSize: "15px",
    fontWeight: "bold",
    color: "#fff",
    backgroundColor: "navy",
    border: "none",
    borderRadius: 15,
    boxShadow: "-2px 2px 7px rgba(0,0,0,0.5)",
    cursor: "pointer",
  },

  "& .modalBtn:hover": {
    backgroundColor: "#29275c",
  },
});
const ForgotPasswordStyle = styled(Box)({
  "& .tabsStyle .MuiTab-textColorPrimary.Mui-selected": {
    backgroundColor: "#00FF00",
  },
  "& .tabsStyle .MuiTabs-indicator": {
    backgroundColor: "#ffffff",
  },
  "& .tabsStyle .PrivateTabIndicator-colorPrimary-11": {
    backgroundColor: "#00FF00",
  },
  "&.MuiTab-textColorPrimary.Mui-selected": {
    color: "#242424 !important",
  },
  "&.jss11": {
    backgroundColor: "#ffffff !important",
  },
  ".PrivateTabIndicator-colorPrimary-49": {
    backgroundColor: "##ffffff !important"
  },
  "& .MuiInput-underline::after": {
    borderBottom: "none",
  },
  "& .MuiTabs-flexContainer": {
    justifyContent: "space-around",
  },
  "& .tabsStyle": {
    display: "flex",
    justifyContent: "space-around",
  },
  "& .MuiTab-wrapper": {
    flexDirection: "row",
    justifyContent: "space-evenly",
    width: "63% !important",
  },
  "& .PrivateTabIndicator-colorPrimary-10": {
    backgroundColor: "white",
  },
  "& .MuiIconButton-edgeEnd": {
    marginRight: "0px",
  },
  "& .inputCheck": {
    width: "20px",
    height: "20px",
    cursor: "pointer",
    paddingLeft: "0px"
  },
  "& .MuiOutlinedInput-notchedOutline": {
    border: " none ! important",
  },
  "& .MuiInputBase-input": {
    color: "white",
    fontFamily: "Lato",
    padding: "16px"
  },
  "& .main-container": {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
  },
  "& .createAccount": {
    fontWeight: 700,
    color: "#D3D3D3",
    fontFamily: 'Lato',
    fontSize: '32px',
    lineHeight: '40px',
    letterSpacing: '-0.005em',
    textAlign: 'left'
  },
  "& .subheading": {
    color: "#D3D3D3",
    fontFamily: 'Lato',
    fontSize: '16px',
    fontWeight: 500,
    lineHeight: '22px',
    textAlign: 'left'
  },
  "& .lableName": {
    fontSize: "14px",
    fontWeight: 500,
    color: "#D3D3D3",
    fontFamily: 'Lato',
    lineHeight: '22px',
    textAlign: 'left',
    marginBottom: "32px"
  },
  "& .inputStyle": {
    width: "100%",
    height: "48px",
    backgroundColor: "#242424",
    color: "#A7A7A7",
    border: "1px solid #FFFFFF",
    fontSize: "16px",
    fontFamily: 'Lato !important',
    fontWeight: 400,
    lineHeight: '24px',
    textAlign: 'left',
    '&::placeholder': {
      color: "#A7A7A7",
      opacity: 0.6,
      fontFamily: "Lato !important",
      fontSize: '16px',
      fontWeight: 400,
      lineHeight: '24px',
      textAlign: 'left',
      padding: "2%"
    },
  },
  "& .inputStyle:focus": {
    outline: "none",
  },
  "& .inputContainer": {
    display: "flex",
    flexDirection: "column",
    marginTop: "5%",
    width: "376px",
    height: "56px"
  },
  "& .customButton": {
    width: "100%",
    height: "48px",
    textTransform: "none",
    marginTop: "4%",
    backgroundColor: "#00FF00",
    fontWeight: 600,
    fontFamily: 'Lato',
    fontSize: '16px',
    lineHeight: '24px',
    textAlign: 'left'
  },
  "& .errorStyle": {
    border: "1px solid red",
  },
  "& .emailError": {
    color: "red",
  },
  '@media (max-width: 760px)': {
    "& .boxContainer": {
      width: "80%",
      padding: "50px 0px"
    }
  },
});
const CheckboxLebel = styled(Typography)({
  fontSize: "12px",
  lineHeight: "unset",
  "@media only screen and (max-width: 1024px)": {
    fontSize: "small",
  },
});

const OtpStyle = styled(Box)({
  '& .MuiInput-underline::after': {
    borderBottom: 'none'
  },
  '& .phoneCodeStyle': {
    position: 'absolute',
    color: 'white',
    marginTop: '1%'
  },
  '& .MuiTab-wrapper': {
    flexDirection: 'row',
    justifyContent: 'space-evenly'
  },
  "& .cb1": {
    borderRadius: '50%',
    verticalAlign: 'middle',
    border: '1px solid pink',
    appearance: 'none',
    outline: 'none',
  },
  '& .PrivateTabIndicator-colorPrimary-10': {
    backgroundColor: 'none'
  },
  '& .customButton': {
    width: '346px',
    height: '56px',
    textTransform: 'none',
    marginTop: '4%',
    backgroundColor: '#00FF00',
    fontFamily: 'Lato',
    fontSize: '16px',
    fontWeight: 700,
    lineHeight: '24px',
    textAlign: 'left',
  },
  '& .MuiIconButton-edgeEnd': {
    marginRight: '0px'
  },
  '& .inputCheck': {
    width: '20px',
    height: '20px',
    cursor: 'pointer',
    backgroundColor: 'none'
  },
  '& .css-1laao21-allyText': {
    width: '100%',
    height: '56px',
    backgroundColor: '#242424',
    color: '#A7A7A7',
    border: '1px solid #FFFFFF',
    paddingLeft: '2%'
  },
  '& .MuiOutlinedInput-notchedOutline': {
    border: ' none ! important',
  },
  '& .MuiInputBase-input': {
    color: 'white'
  },
  '& .css-1pahdxg-control': {
    width: '100%',
    height: '56px',
    backgroundColor: '#242424',
    color: '#A7A7A7',
    border: '1px solid #FFFFFF',
    paddingLeft: '2%'
  },
  '& .ResetTestCSS': {
    lineHeight: '24px',
    fontFamily: 'Lato',
    textAlign: 'left',
    fontSize: '16px',
    fontWeight: 400,
  },
  '& .main-container': {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%'
  },
  '& .inputContainer': {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '5%'
  },
  '& .createAccount': {
    fontSize: '30px',
    fontWeight: 700,
    color: '#D3D3D3',
    fontFamily: 'Lato',
    lineHeight: '40px',
    letterSpacing: '-0.005em',
    textAlign: 'left',
  },
  '& .subheading': {
    color: '#D3D3D3',
    fontFamily: 'Lato',
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '22px',
    textAlign: 'left',
  },
  '& .receiveTextCss': {
    fontSize: '16px',
    lineHeight: '24px',
    fontFamily: 'Lato',
    textAlign: 'left',
    fontWeight: 400,
  },
  '& .css-yk16xz-control ': {
    width: '100%',
    height: '56px',
    backgroundColor: '#242424',
    color: '#A7A7A7',
    border: '1px solid #FFFFFF',
    paddingLeft: '2%'
  },
  '& .lableName': {
    fontSize: '16px',
    fontWeight: 700,
    color: '#D3D3D3'
  },
  '& .inputStyle': {
    border: '2px solid #FFFFFF',
    borderRadius: '8px',
    height: '80px',
    width: '5rem !important',
    color: '#D3D3D3',
    fontSize: '48px',
    background: 'none',
    boxShadow: '0px 1px 2px 0px #1018280D',
    opacity: '0.6'
  },
  '& .MuiSvgIcon-root': { color: "white" },
  '& .inputStyle:focus': {
    outline: 'none',
    opacity: '1.0'
  },
  '& .errorText': {
    fontFamily: "Lato",
    fontSize: "12px",
    fontWeight: 400,
    lineHeight: "18px",
    textAlign: "left",
    color: "#FF1938",
    marginTop: "24px",
    marginBottom: "24px",
  },
  '& .TimeRemainingCSS': {
    lineHeight: '24px',
    fontSize: '16px',
    fontWeight: 700,
    fontFamily: 'Lato',
    textAlign: 'left',
  },
  '& .LogInTextCSS': {
    fontFamily: 'Lato',
    cursor: "pointer",
    fontSize: '16px',
    fontWeight: 700,
    lineHeight: '24px',
    textAlign: 'left',
  },
  '& .BackBtnCss': {
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '24px',
    textAlign: 'left',
    fontFamily: 'Lato',
  },

});
const SignupStyling = styled(Box)({
  "& .MuiGrid-grid-md-7": {
    overflowY: "auto",
  },
  "& ::-webkit-scrollbar-track": {
    background: "#024714",
  },
  "& ::-webkit-scrollbar-thumb": {
    background: "#024714",
  },
  "& .MuiInputBase-input": {
    color: "white",
  },
  "& .MuiIconButton-edgeEnd": {
    marginRight: "0px",
  },
  "& .main-img": {
    width: "100%",
    height: "calc(100vh - 71px)",
    objectFit: "cover",
    objectPosition: "bottom",
    "@media(max-width: 960px)": {
      display: "none",
    },
  },
  "& input::-webkit-outer-spin-button input::-webkit-inner-spin-button": {
    "-webkit-appearance": " none",
    margin: "0",
  },

  "& input[type=number]": {
    "-moz-appearance": "textfield",
  },
  "& .errorMsg": {
    color: "red",
    fontSize: "12px",
  },
  "& .react-tel-input .flag-dropdown": {
    background: "none",
    border: "none",
  },
  "& .flag-dropdown::hover": {
    background: "none",
    border: "none",
  },
  "& .react-tel-input": {
    width: "30%",
    height: "46px",
  },
  "& .react-tel-input .form-control": {
    width: "74%",
    paddingLeft: "28px",
    backgroundColor: "rgb(37 37 37) !important",
  },
  "& .react-tel-input .selected-flag": {
    width: "0px",
    padding: "0px",
    borderRadius: "0px",
  },

  "& .react-tel-input .country-list": {
    zIndex: 37,
    margin: "-250px 0 0px -10px",
    boxShadow: "1px 2px 10px rgba(0, 0, 0, .35)",
    width: "377px",
    borderRadius: "7px",
    backgroundColor: "#2f2f2f !important",
  },
  "& .MuiInput-underline:after": {
    borderBottom: "none",
  },
  "& .main-container": {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "calc(100vh - 71px)",
  },
  "& .createAccount": {
    fontFamily: "Lato",
    letterSpacing: "-0.005em",
    fontSize: "30px",
    fontWeight: 700,
    color: "#D3D3D3",
    lineHeight: "40px",
    textAlign: "left",
  },
  "& .subheading": {
    fontSize: "1rem",
    color: "#D3D3D3",
  },
  "& .lableName": {
    fontSize: "16px",
    fontWeight: 700,
    color: "#D3D3D3",
    fontFamily: "Lato",
    lineHeight: "22px",
    textAlign: "left",
    paddingBottom: "4px",
  },
  "&. phoneNumberInputFiled": {
    display: "flex"
  },
  "& .inputStyle": {
    width: "100%",
    height: "47.6px",
    backgroundColor: "#242424",
    color: "#A7A7A7",
    border: "1px solid #FFFFFF",
    padding: "12px",
    fontFamily: "Lato",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "24px",
    textAlign: "left",
  },
  "& .phoneInputStyle": {
    width: "100%",
    height: "48px",
    backgroundColor: "#242424",
    color: "#A7A7A7",
    border: "1px solid #FFFFFF",
    padding: "16px",
    fontFamily: "Lato",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "24px",
    textAlign: "left",
    outline: "none"
  },
  "& .errorStyle": {
    border: "1px solid red",
  },
  "& .errorMessage": {
    color: "red",
  },

  "& .emailError": {
    marginTop: "10px",
    color: "red",
  },
  "& .inputStyle:focus": {
    outline: "none",
  },
  "& .phoneinput": {
    borderLeft: "none",
  },
  "& .inputContainer": {
    display: "flex",
    flexDirection: "column",
    marginTop: "3%",
  },
  "& .customButton": {
    width: "100%",
    height: "56px",
    textTransform: "none",
    marginTop: "2%",
    backgroundColor: "#00FF00",
    fontWeight: 600,
  },
  "& .customButtonTwo": {
    width: "100%",
    height: "56px",
    textTransform: "none",
    marginTop: "2%",
    backgroundColor: "#7C7C7C",
    fontWeight: 600,
  },
  "& .phoneCodeStyle": {
    position: "absolute",
    color: "white",
    marginTop: "1%",
  },
  "& .checkImg": {
    paddingRight: "2%",
  },
  "& .MuiSvgIcon-root": { color: "white" },
  "& .checkBoxContainer": {
    marginTop: "4px",
  },
  "& .controlContainer": {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-evenly",
    marginTop: "2%",
    marginLeft: "3%",
  },
  "& .checkBoxlabel": {
    color: "#D3D3D3",
    fontFamily: "Lato",
    fontSize: "12px",
    fontWeight: 400,
    lineHeight: "18px",
    textAlign: "left",
  },
  "& .loading-container": {
    textAlign: "center",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    display: "flex",
  },
  "& .spinner": {
    height: "80px",
    animation: "spin 1s ease-in-out infinite",
    borderRadius: "50%",
    borderTopColor: "#000",
    border: "6px solid rgba(0, 0, 0, 0.1)",
    width: "80px",
  },
  "& .loading-text": {
    fontWeight: "bold",
    fontSize: "20px",
    marginTop: "10px",
  },
  "& .passCondLable": {
    color: "#D3D3D3",
    fontFamily: 'Lato',
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '24px',
    textAlign: 'left',
    marginTop: '16px',
  },
  scrollbarColor: "#024714 #f1f1f1",
});
// Customizable Area End