import React, { Component } from 'react';
import { Dialog, Box, Typography, Button, IconButton, TextField, } from '@material-ui/core';
import { styled } from '@material-ui/styles';
import { Close } from "@mui/icons-material";


interface PaymentDetailsProps {
  open: boolean;
  onClose: () => void;
  handleFailDialogBox: () => void;
}

export default class PaymentDetailsBox extends Component<PaymentDetailsProps> {
  render() {
    const { open, onClose, handleFailDialogBox } = this.props;

    return (
      <StyledDialog
        open={open}
        onClose={onClose}
        aria-labelledby="success-dialog-title"
        aria-describedby="success-dialog-description"
      >

        <div>
          <ContainerPayOne>
            <Box className="PaymentDetails">Payment Details</Box>
            <IconButton className="closeButton" onClick={onClose}>
              <Close />
            </IconButton>
          </ContainerPayOne>

          <PlanAmount>
            <div>
              <Typography variant="body1">Paln</Typography>
              <Typography variant="body2">Expert</Typography>
            </div>
            <Typography variant="h6">
              $ 40.00
            </Typography>
          </PlanAmount>

          <ContainerPaytwo>
            <Box className="formBox">
              <StyledLabel>Card Number</StyledLabel>
              <StyledTextField
                fullWidth
                placeholder="0000 0000 0000 0000"
              />
              <StyledLabel>Cardholder Name</StyledLabel>
              <StyledTextField
                fullWidth
                placeholder="Cardholder Name"
              />
              <Box display="flex" justifyContent="space-between">
                <Box width="48%">
                  <StyledLabel>Expiry Date</StyledLabel>
                  <StyledTextField
                    fullWidth
                    placeholder="MM / YYYY"
                  />
                </Box>
                <Box width="48%">
                  <StyledLabel>CVV</StyledLabel>
                  <StyledTextField
                    fullWidth
                    placeholder="3-4 digits"
                  />
                </Box>
              </Box>
            </Box>
            <StyledLineHorizontal />
            <TotalAmount>
              <Typography variant="body1">Total Amount</Typography>
              <Typography variant="h6">
                $ 40.00
              </Typography>
            </TotalAmount>
          </ContainerPaytwo>

          <ContainerPaythree>
            <Box className="payNowButton" onClick={handleFailDialogBox}>
              <Button className="ButtonStyle" >
                Pay now
              </Button>
            </Box>
          </ContainerPaythree>
        </div>
      </StyledDialog>
    );
  }
}

const StyledDialog = styled(Dialog)({
  "& .MuiDialog-paperWidthSm": {
    background: "#505050",
    maxWidth: "511px",
    padding: "12px",
  }
});
const ContainerPayOne = styled(Box)({
  padding: "30px",
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  borderBottom: "1px solid rgb(124, 124, 124, 1)",
  margin: "0px -11px",
  "& .PaymentDetails": {
    width: "462px",
    height: "28px",
    fontFamily: "Lato",
    color: "rgba(255, 255, 255, 1)",
    fontSize: "20px",
    fontWeight: 700,
    lineHeight: "28px",
  },
  "& .closeButton": {
    width: "32px",
    height: "32px",
    color: "white",
  },
});
const ContainerPaytwo = styled(Box)({
  padding: "30px 30px 0px 30px",
  alignItems: "center",
});

const ContainerPaythree = styled(Box)({
  "& .payNowButton": {
    height: "50px",
    backgroundColor: "rgba(0, 255, 0, 1)",
    justifyContent: "center",
    display: "flex",
    alignItems: "center",
    margin: "15px",
  },
  "& .ButtonStyle": {
    fontFamily: "Lato",
    color: "rgba(36, 36, 36, 1)",
    fontSize: "16px",
    lineHeight: "24px",
    fontWeight: 700,
  },
});

const StyledLineHorizontal = styled(Box)({
  backgroundColor: "rgba(124, 124, 124, 1)",
  width: "100%",
  height: "1px",
  marginBottom: "20px"
});

const StyledTextField = styled(TextField)({
  marginBottom: "20px",
  color: '#D3D3D3',
  fontFamily: 'Lato',
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: 600,
  "& .MuiInputBase-root": {
    backgroundColor: "rgba(80, 80, 80, 1)",
    border: "1px solid rgba(211, 211, 211, 1)",
    borderRadius: "4px",
    padding: "10px",
    color: '#D3D3D3',
    fontFamily: 'Lato',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 600,
    "& .MuiInputBase-input::placeholder": {
      color: '#D3D3D3',
      fontFamily: 'Lato',
      fontSize: '14px',
      fontStyle: 'normal',
      fontWeight: 600,
    }
  }
});

const StyledLabel = styled(Typography)({
  color: "rgba(211, 211, 211, 1)",
  fontFamily: "Lato",
  fontSize: "16px",
  fontWeight: 700,
  lineHeight: "20px",
  marginBottom: "5px",
});

const TotalAmount = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  marginTop: "20px",
  "& .MuiTypography-body1": {
    color: "rgba(211, 211, 211, 1)",
    fontFamily: "Lato",
    fontSize: "18px",
    fontWeight: 400,
    lineHeight: "26px",
  },
  "& .MuiTypography-h6": {
    color: "rgba(255, 255, 255, 1)",
    fontFamily: "Lato",
    fontSize: "20px",
    fontWeight: 700,
    lineHeight: "28px",
  },
});
const PlanAmount = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  padding: "15px 30px",
  borderBottom: "1px solid rgb(124, 124, 124, 1)",
  paddingBottom: "10px",
  "& .MuiTypography-body1": {
    color: "rgba(211, 211, 211, 1)",
    fontFamily: "Lato",
    fontSize: "18px",
    fontWeight: 400,
    lineHeight: "26px",
  },
  "& .MuiTypography-body2": {
    color: '#FFF',
    fontFamily: 'Lato',
    fontSize: '22px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '18px'
  },
  "& .MuiTypography-h6": {
    color: "rgba(255, 255, 255, 1)",
    fontFamily: "Lato",
    fontSize: "20px",
    fontWeight: 700,
    lineHeight: "28px",
  },
});
