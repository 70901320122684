// Customizable Area Start
export const approvedVerification = require("../assets/image_verified_24px.svg");
export const pendingVerification = require("../assets/image_timer_24px.svg");
export const rejectedVerification = require("../assets/image_close_24px.svg");
export const clearVerification = require("../assets/image_icon.svg");
export const downloadIcon = require("../assets/image_.png");
export const pdfIcon = require("../assets/image_texts.png");
export const calanderIcon = require("../assets/image_icon.png")
export const ProfileDefaultIcon = require("../assets/blankProfile.jpeg")
export const noDataFound = require("../assets/image_.svg")

// Customizable Area End