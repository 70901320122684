import React, { Component } from 'react';
import { Dialog, DialogTitle, Box, Typography } from '@material-ui/core';
import { styled } from '@material-ui/styles';

interface SuccessDialogProps {
  disc:string;
  open: boolean;
  onClose: () => void;
}

export default class SuccessDialog extends Component<SuccessDialogProps> {
  render() {
    const { disc, open, onClose } = this.props;
    
    return (
      <StyledDialog
        open={open}
        onClose={onClose}
        aria-labelledby="success-dialog-title"
        aria-describedby="success-dialog-description"
      >
        <img onClick={onClose} style={{padding:"16px 16px 8px 8px",width: "24px",height: "24px", alignSelf: "end" ,cursor:"pointer",position:"absolute",top:"0px",right:"0px"}} src={require("../src/cross_icon.png")} />
        <StyledDialogTitle id="success-dialog-title">
          <Box display="flex" alignItems="center" justifyContent="center" flexDirection="column">
            <img src={require("../src/sucess_icon.png")} alt="Success Icon" style={{ marginBottom: "16px" }} />
            <Typography variant="h6">{disc}</Typography>
          </Box>
        </StyledDialogTitle>
      </StyledDialog>
    );
  }
}

const StyledDialog = styled(Dialog)({
    "& .MuiDialog-paperWidthSm": {
        background:"#505050",
        maxHeight:"258px",
        maxWidth:"511px",
        padding:"60px",
    }
  });

const StyledDialogTitle = styled(DialogTitle)({
  color: '#ffffff',
  padding:"0px",
  "& .MuiTypography-h6":{
    fontFamily:"Lato",
    fontWeight:600,
    fontSize:"22px",
    lineHeight:"28px",
  }
});
