import React, { useState } from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';


interface Props {
  handleDateChange: (date: Date | null) => void;
}

export default function ContainedButtons({ handleDateChange }: Props) {
  const classes = useStyles();
 
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [tempDate, setTempDate] = useState<Date | null>(startDate); 
  const [isOpen, setIsOpen] = useState(false);

  const handleChange = (date: Date | null) => {
    setTempDate(date); 
  };

  const handleCancel = () => {
    setTempDate(null);  
    setStartDate(null); 
    setIsOpen(false);  
    handleDateChange(null); 
  };

  const handleApply = () => {
    setStartDate(tempDate); 
    setIsOpen(false);   
    handleDateChange(tempDate);
        
  };

  return (
    <>
      <div
        className={startDate ? `${classes.activeDate} ${classes.datePickerBox}` : classes.datePickerBox}
        onClick={() => setIsOpen(true)}
      >
        {startDate ? startDate.toLocaleDateString() : "Select Date"}
        <img src={require("./image_calendar.png")} alt="calendar" />
      </div>

      <Dialog open={isOpen} onClose={() => setIsOpen(false)}>
        <Box className='datePickerContainer'>
          <DatePicker
            selected={tempDate} 
            onChange={handleChange}
            onClickOutside={() => setIsOpen(false)} 
            dateFormat="yyyy/MM/dd"
            showMonthDropdown
            showYearDropdown
            dropdownMode="select"
            maxDate={new Date()}
            inline
          />
          <Box className='actionButtons'>
            <Button variant="outlined" onClick={handleCancel} className='cancelButton'>
              Cancel
            </Button>
            <Button variant="contained" color="primary" onClick={handleApply} className='applyButton'>
              Apply
            </Button>
          </Box>
        </Box>
      </Dialog>

      <style>
        {`
          .react-datepicker__header {
            background: #505050;
            border: none;
          }
          .react-datepicker__month-container {
            background: #505050;
          }
          .react-datepicker__day--selected, .react-datepicker__day:hover {
            background: #00FF00 !important;
            color: white !important;
          }
          .react-datepicker__month-select, .react-datepicker__year-select {
            background: #505050;
            border: #505050;
            cursor: pointer;
          }
          .react-datepicker__current-month, .react-datepicker__month-select, .react-datepicker__year-select, .react-datepicker__day-name, .react-datepicker__day {
            color: #A7A7A7;
            margin: 5px;
          }

          .react-datepicker__month-select, .react-datepicker__year-select{
            cursor:pointer;
          }

          .react-datepicker{
            border:none;
          }

          .actionButtons{
            background: #505050 !important;
            display: flex;
            justify-content: space-around;
            padding: 10px;
          }


          .MuiButton-label{
            color: white;
            font-weight: bold;
          }

          .cancelButton{
            border: 1px solid white;
              border-radius: 0px;
              background: #00FF00;
              width: 40%;
               height: 35px;
               cursor:pointer;
          }

          .applyButton{
            background: #00FF00;
            color:white;
            border: 1px solid white;
            border-radius: 0px;
            width: 40%;
            height: 35px;
            cursor:pointer;
          }

      
          .MuiButton-containedPrimary:hover{
            background: #00FF00;
            color:white;
          }

          .MuiButton-root:hover{
            background: #00FF00;
            color:white;
          }

          .react-datepicker__day--keyboard-selected{
            background: #00FF00;
            color:white;
          }

        `}
      </style>
    </>
  );
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    marginTop: "15px",
  },
  datePickerBox: {
    border: "1px solid #ccc",
    padding: theme.spacing(1),
    cursor: "pointer",
    height: "56px",
    boxSizing: "border-box",
    textAlign: "unset",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    paddingLeft: "12px",
    borderRadius: 0,
    color: "rgba(124, 124, 124, 1)",
    marginTop: '15px',
    marginBottom: "5px",
  },
  activeDate: {
    color: "#fff",
  },
  datePickerContainer: {
    padding: theme.spacing(2),
  },
  actionButtons: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: theme.spacing(2),
  },
  cancelButton: {
    marginRight: theme.spacing(2),
  },
  applyButton: {
    marginLeft: theme.spacing(2),
  },
}));
