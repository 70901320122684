import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import React from "react";
const navigation = require("react-navigation");
import {removeStorageData } from "../../../../packages/framework/src/Utilities";
// Customizable Area End

export const configJSON = require("./config.js");

export interface Props {
  // Customizable Area Start
  navigation: typeof navigation;
  id: string;
  // Customizable Area End
}

// Customizable Area Start
export interface SearchData {
  id: number;
  name: string;
  emailId: string;
  contactName: string;
  phoneNumber: number;
  kycCheck: number;

}
// Customizable Area End

export interface S {
  // Customizable Area Start
  loading: boolean;
  role: string;
  sideBarClose: boolean;
  tableData: SearchData[];
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: number;
  // Customizable Area End
}

export default class CreateEmployeeController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  fileInputRef: React.RefObject<HTMLInputElement>;
  
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      loading: false,
      role: "",
      sideBarClose :false,
      tableData: [
        {
          id: 1,
          name: "David Smith Willy",
          emailId: "Evergrowcare@gmail.com",
          contactName: "David Willy",
          phoneNumber: +919878987678,
          kycCheck: 10,
        },
        {
          id: 2,
          name: "David Smith Willy",
          emailId: "Evergrowcare@gmail.com",
          contactName: "David Willy",
          phoneNumber: +919878987678,
          kycCheck: 12,
        },
        {
          id: 3,
          name: "David Smith Willy",
          emailId: "Evergrowcare@gmail.com",
          contactName: "David Willy",
          phoneNumber: +919878987678,
          kycCheck: 20,
        },
        {
          id: 4,
          name: "David Smith Willy",
          emailId: "Evergrowcare@gmail.com",
          contactName: "David Willy",
          phoneNumber: +919878987678,
          kycCheck: 20,
        },
        {
          id: 5,
          name: "David Smith Willy",
          emailId: "Evergrowcare@gmail.com",
          contactName: "David Willy",
          phoneNumber: +919878987678,
          kycCheck: 12,
        },
        {
          id: 6,
          name: "David Smith Willy",
          emailId: "Evergrowcare@gmail.com",
          contactName: "David Willy",
          phoneNumber: +919878987678,
          kycCheck: 12,
        },
        {
          id: 7,
          name: "David Smith Willy",
          emailId: "Evergrowcare@gmail.com",
          contactName: "David Willy",
          phoneNumber: +919878987678,
          kycCheck: 12,
        },
        {
          id: 7,
          name: "David Smith Willy",
          emailId: "Evergrowcare@gmail.com",
          contactName: "David Willy",
          phoneNumber: +919878987678,
          kycCheck: 12,
        },
      ],

      // Customizable Area End
    };

    // Customizable Area Start
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    this.fileInputRef = React.createRef();
    // Customizable Area End
  }
// Customizable Area Start
 
 // Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start

    // Customizable Area End
  }

  // Customizable Area Start
  handleSliderButton = () =>{
    this.setState({sideBarClose:!this.state.sideBarClose});
  }
  logOutBtn = async () => {
    await removeStorageData("token");
    this.goToLoginScreen();
  }

  goToLoginScreen = () => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), "LandingPage");
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  };

  navigateToScreen = (screenName:string) => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
        message.addData(getName(MessageEnum.NavigationTargetMessage), screenName);
        message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        message.addData(getName(MessageEnum.NavigationScreenNameMessage), screenName);
        this.send(message)
  };
  // Customizable Area End
}
