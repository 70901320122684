import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import { toast } from "react-toastify";
import {getStorageData, removeStorageData } from "../../../../packages/framework/src/Utilities";


export const configJSON = require("./config.js");

export interface SearchData {
  id: number;
  userPic:string;
  name: string;
  kycCheck: string;
  passportNo:number;
  documents:Array<string>;
  documentName:Array<string>;
  status:string;
}
interface StatusCounts {
  completed: number;
  pending: number;
  failed: number;
  null_review_status: number;
  invalid: number;
  null: number;
}

interface ApplicantStatusResponse {
  total_applicants: number;
  status_counts: StatusCounts;
}

export interface DateRange {
  startDate: Date | null;
  endDate: Date | null;   
  key: string;           
}

export interface CompanyListData {
  id: string;
  type: string;
  attributes: {
    activated: boolean;
    company_name: string;
    company_unique_id: string;
    email: string;
    full_phone_number: string;
    contact_person_name: string;
    tax_identification_number: string;
    website_link: string;
    address: string;
    street: string;
    city: string;
    zip_code: string;
    state: string;
    country: string;
    role_id: string;
    user_type: string;
    address_line_two: string | null;
    image: string | null;
    total_credits: number | null;
    available_credits: number | null;
    first_name: string | null;
    last_name: string | null;
    middle_name: string | null;
    nationality: string | null;
    date_of_birth: string | null;
    status: string | null;
    passport_number: string | null;
    passport: string | null;
    stripe_customer_id: string | null;
    is_subscribed: boolean | null;
    subscription_name: string | null;
    employees: any[];
  };
}
export interface ApplicantStatus {
  review_result: {
    reviewAnswer: string;
  };
  review_status: string;
}

export interface ApplicantAttributes {
  firstName: string | null;
  lastName: string | null;
  middleName: string | null;
  gender: string | null;
  dob: string | null;
  placeOfBirth: string | null;
  countryOfBirth: string | null;
  stateOfBirth: string | null;
  country: string; 
  nationality: string | null;
  addresses: string | null;
  applicant_id: string; 
  passport_number: string | null;
  state: string | null;
  city: string | null;
  zip_code: string | null;
  addresses_2: string | null;
  passport: string | null; 
  profile_picture: string | null;
  status: ApplicantStatus;
  documents: any | null;
}
export interface ApplicantData {
  id: string;
  type: string;
  attributes: ApplicantAttributes;
}
interface ApplicantMeta {
  current_page: number | undefined;
  per_page: number | undefined;
  total_count?: number;
}


export interface ApiResponse {
  data: ApplicantData[];
  meta: ApplicantMeta;
}



// Customizable Area End

export const webConfigJSON = require("./config.js");

export interface Props {
  navigation: any;
  // Customizable Area Start
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  dashboardData: {
    type: string;
    quantity: string;
  }[];
  totalCandidates: string;
  type: string;
  token: string;
  errorMsg: string;
  loading: boolean;
  sideBarClose: boolean;
  tablesData:SearchData[];
  rejectedText:boolean;
  role: string;
  fromDate: Date | null;
  toDate:Date | null;
  anchorEl: HTMLButtonElement | null;
  companyListData : CompanyListData[];
  startDate:Date | null;
  endDate:Date | null;
  ranges:any;
  isPickerVisible:boolean;
  currentPage: number;
  itemsPerPage: number;
  totalItems: number;
  windowWidth: number;
  dashboardApiData: ApplicantData[];
  dashboardApiLoader:boolean;
  dashboardApiErrorMsg:string;
  dashboardApiDataLength:number;
  perPage:number;
  downloadPdfLoader:boolean;
  analysisData: {
    completed: number;
    pending: number;
    failed: number;
    null_review_status: number;
    invalid: number;
    null: number;
     },
     noDataFound:boolean;
     formattedStartDate: string | null;
     formattedEndDate: string | null;
  // Customizable Area End
}
interface SS {}

export default class DashboardController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  apiDashboardItemCallId: string = "";
  dashboardApiCallId: string = "";
  apiGetQueryStrinurl: string = "";
  getCompanyListApiCallId :string = "";
  getDasboardApiListCallId: string= "";
  analysisDataApiCallId : string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    console.disableYellowBox = true;
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage)
    ];

    this.state = {
      type: "",
      dashboardData: [],
      totalCandidates: "",
      errorMsg: "",
      token: "",
      loading: false,
      sideBarClose: false,
      anchorEl:null,
      rejectedText:true,
      role: "",
      fromDate: null,
      toDate:null,
      startDate: null,
      endDate: null,
      formattedStartDate:"",
      formattedEndDate:"",
      ranges: 
        {
          startDate: new Date(),
          endDate: new Date(),
          key: 'selection',
        },
      isPickerVisible: false,
      windowWidth: window.innerWidth,
      tablesData: [
        {
          id: 1,
          userPic:
            "https://cdn.pixabay.com/photo/2024/02/24/22/38/ai-generated-8594850_1280.png",
          name: "David Smith",
          kycCheck:"20/03/2023",
          passportNo: 2343434343,
          documents: ["doc1", "doc2", "doc3","doc4"],
          documentName:["passport.pdf", "document.pdf","passport.pdf", "document1.pdf"],
          status: "approved",
        },
        {
          id: 2,
          userPic:
            "https://cdn.pixabay.com/photo/2024/02/24/22/38/ai-generated-8594850_1280.png",
          name: "Aisyah Clara",
          kycCheck:"20/03/2023",
          passportNo: 2343434343,
          documents: ["doc1", "doc2", "doc3","doc4"],
          documentName:["passport.pdf", "document.pdf","passport.pdf", "document1.pdf"],
          status: "pending",
        },
        {
          id: 3,
          userPic:
            "https://cdn.pixabay.com/photo/2024/02/24/22/38/ai-generated-8594850_1280.png",
          name: "Anastasia De",
          kycCheck:"20/03/2023",
          passportNo: 2343434343,
          documents: ["doc1", "doc2", "doc3","doc4"],
          documentName:["passport.pdf", "document.pdf","passport.pdf", "document1.pdf"],
          status: "approved",
        },
        {
          id: 4,
          userPic:
            "https://cdn.pixabay.com/photo/2024/02/24/22/38/ai-generated-8594850_1280.png",
          name: "Shirely Wong",
          kycCheck:"20/03/2023",
          passportNo: 2343434343,
          documents: ["doc1", "doc2", "doc3","doc4"],
          documentName:["passport.pdf", "document.pdf","passport.pdf", "document1.pdf"],
          status: "rejected",
        },
      ],
      companyListData: [],
      itemsPerPage: 10,
      totalItems: 0,
      dashboardApiData:[],
      dashboardApiLoader:false,
      dashboardApiErrorMsg:"",
      dashboardApiDataLength:0,
      currentPage: 1,
      perPage:10,
      downloadPdfLoader:false,
      analysisData: {
        completed: 0,
        pending: 0,
        failed: 0,
        null_review_status: 0,
        invalid: 0,
        null: 0,
         },
         noDataFound:false,
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getDashboardData();
    // Customizable Area Start
    this.getAnalysisData();
    this.DashboardApiCall();
    this.setState({
      endDate:this.state.startDate
    })
    const role = await getStorageData("role");
    const tokenId = await getStorageData("token");
    this.setState({
      role,token:tokenId
    });
    window.addEventListener('resize', this.handleResize);
    this.invalidToken()
    // Customizable Area End
  }

  getDashboardData(): boolean {
    // Customizable Area Start
    const webHeader = {};
    const webRequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    
    this.apiDashboardItemCallId = webRequestMessage.messageId;
    webRequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      webConfigJSON.dashboardGetUrl
    );
    
    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(webHeader)
    );

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      webConfigJSON.dashboarApiMethodType
    );
    runEngine.sendMessage(webRequestMessage.id, webRequestMessage);
    // Customizable Area End
    return true;
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const webApiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let webResponseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      let webErrorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      
      if(webResponseJson.errors==="Company not found" || webResponseJson.errors==="Employee not found"){
        await removeStorageData("token");
        await removeStorageData("role");
        await removeStorageData("buycredit_subscription_id");
        await removeStorageData("image");
        await removeStorageData("contact_person_name");
        await removeStorageData("invite");
        await removeStorageData("email");
        await removeStorageData("subscription_name");

        const message = new Message(getName(MessageEnum.NavigationMessage));
        message.addData(getName(MessageEnum.NavigationTargetMessage), "LandingPage");
        message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(message);
     
      }
      if (webResponseJson && !webResponseJson.errors) {
        this.invalidToken(webResponseJson.errors);
        if (webApiRequestCallId === this.apiDashboardItemCallId) {
          this.setState({
            dashboardData: webResponseJson.data.attributes.sub_attributres,
            totalCandidates: webResponseJson.data.attributes.total_candidates,
            type: webResponseJson.data.type,
            errorMsg: "",
            loading: false
          });
        }
        if (webApiRequestCallId == this.analysisDataApiCallId) { 
          this.getAnalysisDataResponce(webResponseJson) 
       }       
        if(webApiRequestCallId=== this.getDasboardApiListCallId){
            this.DashboardApiSuccessCall(webResponseJson);
        }
      } else if (webResponseJson && webResponseJson.errors) {
          this.handleErrorResponse(webResponseJson,webErrorReponse,webApiRequestCallId)
    
      }
      this.apiResponseData(webApiRequestCallId,webResponseJson)
    }
    // Customizable Area End
  }
  // Customizable Area Start
  

  invalidToken = (token?:string)=>{
    if(token === "Invalid token" || token === "Token has Expired"){
      this.goToLoginScreen()
    };
  }
  async componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  formatDate = (date:Date ) => {
    
    const day = date.getDate();
    const month = date.toLocaleString('en-US', { month: 'short' }); 
    const year = date.getFullYear().toString().slice(-2); 
    const weekday = date.toLocaleString('en-US', { weekday: 'long' }); 
  
    return `${day} ${month}’${year} ${weekday}`;
  };



   formatDateFun = (date: Date | null) => {
    if (!date) return null; 
    const d = new Date(date);
    const year = d.getFullYear();
    const month = String(d.getMonth() + 1).padStart(2, '0'); 
    const day = String(d.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };



  handleApply = () => {
    const { startDate, endDate } = this.state.ranges; 
    const formattedStartDate = this.formatDateFun(startDate);
    const formattedEndDate = this.formatDateFun(endDate);
  
    
    this.setState({
      startDate: startDate, 
      endDate: endDate,     
      formattedStartDate:formattedStartDate,   
      formattedEndDate:formattedEndDate,    
      isPickerVisible: false,
    },()=>{
    this.DashboardApiCall()
    });
  };
  


  handleCancel = () => {
    this.setState({
     
      isPickerVisible: false,
      startDate:null,
      endDate:null,
      formattedStartDate:'',   
      formattedEndDate:'',    
    }
    ,()=>{
      this.DashboardApiCall()
      }
  );
  };


  setDownloadPdfLoader=(val:boolean)=>{

    this.setState({
      downloadPdfLoader:val
    })
   }
 
 

  handleSelect = (d:any) => {
 
    this.setState({ ranges: d?.selection });
      
  };

  toggleDatePicker = () => {
    this.setState((prevState) => ({ isPickerVisible: !prevState.isPickerVisible }));
  };

  handleResize = () => {
    this.setState({ windowWidth: window.innerWidth });
  };


  handleSliderButton = () =>{
    this.setState({sideBarClose:!this.state.sideBarClose});
  }
  logOutBtn = async () => {
    await removeStorageData("token");
    this.goToLoginScreen();
  }

  goToLoginScreen = () => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), "LandingPage");
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  };

  navigateToScreen = (screenName:string) => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
        message.addData(getName(MessageEnum.NavigationTargetMessage), screenName);
        message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        message.addData(getName(MessageEnum.NavigationScreenNameMessage), screenName);
        this.send(message)
  };
  handleLoader =()=>{
    return !this.state.loading && this.state.tablesData.length > 0
   };

   handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  handleNeedClarification = () => {
    this.setState({ rejectedText: !this.state.rejectedText, anchorEl: null });
  };

  goToScreen = (screenName:string) => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
        message.addData(getName(MessageEnum.NavigationTargetMessage), screenName);
        message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        message.addData(getName(MessageEnum.NavigationScreenNameMessage), screenName);
        this.send(message)
  };

  handleDateChange = (date: Date | null) => {
    this.setState({ fromDate: date, toDate: null });
  };

  handleMaxDate = () => {
    const { fromDate } = this.state;
    const currentDate = new Date();
    const maxDate = fromDate ? new Date(Math.min(currentDate.getTime(), new Date(fromDate).getTime() + 720 * 24 * 60 * 60 * 1000)) : currentDate;
    return maxDate
  };

  toDateInput = (date :  Date | null) => {
    this.setState({toDate: date});
  };

  DashboardApiSuccessCall=(webResponseJson:ApiResponse)=>{


    
    this.setState({dashboardApiLoader:false})

    const totalItems = Number(webResponseJson.meta?.total_count) || 0;
      if(totalItems===0)
        {
          this.setState({
            noDataFound:true
          })
        }
        else
        {
          this.setState({
            noDataFound:false
          })
        }

  this.setState({dashboardApiData: webResponseJson.data,dashboardApiDataLength:totalItems})

  }


  shouldShowDownloadIcon = (reviewAnswer: string | undefined): boolean => {
    return reviewAnswer === 'GREEN' || reviewAnswer === 'RED' || reviewAnswer === 'PENDING';
  };

  
  formatName = (firstName: string | null, middleName: string | null, lastName: string | null): string => {
    const fullName = [firstName, middleName, lastName].filter(Boolean).join(' ');    
    if (fullName.length > 8) {
      return fullName.slice(0, 8) + '...';
    }  
    return fullName;
  };


  handleErrorResponse=(webResponseJson:any,webErrorReponse:any,webApiRequestCallId:string)=>{
    if (webApiRequestCallId === this.apiDashboardItemCallId) {
      this.setState({
        errorMsg: webErrorReponse,
       
      });
     
    }
    else if (webApiRequestCallId === this.getDasboardApiListCallId){
      
      if(Array.isArray(webResponseJson.errors))
      {
        const keyToExtract = Object.keys(webResponseJson.errors[0])[0]; 
        const errMessage = webResponseJson.errors[0][keyToExtract]; 
        this.setState({dashboardApiLoader:false,dashboardApiErrorMsg: errMessage,noDataFound:true});
        toast.error(errMessage,  { hideProgressBar: true ,position: "top-center", style: { backgroundColor: '#003111', color: 'white' }} )
      this.invalidToken(errMessage);
      }
    else if(typeof webResponseJson.errors === 'string')
    {
      const errMessage=webResponseJson.errors;
      toast.error(errMessage,  { hideProgressBar: true ,position: "top-center", style: { backgroundColor: '#003111', color: 'white' }} )
      this.invalidToken(errMessage);
    }

  
    }
  }


  DashboardApiCall= async()=>{ 
    const tokenId = await getStorageData("token");
    const role_type = await getStorageData("role");
    this.setState({dashboardApiLoader:true})
    
      const header = {
        "Content-Type": configJSON.dashboarContentType,
         "token":tokenId ,
      };
      
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.getDasboardApiListCallId= requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.dashboardApiEndPoint}?role=${role_type}&per_page=${this.state.perPage}&page=${this.state.currentPage}&start_date=${this.state.formattedStartDate}&end_date=${this.state.formattedEndDate}`
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
  
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.dashboarApiMethodType
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
     
  
    
  }

  getCompanyData = (value : unknown) => {
    this.setState({loading:true})
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getCompanyListApiCallId = requestMessage.messageId;

     requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.companyListGetUrl}?per_page=${this.state.itemsPerPage}&page=${this.state.currentPage}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );

      runEngine.sendMessage(requestMessage.id, requestMessage);

  };

  apiResponseData = async (apiRequestCallId: string, responseJson: any) => {
    if (apiRequestCallId === this.getCompanyListApiCallId) {
        const data = responseJson.user_list?.data;
        this.setState({ companyListData: data, totalItems: responseJson.total_count,loading:false});
    }
  }

  handlePageChange = (event: object, currentPage: number) => {
    this.setState({
      currentPage
    }, () => {
      this.DashboardApiCall();
    })
  };
  getAnalysisDataResponce = (responseJson: ApplicantStatusResponse) => {
    this.setState({
      analysisData: {
        completed: responseJson.status_counts.completed,
        pending: responseJson.status_counts.pending,
        failed: responseJson.status_counts.failed,
        null_review_status: responseJson.status_counts.null_review_status,
        invalid: responseJson.status_counts.invalid,
        null: responseJson.status_counts.null, 
      },
    });
  };
  getAnalysisData = async () => {
    const token = await getStorageData("token");
    const header = {
      "Content-Type": configJSON.contentTypeApiAddDetail,
      'token': token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.analysisDataApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.analysisDataApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );

      runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  // Customizable Area End
}
