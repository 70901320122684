import React from "react";

// Customizable Area Start
import {
  Avatar,
  Box,
  Button,
  Grid,
  IconButton,
  Input,
  InputAdornment,
  Paper,
  Tab,
  Tabs,
  Typography,
  styled,
  Checkbox
} from "@material-ui/core";
import { logo, mainImage, companyLogo, employeeLogo,blurImage,mainLogo } from "./assets";
import EmailAccountLoginController, {
  Props,
} from "./EmailAccountLoginController.web";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import CircularProgress from '@material-ui/core/CircularProgress';
import { LazyLoadImage } from "react-lazy-load-image-component";
import LazyLoadImageComponentLogin from "../../../components/src/LazyLoadImageComponent"
// Customizable Area End

export default class EmailAccountLogin extends EmailAccountLoginController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  render() {
    return (
      <LoginStyle>
        <Box>
          <Grid item xl={12} xs={12} lg={12} sm={12}>
            <Box
              style={{
                backgroundColor: "#242424",
                width: "100%",
                borderBottom: "1px solid #505050",
                display:'flex',
                justifyContent:'flex-end',
              }}
            >
                <LazyLoadImage
               src={mainLogo}
               placeholderSrc={logo}
               style={{ display: "flex",
               marginLeft: "auto",
               paddingRight: "2%",
              marginRight:'20px' }}
              alt="logo"
              wrapperClassName="lazy-load-wrapper-logo"
              effect="blur"
                />

            </Box>
          </Grid>
        </Box>
        <Box>
          <Grid container spacing={0}>
            <Grid
              item
              xl={7}
              lg={7}
              md={7}
              sm={12}
              xs={12}
              style={{ backgroundColor: "#242424" }}
            >
              <Box className="main-container">
                <Box style={{margin: "auto" }} className="boxContainer">
                  <Box style={{ color: "white" }}>
                    <Typography className="createAccount" style={{cursor: 'context-menu',marginBottom:"20px"}}>Log In</Typography>
                  </Box>
                  <Box style={{ marginTop: "1%" }}>                    
                  </Box>
                  <Paper square>
                    <Tabs
                      className="tabsStyle"
                      style={{padding:"5px", backgroundColor:"white"}}
                      value={this.state.inputData.tabValue}
                      indicatorColor="secondary"
                      textColor="primary"
                      onChange={this.handleChange}
                      aria-label="disabled tabs example"
                      disableRipple
                    >
                      <Tab
                        className="tabStyle"
                        style={{
                                minHeight:"48px",
                                minWidth:"192px",
                                fontFamily: 'Lato', 
                                fontSize: '14px', 
                                fontWeight: 600, 
                                lineHeight: '18px', 
                                textAlign: 'left',
                                color:"#242424"
                                }}
                        icon={
                          <img
                            alt="avatar"
                            src={companyLogo}
                            style={{ height: "24px", width: "24px",marginTop:"2px"}}
                          />
                        }
                        label="Company"
                      />
                      <Tab
                        className="tabStyle"
                        style={{
                                minHeight:"48px",
                                minWidth:"192px",
                                fontFamily: 'Lato', 
                                fontSize: '14px', 
                                fontWeight: 600, 
                                lineHeight: '18px', 
                                textAlign: 'left',
                                color:"#242424"
                              }}
                        icon={
                          <img
                            alt="avatar"
                            src={employeeLogo}
                            style={{ height: "24px", width: "24px",marginTop:"2px" }}
                          />
                        }
                        label="Employee"
                      />
                    </Tabs>
                  </Paper>
                  <Box className="inputContainer">
                    <Typography className="lableName">Email</Typography>
                    <Input
                      name="email"
                      value={this.state.inputData.email}
                      onChange={this.handleInputData}
                      onKeyDown={this.handleKeyDown}
                      className={`inputStyle ${this.state.emailError ? 'errorStyle' : ''}`}
                      type="text"
                      placeholder="Your Email"                      
                      autoComplete="off"
                      data-test-id="emailTest"
                    />
                    <Typography className="emailError">
                           {this.state.emailError} 
                    </Typography>
                  </Box>
                  <Box style={{ marginTop: "2%" }}>
                    <Box className="inputContainer">
                      <Typography className="lableName">Password</Typography>
                      <Input                        
                        className="inputStyle"
                        name="password"
                        autoComplete="off"
                        placeholder="Your Password"
                        onKeyDown={this.handleKeyDown}
                        type={
                          this.state.enablePasswordField ? "password" : "text"
                        }
                        inputProps={{ disableUnderline: true }}
                        value={this.state.inputData.password}
                        onChange={this.handleInputData}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={this.handleClickShowPassword}
                              edge="end"
                            >
                              {!this.state.enablePasswordField ? (
                                <Visibility style={{ color: "white" }}/>
                              ) : (
                                <VisibilityOff style={{ color: "white" }}/>
                              )}
                            </IconButton>
                          </InputAdornment>
                        }
                        data-test-id="passwordTest"
                      />
                    </Box>
                    <Box
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        marginTop: "4%",
                      }}
                    >
                      <Box  style={{display:"flex",justifyContent:"center",alignItems:"center"}}>                        
                        <Checkbox                        
                          className="inputCheck"                         
                          onClick={this.handleCheckboxClick}
                          checked={this.state.checkedRememberMe}      
                          style={{ color: '#D3D3D3' }}    
                          data-test-id="checkboxTest"          
                       />
                        <label
                          style={{
                            color: "white",                           
                            fontSize: "14px",
                            fontWeight: 400,    
                            fontFamily: 'Lato',                            
                            lineHeight: '22px',
                            textAlign: 'left'                        
                          }}
                        >
                          Remember Me
                        </label>
                      </Box>
                      <Box onClick={this.goToLoginScreen2}  data-test-id="forgotpassTesID">
                        <p
                          style={{
                            color: "white",                            
                            fontSize: "14px",
                            fontWeight: 600,
                            fontFamily: 'Lato',    
                            lineHeight: '20px',
                            textAlign: 'left',
                            cursor:"pointer",
                          }}
                        >
                          Forgot Password
                        </p>
                      </Box>
                    </Box>
                  </Box>
                  <Button
                    data-test-id="confirmButton"
                    className="customButton"
                    onClick={this.login}
                    disabled={this.state.disabledBtn}
                    style={
                      this.state.disabledBtn 
                        ? { backgroundColor: "#7C7C7C" }
                        : { backgroundColor: "#00FF00" }
                    }
                  >                    
                    {this.state.Loader ? <CircularProgress thickness={4} size={30} /> : "Log In"}
                  </Button>  
                  <Box
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginTop: "2%",
                    }}
                  >
                    <Typography 
                    style={{ 
                              color: "#D3D3D3" ,
                              fontFamily: 'Lato',
                              fontSize: '16px',
                              fontWeight: 400,
                              lineHeight: '22px',
                              textAlign: 'left'
                              }}>
                      New to Scale KYC?
                    </Typography>
                    <div 
                     data-test-id="NavigateSelectRole"
                    style={{ 
                            color: "#00FF00", 
                            paddingLeft: "1%",fontFamily: 'Lato',
                            fontSize: '16px',
                            fontWeight: 700,
                            lineHeight: '24px',
                            textAlign: 'left', 
                            cursor:"pointer",
                            textDecoration: "none",
                            }}
                          onClick={this.handleSelectRoleNavigation}  
                         >
                      Create Account
                    </div>
                  </Box>
                </Box>
              </Box>
            </Grid>
            <Grid item xl={5} lg={5} md={5} sm={12} xs={12} style={{background:'rgb(36, 36, 36)'}}>    
        <LazyLoadImageComponentLogin mainImage={mainImage} blurImage={blurImage} />
<style>
  {
    `
    .lazy-load-wrapper-logo{
      backgroundColor: "#242424",
      width: "100%",
      borderBottom: "1px solid #505050",
    }
    `
}
</style>      
            </Grid>
          </Grid>
        </Box>
      </LoginStyle>
    );
  }
}
const LoginStyle = styled(Box)({
  "& .tabsStyle .MuiTab-textColorPrimary.Mui-selected": {
    backgroundColor: "#00FF00",    
  },
  "& .tabsStyle .MuiTabs-indicator": {
    backgroundColor: "#ffffff",
  },
  "& .tabsStyle .PrivateTabIndicator-colorPrimary-11": {
    backgroundColor: "#00FF00",
  },
  "&.MuiTab-textColorPrimary.Mui-selected": {
    color: "#242424 !important",
  },
  "&.jss11" :{
    backgroundColor: "#ffffff !important",
  }, 
  ".PrivateTabIndicator-colorPrimary-49": {
    backgroundColor: "##ffffff !important"
  },
  "& .MuiInput-underline::after": {
    borderBottom: "none",
  },
  "& .MuiTabs-flexContainer": {
    justifyContent: "space-around",
  },
  "& .tabsStyle": {
    display: "flex",
    justifyContent: "space-around",
  },
  "& .MuiTab-wrapper": {
    flexDirection: "row",
    justifyContent: "space-evenly",
    width: "63% !important", 
  },
  "& .PrivateTabIndicator-colorPrimary-10": {
    backgroundColor: "white",
  },
  "& .MuiIconButton-edgeEnd": {
    marginRight: "0px",
  },
  "& .inputCheck": {
    width: "20px",
    height: "20px",
    cursor: "pointer",  
    paddingLeft:"0px"
  },  
  "& .MuiOutlinedInput-notchedOutline": {
    border: " none ! important",
  },
  "& .MuiInputBase-input": {
    color: "white",
    fontFamily: "Lato",
  },
  "& .main-container": {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
  },
  "& .createAccount": {
    fontWeight: 700,
    color: "#D3D3D3",
    fontFamily: 'Lato',
    fontSize: '32px',
    lineHeight: '40px',
    letterSpacing: '-0.005em',
    textAlign: 'left'
  },
  "& .subheading": {  
    color: "#D3D3D3",
    fontFamily: 'Lato', 
    fontSize: '16px',
    fontWeight: 500,
    lineHeight: '22px',
    textAlign: 'left'
  },
  "& .lableName": {
    fontSize: "16px",
    fontWeight: 700,
    color: "#D3D3D3",
    fontFamily: 'Lato',  
    lineHeight: '20px',
    textAlign: 'left',
    marginBottom:"7px"
  },
  "& .inputStyle": {
    width: "100%",
    height: "48px",
    backgroundColor: "#242424",
    color: "#A7A7A7",
    border: "1px solid #FFFFFF",
    paddingLeft: "2%",
    fontSize: "16px",
    fontFamily: 'Lato !important', 
    fontWeight: 400,
    lineHeight: '24px',
    textAlign: 'left',
    '&::placeholder': {
      color: "#A7A7A7",
      opacity: 0.6,
      fontFamily: "Lato !important",
      fontSize: '16px',
      fontWeight: 400,
      lineHeight: '24px',
      textAlign: 'left'
    },
  },
  "& .inputStyle:focus": {
    outline: "none",
  },
  "& .inputContainer": {
    display: "flex",
    flexDirection: "column",
    marginTop: "5%",
  },
  "& .customButton": {
    width: "100%",
    height: "48px",
    textTransform: "none",
    marginTop: "4%",
    backgroundColor: "#00FF00",
    fontWeight: 600,
    fontFamily: 'Lato',
    fontSize: '16px',  
    lineHeight: '24px',
    textAlign: 'left'
  },
  "& .errorStyle": {
    border: "1px solid red",
  },
  "& .emailError": {
    marginTop: "10px",
    color: "red",
  },
  "& .loading-container": {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
  },
  "& .spinner": {
    width: "80px",
    height: "80px",
    border: "6px solid rgba(0, 0, 0, 0.1)",
    borderRadius: "50%",
    borderTopColor: "#000",
    animation: "spin 1s ease-in-out infinite",
  },
  "& .loading-text": {
    marginTop: "10px",
    fontSize: "20px",
    fontWeight: "bold",
  },
  '@media (max-width: 760px)': {
    "& .boxContainer": {
      width:"80%",
      padding:"50px 0px"
    }},
});
