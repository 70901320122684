import React from "react";
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Button,
  TableContainer,
  IconButton,
} from "@material-ui/core";
import { styled } from "@material-ui/styles";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CancelIcon from "@material-ui/icons/Cancel";
import BuyCreditController, {
  Props,
} from "../../../blocks/user-profile-basic/src/BuyCreditController.web";
import Headers from "../../../components/src/Headers.web";
import { ArrowBack } from "@mui/icons-material";
import SuccessDailogBoxProfile from '../../../components/src/SuccessDailogBoxProfile.web';
import FailDailogBox from '../../../components/src/FailDailogBox.web';
import PaymentDetailsBox from "../../../components/src/PaymentDetailsBox.web";
import StripePayments from "../../../components/src/StripeWrapper.web";
import Skeleton from '@material-ui/lab/Skeleton';
import SuccessDialog from '../../../components/src/SuccessDailogox.web';

export default class BuyCredit extends BuyCreditController {
  constructor(props: Props) {
    super(props);
  }


  renderSkeletonLoader2=()=>{

    const Arr=[1,2,3,4,5];
    return(
     
  <>
        {


        Arr.map((i,ind)=>(
        <TableRow key={ind}>
  {
    Arr.map((i,ind1)=>(
      <TableCell key={ind1}>
      <Skeleton style={{ borderRadius: '6px',background: ' rgb(113, 108, 108)'}}  width={200} variant="text" />
      </TableCell>
    ))
  }     </TableRow>
        ))
  }
  
  </>  
    )
  }


  renderNoDataFoundEmployeeBuyCredits=()=>{
    return(
    
<TableCell colSpan={8}>
          <div style={{padding:"50px 0px",flexDirection:'column',display:'flex',alignItems:'center',justifyContent:'center',
          background: '#242424'}}>


          <img src={require("../assets/image_.svg")} style={{width:'25%'}}  />
<p style={{fontWeight:'bold',color:'white', fontSize:'20px'}}>No Data Found</p>

     </div> 
     </TableCell>
    )

    
  }

  renderContent = () => {
    const { loading, noDataFound } = this.state;
  
    if (loading) {
      return this.renderSkeletonLoader2();
    }
  
    if (noDataFound) {
      return this.renderNoDataFoundEmployeeBuyCredits();
    }
  
    return this.renderTableFun();
  }


renderTableFun=()=>{
  return(
    <>{
      this.state.features.map((feature, index) => (
        <StyledTableRow key={index}>
          <TableCell className="featureTableCell"  >
            {feature} 
          </TableCell>
          {this.state.creditPlanlist.map((plan, planIndex) => (
            <TableCell
              key={planIndex}
              align="center" 
              style={{width:"auto",borderLeft:"1px solid rgba(124, 124, 124, 0.2)", backgroundColor: this.state.checkCurrentPlan==plan.id ? "#111315" : "" }}       
              data-testId="BuyCreditToggleTest1" 
            >          
          {
           plan.attributes.subscriptions_plan_benefit_id.includes(feature)? <CheckCircleIcon style={{ color: "#00FF00" }} />: <CancelIcon style={{ color: "#515151" }} />
          }
            </TableCell>
          ))}
        </StyledTableRow>
      ))}
      <StyledTableRow>
        <TableCell />

        {this.state.creditPlanlist.map((plan, index) => (
          <TableCell
            key={index}
            align="center"    
            style={{  width:"auto",borderLeft:"1px solid rgba(124, 124, 124, 0.2)",backgroundColor: this.state.checkCurrentPlan==plan.id ? "#111315" : "" }}             
            onClick={() => this.handlePaymentDialogBox(plan.attributes.price,plan.id,plan.type,plan.attributes.name)}
            data-testId="BuyCreditToggleTest"
          >
              <Typography >${this.formatPrice(plan.attributes.price)}</Typography>
            <StyledButton
              variant="contained"
              color={this.state.checkCurrentPlan==plan.id ? "secondary" : "primary"}
            >                      
              {this.state.checkCurrentPlan==plan.id ? "Current Plan" : "Purchase"}                      
            </StyledButton>                    
          </TableCell>
        ))}
      </StyledTableRow>
      </>
  )
}



  renderBuyCredit() {
     return (
      <MainContent>
        <PageTop>
          <Typography variant="h5" className="headerText">
            Buy Credits
          </Typography>
        </PageTop>
        <StyledTableContainer>
          <StyledTable>
            <TableHead>
              <StyledTableRow>
                <TableCell>
                 
                    <Typography variant="subtitle1">
                    {!this.state.noDataFound &&  this.state.meta?.title}
                    </Typography>
                    <Typography variant="body2" className="DescritptionWidth">
                    {!this.state.noDataFound && this.state.meta?.description}
                    </Typography>
                 
                </TableCell>
                {this.state.creditPlanlist.map((plan, index) => (
                  <TableCell
                    key={index}
                    className="planTableCell"   
                    style={{width:"auto",borderLeft:"1px solid rgba(124, 124, 124, 0.2)" ,backgroundColor: this.state.checkCurrentPlan==plan.id? "#111315" : "" }}   
                    data-testId="BuyCreditToggleTest1"     
                  >
                     <Typography variant="h6">{plan.attributes.name}</Typography>
                     <Typography variant="h5">KYC Credit: {plan.attributes.store_credit_id} </Typography>
                     <Typography variant="h6" style={{whiteSpace:"nowrap"}}>Valid for: {plan.attributes.subscription_validity_day} days</Typography>
                     
                  </TableCell>
                ))}
              </StyledTableRow>
            </TableHead>
            <TableBody>
              
              {
               this.renderContent()
            }
            </TableBody>
          </StyledTable>
        </StyledTableContainer>
        <SuccessDialog
          data-testId="confirmDialog_test"
          disc={"Your Payment is Successfully Processed"}
          open={this.state.successDialogOpen}
          onClose={this.handleCancel}
        />
        <FailDailogBox
         data-testId="confirmDialog_test2"
         open={this.state.failDialogOpen}
         onClose={this.handleCancel}
         handleSuccessDialogBox={this.handleFailureDialogBox}
        />
        <StripePayments
          data-testId="confirmDialog_test3"
          open={this.state.isDialogOpen}
          onClose={this.handleCancel} 
          payAmount={this.state.paymentPrice}
          handleFailDialogBox={this.handleFailDialogBox}
          handleSuccessDialogBox={this.handleSuccessDialogBox}
          handleSuccessDialogBox2={this.handleSuccessDialogBox2}
          payId={this.state.paymentId}
          payType={this.state.paymentType}
          payPlan={this.state.payPlan}
        />
      </MainContent>
    );
  }

  render() {
    return (
      <Headers
        sliderButton={this.state.sideBarClose}
        logOutToogle={this.logOutBtn}
        data-testId="logoutTestId"
        onToggleClick={this.handleSliderButton}
        goToScreen={this.navigateToScreen}
        isRouteData="BuyCredit"
      >
        {this.renderBuyCredit()}
      </Headers>
    );
  }
}

const MainContent = styled(Box)({
  padding: "20px",
  backgroundColor: "rgba(26, 29, 31, 1)",
  fontFamily: "Lato",
  maxHeight: 'calc(100vh - 100px)',
  overflow: "auto"
});

const PageTop = styled(Box)({
  marginBottom: "20px",
  display: "flex",
  alignItems: "center",
  "& .headerText": {
    fontFamily: "Lato",
    color: "rgba(252, 252, 252, 1)",
    fontSize: "40px",
    fontWeight: 400,
    lineHeight: "48px",
    initialLetter: "-2%"
  },
});


const StyledTableContainer = styled(TableContainer)({
  marginTop: "20px",
  backgroundColor: "#18191b",
  margin:"auto",
  height:'100vh',
  gap: "40px",
  overflowY:'hidden',
  "@media (max-width: 1000px)": {
    overflowX: "scroll",
  },
 
});

const StyledTableRow = styled(TableRow)({
  "&:not(:last-child)": {
    "& .MuiTableCell-root": {
      borderBottom: "1px solid rgba(124, 124, 124, 0.2)",
      width: "50%",
    },
  },
});

const StyledTable = styled(Table)({
  "& .MuiTableCell-root": {
    borderBottom: "none",
    color: "white",
    padding: "16px 24px",
  },
  "& .MuiTableCell-head": {
    fontWeight: "bold",
    width: "200px",
  },
  "& .planTableCell": {
    textAlign: "start",
    "& .MuiTypography-h6": {
      fontFamily: "Lato",
      fontWeight: 400,
      fontSize: "17px",
      lineHeight: "20px",
      color: "rgba(167, 167, 167, 1)",
      marginBottom: "9px",
      width: "150px",
      margin: "auto",      
    },
    "& .MuiTypography-h5": {
      fontFamily: "Lato",
      fontWeight: 400,
      fontSize: "18px",
      lineHeight: "29px",
      color: "rgba(255, 255, 255, 1)",  
      paddingBottom: "7px",
      width: "150px",
      margin: "auto",
    },
  },
  "& .featureTableCell": {
    color: "rgba(255, 255, 255, 1)",
    fontSize: "16px",
    fontWeight: 400,
    fontFamily: "Lato",
    lineHeight: "24px",
    minWidth:"300px !important",    
  },
  "& .DescritptionWidth": {
    maxWidth:"300px !important",     
  },
});

const StyledButton = styled(Button)({
  height: "47px",
  width: "162px",
  padding: "8px 16px",
  textTransform: "none",
  fontSize: "14px",
  fontWeight: 600,
  fontFamily: "Lato",
  border:"1px solid rgba(0, 255, 0, 1)",
  "&.MuiButton-containedPrimary": {
    backgroundColor: "rgba(0, 255, 0, 1)",
    color: "rgba(36, 36, 36, 1)",
  },
  "&.MuiButton-containedSecondary": {
    backgroundColor: "#111315",
    color: "white",
    border: "2px solid rgba(0, 255, 0, )",
  },
  
});
