
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, { getName, } from "../../../framework/src/Messages/MessageEnum";
// Customizable Area Start
import { Message } from "../../../framework/src/Message";
import { getStorageData, removeStorageData, setStorageData} from "../../../../packages/framework/src/Utilities";
import { toast } from "react-toastify";
//@ts-ignore
import { GetCountries, GetState } from "react-country-state-city";
import { countryData } from "../../../../packages/components/src/ClientGlobals";
import React, { ChangeEvent } from 'react';
import debounce from 'debounce';
interface userProfilePerticulerCompanyAPi {
  data: {
    id: string;
    type: string;
    attributes: {
      total_credits: string,
      avaliable_credits: string,
      website_link: string,
      company_unique_id: string,
      tax_identification_number: string,
      address: string,
      first_name: string,
      last_name: string,
      middle_name: string,
      user_type: string,
      company_name: string,
      contact_person_name: string,
      full_phone_number: string,
      address_line_two: string,
      street: string,
      email: string,
      state: string,
      city: string,
      country: string,
      zip_code: string,
      image: string,
      PhoneNumberValidationCheck:number
    };
  };
}
interface ZipCodeAttributes {
  id: number;
  zip_code: string;
  country_code: string;
  postal_area: string;
  city: string;
  state: string;
  country_full_name: string;
  nationality: string;
}

interface ZipCodeData {
  id: string;
  type: string;
  attributes: ZipCodeAttributes;
}

interface ZipCodeResponse {
  data: ZipCodeData[];
}

type InitialFalseTrue = "initial" | "false" | "true";
interface ErrorPasswordObject {
  alphabet: boolean;
  minimumChar: boolean;
  capitalChar: boolean;
  specialSign: boolean;
  number: boolean;
}
interface userProfilePerticulerEmployeeAPi {
  data: {
    id: string,
    type: string,
    attributes: {
        first_name: string,
        last_name: string,
        middle_name: string,
        date_of_birth: string,
        country: string,
        country_code: string,
        activated: string,
        email: string,
        full_phone_number: string,
        created_at: string,
        updated_at: string,
        company_name: string,
        employee_name: string,
        role_id: Number,
        user_type: string,
        company_unique_id: string,
        image: string,
        nationality: string,
        address: string,
        address_line_two: string,
        state: string,
        city: string,
        zip_code: string,
        passport_number: string,
        passport: string,
        status: string,
        website_link: string,
        phone_number: string
    }
}
}
interface CityApi {
  place_id: number,
  licence: string
  lat: string,
  lon: string,
  class: string,
  type: string,
  place_rank: number,
  importance: number,
  addresstype: string,
  name: string,
  display_name: string,
  boundingbox: Array<string>
}
interface APIPayloadType {
  contentType?: string;
  method: string;
  endPoint: string;
  body?: object;
  token?: string;
  type?: string;
}
export interface CountryCodeObj {
  name: string;
  dialCode: string;
  countryCode: string;
  format: string;
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  // Customizable Area Start  
  navigation: any;
  // Customizable Area End
}

export interface S {
  // Customizable Area Start  
  role: string;  
  sideBarClose:boolean;
  enablePasswordField:boolean;
  enableConfirmPasswordField:boolean;
  enableOldPasswordField:boolean;
  userProfilePerticulerCompany: {
    totalCredits: string,
    avaliableCredits: string,
    websiteLink: string,
    companyUniqueId: string,
    taxIdentificationNumber: number | string,
    companyAddress: string,
    firstName: string,
    lastName: string,
    middleName: string,
    userType: string,
    companyName: string,
    personName: string,
    phoneNumber: string,
    companyAddressTwo: string,
    street: string,
    email: string,
    stateNameCheck: string,
    cityNameCheck: string,
    countryName: string,
    zipCode: string,
    error: string,
    errorEmail: string,
    PhoneNumberValidationCheck: number,
    image: string,
  },
  userProfilePerticulerEmployee: {
    firstName: string,
    lastName: string,
    middleName: string,
    dateOfBirth: string,
    country: string,
    countryCode: string,
    activated: string,
    email: string,
    fullPhoneNumber: string,
    companyName: string,
    employeeName: string,
    roleId: Number,
    userType: string,
    companyUniqueId: string,
    image: string,
    nationality: string,
    address: string,
    addressLineTwo: string,
    state: string,
    city: string,
    zipCode: string,
    passportNumber: string,
    passport: string,
    status: string,
    websiteLink: string,
    phoneNumber: string
    PhoneNumberValidationCheck: number,
  },
  error: string;
  errorEmail: string
  thirdBtnStattus: boolean;
  cityError: boolean;
  Loader: boolean;
  Loader2: boolean;
  Loader3: boolean;
  btnStatus: boolean;
  secondBtnStatus: boolean;
  companyAddressError: boolean;
  companyAddressErrorTwo: boolean;
  stateNameError: boolean;
  zipCodeError: boolean;
  taxIdentificationNumberError: boolean;
  streetError: boolean;
  websiteLinkError: boolean;
  companyNameError: boolean;
  personNameError: boolean;
  emailError: string;
  phoneNumberStatus: string;
  countryList: SS[];
  countries: any[];
  stateList: any[];
  imgSizeExceed: boolean;
  invalidFileType: boolean;
  oldPassword:string;
  newPassword:string;
  confirmPassword:string;
  validPass: boolean;
  confirmPass: boolean;
  enableBtn: boolean;
  isCheckPassword : boolean;
  selectedImage: File | null;
  isDialogOpen:boolean;
  successDialogOpen:boolean;
  failDialogOpen:boolean;
  editSuccessDialogOpen:boolean
  anchorEl: HTMLButtonElement | null;
  isLoaded: boolean;
  employeErrorData: {
    newPassword: ErrorPasswordObject;
  };
  dailCodePhone:string;
  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  code: string;
  label: string;
  phone: string;
  id: any;
  name: string;
  // Customizable Area End
}

export default class EmailAccountRegistrationWebController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start  
  fileInputRef: React.RefObject<HTMLInputElement>;
  userProfilePerticulerCompanyApiCallId: string = "";
  userProfilePerticulerEmployeeApiCallId: string = "";
  getCityAPICallId: string = "";
  updateProfileApiCallId : string = "";
  updateEmployeeProfileApiCallId : string = "";
  changePasswordApiCallId : string = "";
  emailRegex: RegExp;
  taxRegex: RegExp
  nameRegex: RegExp;
  passwordRegEx: RegExp;
  numberRegex: RegExp;
  checkAlphabet: RegExp;
  checkCapital: RegExp;
  checkDigit: RegExp;
  checkSpecialChar: RegExp;
  websiteRegex: RegExp;
  checkAlphaNumaricReg: RegExp;
  phoneNumberReg: RegExp;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      // Customizable Area End    
    ];
    this.receive = this.receive.bind(this);
    runEngine.attachBuildingBlock(this, this.subScribedMessages);
    this.fileInputRef = React.createRef();

    this.emailRegex =
      /^[\w.%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    this.nameRegex = /^[A-Za-z\s]+$/;
    this.numberRegex = /^\d+$/;
    this.taxRegex = configJSON.taxRegex;
    this.passwordRegEx =
      /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,}/;
    this.checkAlphabet = /[a-zA-Z]/;
    this.checkCapital = /[A-Z]/;
    this.checkDigit = /\d/;
    this.checkSpecialChar = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
    this.websiteRegex = /^(https?:\/\/)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(:\d+)?(\/.*)?$/;
    this.checkAlphaNumaricReg = /^[a-zA-Z0-9]+$/;
    this.phoneNumberReg = /^\d{10}$/;

    this.state = {
      // Customizable Area Start   
      role: "",   
      sideBarClose :false,
      enablePasswordField:true,
      enableConfirmPasswordField:true,
      enableOldPasswordField:true,
      userProfilePerticulerCompany: {
        totalCredits: "",
        avaliableCredits: "",
        websiteLink: "",
        companyUniqueId: "",
        taxIdentificationNumber: "",
        companyAddress: "",
        firstName: "",
        lastName: "",
        middleName: "",
        userType: "",
        companyName: "",

        personName: "",
        phoneNumber: "",
        companyAddressTwo: "",
        street: "",
        email: "",
        stateNameCheck: "",
        cityNameCheck: "",
        countryName: "",
        zipCode: "",
        error: "",
        errorEmail: "",
        PhoneNumberValidationCheck: 100,
        image: "",
      },
      userProfilePerticulerEmployee: {
        firstName: "",
        lastName: "",
        middleName: "",
        dateOfBirth: "",
        country: "",
        countryCode: "",
        activated: "",
        email: "",
        fullPhoneNumber: "",
        companyName: "",
        employeeName: " ",
        roleId: 0,
        userType: "",
        companyUniqueId: "",
        image: "",
        nationality: "",
        address: "",
        addressLineTwo: "",
        state: "",
        city: "",
        zipCode: "",
        passportNumber: "",
        passport: "",
        status: "",
        websiteLink: "",
        phoneNumber: "",
        PhoneNumberValidationCheck: 100,
      },
      error: "",
      errorEmail: "",
      thirdBtnStattus: true,
      cityError: false,
      Loader: false,
      Loader2: false,
      Loader3: false,
      btnStatus: true,
      secondBtnStatus: true,
      companyAddressError: false,
      companyAddressErrorTwo: false,
      stateNameError: false,
      zipCodeError: false,
      taxIdentificationNumberError: false,
      streetError: false,
      websiteLinkError: false,
      companyNameError: false,
      personNameError: false,
      emailError: "",
      phoneNumberStatus: "",
      countryList: [],
      countries: [],
      stateList: [],
      imgSizeExceed: false,
      invalidFileType: false,
      selectedImage: null,
      oldPassword:"",
      newPassword:"",
      confirmPassword:"",
      validPass: true,
      confirmPass: true,
      enableBtn: true,
      isCheckPassword : false,
      isDialogOpen:false,
      successDialogOpen:false,
      failDialogOpen:false,
      anchorEl: null,
      editSuccessDialogOpen:false,
      isLoaded:false,
      employeErrorData: {
        newPassword: {
          alphabet:false,
          capitalChar:false,
          minimumChar:false,
          number:false,
          specialSign:false
        },
      },
      dailCodePhone:""
      // Customizable Area End
    };
    // Customizable Area Start
    // Customizable Area End
  }

  handelCityAPIFunction = (responseJson: any) => {
    this.handelCityAPIResponse(responseJson)
  }
  handelCompanyAPIFunction = (responseJson: any) => {
    this.userProfilePerticulerCompanyApiResponse(responseJson);
  }
  handelEmployeeAPIFunction = (responseJson: any) => {
    this.userProfilePerticulerEmployeeApiResponse(responseJson);
  }
  handelChangeAPIFunction = (responseJson: any) => {
    this.changePasswordResponse(responseJson) 
  }
  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
    
      if(responseJson?.errors && (responseJson?.errors[0].message==="Company not found or not activated" || responseJson?.errors && responseJson?.errors[0].message==="Employee not found or not activated")){
 
        await removeStorageData("contact_person_name");
        await removeStorageData("subscription_name");
        await removeStorageData("role");
        await removeStorageData("image");
        await removeStorageData("token");
        await removeStorageData("invite");
        await removeStorageData("buycredit_subscription_id");
        await removeStorageData("email");

        const message2 = new Message(getName(MessageEnum.NavigationMessage));
        message2.addData(getName(MessageEnum.NavigationTargetMessage), "LandingPage");
        message2.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(message2);
      }
      if (responseJson?.errors) {
        responseJson?.errors?.map((error: any) => {
          toast.error(error?.token, { hideProgressBar: true, position: "top-center", style: { backgroundColor: '#003111', color: 'white' } })
          this.invalidToken(responseJson?.errors);
        })
      }
      if(responseJson?.meta){
        this.setState({ editSuccessDialogOpen:true });  
      }
      let apiIdList = {
        [this.getCityAPICallId]: this.handelCityAPIFunction,
        [this.userProfilePerticulerCompanyApiCallId]: this.handelCompanyAPIFunction,
        [this.userProfilePerticulerEmployeeApiCallId]: this.handelEmployeeAPIFunction,
        [this.changePasswordApiCallId]: this.handelChangeAPIFunction,
      }
      let functionName = apiIdList[apiRequestCallId]
      if (functionName)
        functionName(responseJson)

      if (apiRequestCallId === this.updateProfileApiCallId) {
        { this.UpdateProfileCompanyApiResponse2(responseJson) }
        await setStorageData("contact_person_name", responseJson.data.attributes.contact_person_name);
        await setStorageData("image", responseJson.data.attributes.image);
      }
      if (apiRequestCallId === this.updateEmployeeProfileApiCallId) {
        { this.UpdateProfileEmployeeApiResponse2(responseJson) }
        await setStorageData("designation", responseJson.data.attributes.designation);
        await setStorageData("employee_name", responseJson.data.attributes.employee_name);
        await setStorageData("image", responseJson.data.attributes.image);
      }

    }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
    const role = await getStorageData("role");
    const tokenId = await getStorageData("token");
    this.setState({
      role
    });
    this.invalidToken(tokenId);
    
    this.state.role === "company" ?  this.getUserProfilePerticulerCompany() : this.getUserProfilePerticulerEmployee()
  
    this.setState({
      countries: countryData,
      isLoaded: true
    });
    GetCountries().then((result: any) => {
      this.setState({
        countryList: result,
      });
    });
    

  }
  invalidToken = (token?:string)=>{
    if(!token || token === "Invalid token" || token === "Token has Expired"){
      this.goToLoginScreen()
    };
  }
  // @ts-ignore
  changePasswordResponse = (responseJson) =>{
    this.setState({ Loader: false })
    if(responseJson.message=="Password Updated Successfully") this.goToUserProfile()
    toast(responseJson.message, { hideProgressBar: true ,position: "top-center", style: { backgroundColor: '#003111', color: 'white' }} );  
  }

  getUserProfilePerticulerCompany = async () => {
    const token = await getStorageData("token")
    const id = await getStorageData("UserID");
    const header = {
      "Content-Type": configJSON.contentTypeApiAddDetail,
      'token': token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.userProfilePerticulerCompanyApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.accountSubscriptionEndPoint}?id=${id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  getUserProfilePerticulerEmployee = async () => {
    const token = await getStorageData("token")
    const id = await getStorageData("UserID");
    const header = {
      "Content-Type": configJSON.contentTypeApiAddDetail,
      'token': token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.userProfilePerticulerEmployeeApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.accountSubscriptionEmployeeEndPoint}?id=${id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  userProfilePerticulerCompanyApiResponse = (responseJson: userProfilePerticulerCompanyAPi) => {
    const attributes = responseJson.data.attributes;
    this.setState({
      userProfilePerticulerCompany: {
        ...this.state.userProfilePerticulerCompany,
        totalCredits: attributes?.total_credits,
        avaliableCredits: attributes?.avaliable_credits,
        websiteLink: attributes?.website_link,
        companyUniqueId: attributes?.company_unique_id,
        taxIdentificationNumber: attributes?.tax_identification_number,
        companyAddress: attributes?.address,
        firstName: attributes?.first_name,
        lastName: attributes?.last_name,
        middleName: attributes?.middle_name,
        userType: attributes?.user_type,
        companyName: attributes?.company_name,
        personName: attributes?.contact_person_name,
        phoneNumber: attributes?.full_phone_number,
        PhoneNumberValidationCheck: attributes?.full_phone_number.length,
        companyAddressTwo: attributes?.address_line_two || "",
        street: attributes?.street,
        email: attributes?.email,
        stateNameCheck: attributes?.state,
        cityNameCheck: attributes?.city,
        countryName: attributes?.country,
        zipCode: attributes?.zip_code,
        image: attributes?.image,
      },
    });
  };
  userProfilePerticulerEmployeeApiResponse = (responseJson: userProfilePerticulerEmployeeAPi) => {
    const attributes = responseJson.data.attributes;
    this.setState({
      userProfilePerticulerEmployee: {
        ...this.state.userProfilePerticulerEmployee,
        firstName: attributes?.first_name,
        lastName: attributes?.last_name,
        middleName: attributes?.middle_name,
        dateOfBirth: attributes?.date_of_birth,
        country: attributes?.country,
        countryCode: attributes?.country_code,
        activated: attributes?.activated,
        email: attributes?.email,
        fullPhoneNumber: attributes?.full_phone_number,
        companyName: attributes?.company_name,
        employeeName: attributes?.employee_name,
        roleId: attributes?.role_id,
        userType: attributes?.user_type,
        companyUniqueId: attributes?.company_unique_id,
        image: attributes?.image,
        nationality: attributes?.nationality,
        address: attributes?.address,
        addressLineTwo: attributes?.address_line_two,
        state: attributes?.state,
        city: attributes?.city,
        zipCode: attributes?.zip_code,
        passportNumber: attributes?.passport_number,
        passport: attributes?.passport,
        status: attributes?.status,
        websiteLink: attributes?.website_link,
        phoneNumber: attributes?.full_phone_number,
        PhoneNumberValidationCheck: attributes?.full_phone_number.length
      },
    });
  };
  UpdateProfileCompanyApiResponse2 = (responseJson: userProfilePerticulerCompanyAPi) => {
    this.setState({ Loader2: false }) 
    const attributes = responseJson.data.attributes;

    this.setState({
      userProfilePerticulerCompany: {
        ...this.state.userProfilePerticulerCompany,
        personName: attributes?.contact_person_name,
        phoneNumber: attributes?.full_phone_number,
        companyName: attributes?.company_name,
        websiteLink: attributes?.website_link,
        taxIdentificationNumber: attributes?.tax_identification_number,
        countryName: attributes?.country,
        companyAddress: attributes?.address,
        companyAddressTwo: attributes?.address_line_two,
        stateNameCheck: attributes?.state,
        zipCode: attributes?.zip_code,
        image: attributes?.image,         
      },
    });
  };
  UpdateProfileEmployeeApiResponse2 = (responseJson: userProfilePerticulerEmployeeAPi) => {
    this.setState({ Loader3: false }) 
    const attributes = responseJson.data.attributes;

    this.setState({
      userProfilePerticulerEmployee: {
        ...this.state.userProfilePerticulerEmployee,
        fullPhoneNumber: attributes?.full_phone_number,
        employeeName: attributes?.employee_name,
      },
    });
  };
  formatPhoneNumber = (value:string, dialCode:string) =>  `+${dialCode}-${value.substring(dialCode.length)}`
  UpdateUserProfilePerticulerCompanyFunction = async () => {
    this.setState({ Loader2: true }) 
    const token = await getStorageData("token");
    let phoneNumberFormate= await this.formatPhoneNumber(this.state.userProfilePerticulerCompany.phoneNumber,this.state.dailCodePhone)
    const formData = new FormData();
  
    if (this.state.selectedImage) {
      formData.append("data[image]", this.state.selectedImage);
    }
    
    formData.append("data[contact_person_name]", this.state.userProfilePerticulerCompany.personName);    
    formData.append("data[full_phone_number]", String(phoneNumberFormate));
    formData.append("data[address]", this.state.userProfilePerticulerCompany.companyAddress);
    formData.append("data[country]", this.state.userProfilePerticulerCompany.countryName);
    formData.append("data[address_line_two]", this.state.userProfilePerticulerCompany.companyAddressTwo);
    formData.append("data[state]", this.state.userProfilePerticulerCompany.stateNameCheck);
    formData.append("data[zip_code]", String(this.state.userProfilePerticulerCompany.zipCode));
    formData.append("data[tax_identification_number]", String(this.state.userProfilePerticulerCompany.taxIdentificationNumber));
    formData.append("data[website_link]",this.state.userProfilePerticulerCompany.websiteLink);
  
    const header = {
      'token': token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    
    this.updateProfileApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.updateCompanyProfileEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiUpdateUserType
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );
  
    runEngine.sendMessage(requestMessage.id, requestMessage);
    
    
  };
  isButtonDisabled = () => {
    const { btnStatus, userProfilePerticulerCompany } = this.state;
    return btnStatus || !userProfilePerticulerCompany.zipCode;
  }
  UpdateUserProfilePerticulerEmployeeFunction = async () => {
    this.setState({ Loader3: true }) 
    const token = await getStorageData("token")
    let phoneNumberFormate= await this.formatPhoneNumber(this.state.userProfilePerticulerEmployee.fullPhoneNumber,this.state.dailCodePhone)
    const formData = new FormData();

     if(this.state.selectedImage){
     formData.append("data[image]", this.state.selectedImage)
     }   
     formData.append("data[employee_name]", this.state.userProfilePerticulerEmployee.employeeName);    
     formData.append("data[full_phone_number]", phoneNumberFormate);
     
    const header = {
      'token': token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.updateEmployeeProfileApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.updateEmployeeProfileEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiUpdateUserType
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    
  };

  handleSliderButton = () => {
    this.setState({ sideBarClose: !this.state.sideBarClose });
  }
  logOutBtn = async () => {
    await removeStorageData("token");
    await removeStorageData("UserID");
    this.goToLoginScreen();
  }
  goToLoginScreen = () => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), "LandingPage");
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  };
  goToScreen = (screenName: string) => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), screenName);
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    message.addData(getName(MessageEnum.NavigationScreenNameMessage), screenName);
    this.send(message)
  };
  goToUserProfileEditScreen = () => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), "UserProfileEditWeb");
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  };
  goToUserProfileEditScreenEmployee = () => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), "UserProfileEmployeeEditWeb");
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  };
  goToUserProfile = () => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), "UserProfileBasicWeb");
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  };
  handelCityAPIResponse = (responseJson: ZipCodeResponse) => {   
    const details = responseJson.data[0].attributes;
    this.setState({
      userProfilePerticulerCompany: {
        ...this.state.userProfilePerticulerCompany,
        cityNameCheck: details.city,
        stateNameCheck: details.state, 
        countryName:details.country_full_name,
      }
    });
      // Customizable Area End
  }

  getCityDetail = async (zipCode: string) => {
    if (zipCode) {
      this.getCityAPICallId = await this.apiCall({
        contentType: configJSON.validationApiContentType,
        method: configJSON.callTypeApiValidateMobileNo,
        endPoint: `account_block/zip_code_search?query=${zipCode}`,
      });
    }
  };
  apiCall = async (apiData: APIPayloadType) => {
    const { contentType, method, endPoint, body, type } = apiData;
    const header = {
      "Content-Type": contentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    body && type !== "formData"
      ? requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      )
      : requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        body
      );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };

  checkValidation = () => {
    const {
      personName,
      websiteLink,
      companyAddress,
      companyAddressTwo,
      countryName,
      zipCode,
    } = this.state.userProfilePerticulerCompany;
    if (personName.length > 0 && this.state.userProfilePerticulerCompany.phoneNumber.length === this.state.userProfilePerticulerCompany.PhoneNumberValidationCheck
    ) {
      this.setState({ btnStatus: false });
    }

    else {
      this.setState({ btnStatus: true });
    }
    if (
 
      websiteLink.length > 0 &&
      this.websiteRegex.test(websiteLink) &&
      companyAddress.trim().length > 0 && 
      companyAddressTwo.trim().length > 0 && 
      this.state.userProfilePerticulerCompany.phoneNumber.length === this.state.userProfilePerticulerCompany.PhoneNumberValidationCheck
    ) {
      this.setState({ secondBtnStatus: false });
    } else {
      this.setState({ secondBtnStatus: true });
    }
    if (countryName.length > 0 && zipCode.length === 6 ) {
      this.setState({ thirdBtnStattus: false});
    } 
    else {
      this.setState({ thirdBtnStattus: true });
    }
  };

  onChooseFile = () => {
    if (this.fileInputRef.current) {
      this.fileInputRef.current.click();
      this.setState({ imgSizeExceed: false });
      this.setState({ invalidFileType: false });
    }
  };

  handleAvatarFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files && files.length > 0) {
        const file = files[0];
        const fileType = file.type;
        const allowedTypes = configJSON.uploadFileTypes;
        const maxSizeMB = 10;

        if (!allowedTypes.includes(fileType)) {
            this.setState({
                imgSizeExceed: false,
                btnStatus: true,
                invalidFileType: true 
            });
            return;
        }
        if (file.size > maxSizeMB * 1024 * 1024) {
            this.setState({
                imgSizeExceed: true,
                btnStatus: true,
                invalidFileType: false
            });
            return;
        }
        
        const imgUrl = URL.createObjectURL(file);
        this.setState({
            selectedImage: file,
            imgSizeExceed: false,
            btnStatus: false,
            invalidFileType: false
        });
        
    } else {
        this.setState({
            imgSizeExceed: false,
            btnStatus: true,
            invalidFileType: false
        });
    }
};



  componentWillUnmount(): Promise<void> {
    return new Promise<void>((resolve) => {
        if (this.state.selectedImage) {
            URL.revokeObjectURL(URL.createObjectURL(this.state.selectedImage));
        }
        resolve();
    });
  }

  handleTaxCheck = (value: string, name: string) => {
  
    if (value) {
      this.setState(
        prevState => ({
          userProfilePerticulerCompany: {
            ...prevState.userProfilePerticulerCompany,
            [name]: value.trimStart()
          },
      
        }),
        () => this.checkValidation()
      );
    } 
  };  

  handleZipCodeCheck = (value: string, name: string) => {
    const isValidZipCode = configJSON.zipCodeRegEx.test(value);
  
    if (isValidZipCode) {
      this.setState(
        prevState => ({
          userProfilePerticulerCompany: {
            ...prevState.userProfilePerticulerCompany,
            [name]: value.trimStart()
          },
          zipCodeError: false
        }),
        () => this.checkValidation()
      );
    } else {
      this.setState({ zipCodeError: true, btnStatus: true });
    }
  };
  

  handleWebsiteCheck = (value: string, name: string,) => {
    const webSiteRegex = configJSON.webSiteRegex
    const urlCheck = value.trim();
    let urlTest = this.state.userProfilePerticulerCompany
    urlTest.websiteLink = value.trimStart()
    this.setState({ userProfilePerticulerCompany: urlTest },
      () => {
        if (webSiteRegex.test(urlCheck)) {
          this.checkValidation();
          this.setState({
            websiteLinkError: false
          })
        } else {
          this.setState({
            websiteLinkError: true
          }, () => {
            this.checkValidation();
            this.setState({ btnStatus: true, });
          });
        }
      }
    );
  };

  handlePersonCheck = (value: string, name: string) => {
    if (value.length <= 42 && value.length > 0) {
      this.setState(
        prevState => ({
          userProfilePerticulerCompany: {
            ...prevState.userProfilePerticulerCompany,
            [name]: value.trimStart()
          },
          personNameError: false,
          companyNameError: false
        }),
        () => this.checkValidation()
      );
    } else {
      this.setState({ personNameError: true, companyNameError: false ,btnStatus: true});
    }
  }

  handleCompanyAddressCheck = (value: string, name: string) => {
    if (value.length < 100 && value.length !== 0) {
      this.setState(
        prevState => ({
          userProfilePerticulerCompany: {
            ...prevState.userProfilePerticulerCompany,
            [name]: value.trimStart()
          },
          companyAddressError: false
        }),
        () => this.checkValidation()
      );
    } else {
      this.setState({ companyAddressError: true ,btnStatus: true});
    }
  };

  handleCompanyAddressCheckTwo = (value: string, name: string) => {
    if (value.length < 100 && value.length !== 0) {
      this.setState(
        prevState => ({
          userProfilePerticulerCompany: {
            ...prevState.userProfilePerticulerCompany,
            [name]: value.trimStart()
          },
         
        }),
        () => this.checkValidation()
      );
    }
  };

  dailcodeData = async(value: string, data: CountryCodeObj, event: React.ChangeEvent<HTMLInputElement>, formattedValue: string) => {
     
    let dailCodeLenght = data?.dialCode?.length;
    let dailCodeData= await data?.dialCode
    let numberData = data?.format?.split('.').length - 1;
    let difference = numberData - dailCodeLenght;
    let displayDifference = isNaN(difference) ? "valid" : difference;
    let reference = this.state.userProfilePerticulerCompany
    reference.phoneNumber = value
    reference.PhoneNumberValidationCheck = numberData

    this.setState({
      userProfilePerticulerCompany: reference,dailCodePhone:dailCodeData
    }, () => {
      this.checkValidation();
      if (this.state.userProfilePerticulerCompany.phoneNumber.length !== this.state.userProfilePerticulerCompany.PhoneNumberValidationCheck) {
        this.setState({ phoneNumberStatus: `Please write ${displayDifference} digits` });
      } else {
        this.setState({ phoneNumberStatus: "" });
      }
    });
  };

  getStateList = (e: any) => {
    this.setState({
      userProfilePerticulerCompany: {
        ...this.state.userProfilePerticulerCompany,
        countryName: e.target.value,
      },
    }, () => {
      this.getCityDetail(this.state.userProfilePerticulerCompany.countryName)
    });
    const countryDetail = this.state.countryList.find(
      (item) => item.name === e.target.value
    );
    GetState(countryDetail?.id).then((result: any) => {
      this.setState({
        stateList: result,
      });
    });
  };
  handleCountryName = (value: string) => {
    this.setState((prevState) => ({
      userProfilePerticulerCompany: {
        ...prevState.userProfilePerticulerCompany, ["countryName" as string]: value,
      },
    }),()=>{
      if (value.length <= 0) {    
          this.checkValidation()
          this.setState({ btnStatus: true, });
      } 
    });
  };
  handleStateName = (value: string) => {
    this.setState((prevState) => ({
      userProfilePerticulerCompany: {
        ...prevState.userProfilePerticulerCompany, ["stateNameCheck" as string]: value,
      },
    }),()=>{
      if (value.length <= 0) {    
          this.checkValidation()
          this.setState({ btnStatus: true, });
      } 
    });
  };

  debouncedGetCityDetail = debounce(this.getCityDetail.bind(this), 1000);

  handleEditDescription = (event: { target: { name: string, value: string } }) => {
    const { name, value } = event.target;

    this.setState(prevState => ({
        userProfilePerticulerCompany: {
            ...prevState.userProfilePerticulerCompany,
            [name]: value,
        }
    }), () => {
      if (name === "taxIdentificationNumber") {
            this.handleTaxCheck(value, name);
        } 
        else if (name === "websiteLink") {
            this.handleWebsiteCheck(value, name);
        } 
        else if (name === "personName") {
            this.handlePersonCheck(value, name);
        } 
        else if (name === "companyAddress") {
            this.handleCompanyAddressCheck(value, name);
        } 
        else if (name === "companyAddressTwo") {
            this.handleCompanyAddressCheckTwo(value, name);
        }
        else if (name === "stateName") {
          this.handleStateName(value);
          this.checkValidation()
        }
        else if (name === "country") {
          this.handleCountryName(value);
          this.checkValidation()
        }
        else{
          this.setState(prevState => ({
            userProfilePerticulerCompany: {
                ...prevState.userProfilePerticulerCompany,
                [name]: value,
            }
        }), () => {
            const { zipCode } = this.state.userProfilePerticulerCompany;
            if (zipCode) {           
                this.debouncedGetCityDetail(zipCode);
                  this.setState({ zipCodeError: false, });
              
              this.checkValidation();
            }
         
        });
         } 
    });
};


  employeeValidation = () => {   
    const {employeeName ,fullPhoneNumber,PhoneNumberValidationCheck} =   
      this.state.userProfilePerticulerEmployee;
       if (
      employeeName.match(this.nameRegex) &&
      fullPhoneNumber.length === PhoneNumberValidationCheck &&
      employeeName.length < 52

    ) {
      this.setState({ btnStatus: false });
    } else {
      this.setState({ btnStatus: true });
    }
  };

  dailcodeData2 = async(value: string, data: CountryCodeObj, event: React.ChangeEvent<HTMLInputElement>, formattedValue: string) => {
    let numberData = data?.format?.split('.').length - 1;
    let dailCodeLenght= data?.dialCode?.length;
    let dailCodeData= await data?.dialCode
    let difference = numberData - dailCodeLenght;
    let displayDifference = isNaN(difference) ? "valid" : difference;
    let reference = this.state.userProfilePerticulerEmployee
    reference.fullPhoneNumber = value    
    reference.PhoneNumberValidationCheck = numberData   
    this.setState({
      userProfilePerticulerEmployee: reference,dailCodePhone:dailCodeData
    }, () => {
      this.employeeValidation(); 
      if (this.state.userProfilePerticulerEmployee.fullPhoneNumber.length !== this.state.userProfilePerticulerEmployee.PhoneNumberValidationCheck) {
        this.setState({ phoneNumberStatus: `Please write ${displayDifference} digits`});
      } else {
        this.setState({ phoneNumberStatus: "" });
      }
    });
  }; 

  handleCompanyNameCheck = (value: string, name: string) => {
    if (value.length <= 52) {
      if(name === "employeeName"){
        this.setState(
          (prevEmployeeData) => ({
            ...prevEmployeeData,
            userProfilePerticulerEmployee: {
              ...prevEmployeeData.userProfilePerticulerEmployee,
              [name]: value.trimStart(),
            },
            companyNameError:false
          }),
          () => {
            this.employeeValidation();
          }
        );
      }     
    } else {
      this.setState({ companyNameError: true });
    }
  }
  

  handleEmployeeData = (e: { target: { name: string, value: string } }) => {
    const inputValue = e.target.value;
  if(e.target.name == "employeeName"){  
      if(e.target.value.length<53){
        this.handleCompanyNameCheck(inputValue, e.target.name)
        this.employeeValidation();
      }
    }  
  };
  handleOldPasswordShow = () => {
    this.setState({
      enableOldPasswordField: !this.state.enableOldPasswordField,
    });
  };
  handlePasswordShow = () => {
    this.setState({
      enablePasswordField: !this.state.enablePasswordField,
    });
  };
  handleConfirmPasswordShow = () => {
    this.setState({
      enableConfirmPasswordField: !this.state.enableConfirmPasswordField,
    });
  };

  validatePassword = (password: string) => {
    const validLength = password.length >= 8;
    const isSpecialCharacter = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/.test(password);
    const isNumber = /\d/.test(password);
    const isUppercaseCharacter = /[A-Z]/.test(password);
    const hasAlphabet = /[a-zA-Z]/.test(password);
    const employeeErrorData = {
      ...this.state.employeErrorData,
      newPassword: {
        alphabet: hasAlphabet,
        capitalChar: isUppercaseCharacter,
        minimumChar: validLength,
        number: isNumber,
        specialSign: isSpecialCharacter,
      },
    };

    this.setState({ employeErrorData: employeeErrorData, validPass: validLength && hasAlphabet && isUppercaseCharacter && isNumber && isSpecialCharacter}, this.validatePasswords);
  };

  empPasswordConfirm = (cpass: string, pass: string) => {
    const confirmPass = pass === cpass;
    this.setState({ confirmPassword: cpass, confirmPass }, this.validatePasswords);
  };

  validatePasswords = () => {
    const { oldPassword, newPassword, confirmPassword, confirmPass, validPass } = this.state;
    const enableBtn = !(oldPassword && newPassword && confirmPassword && confirmPass && validPass);
    this.setState({ enableBtn });
  };

  handleChange = (event: ChangeEvent<HTMLInputElement>): void => {
    const { name, value } = event.target;

    if (name === "newPassword") {
      this.setState({ newPassword: value }, () => {
        this.validatePassword(value);
        this.empPasswordConfirm(this.state.confirmPassword, value);
      });
    } else if (name === "confirmPassword") {
      this.empPasswordConfirm(value, this.state.newPassword);
    } else if (name === "oldPassword") {
      this.setState({ oldPassword: value }, this.validatePasswords);
    }
  };

  passwordCheck = () =>{
    this.setState({ Loader: true })
    const { oldPassword , newPassword} = this.state;
    if( oldPassword === newPassword){
      toast.error("Old Password and New Password should be different.",  { hideProgressBar: true ,position: "top-center", style: { backgroundColor: '#003111', color: 'white' }} )
      this.setState({ Loader: false })
    }else{
      this.changePasswordApi()
    }
  }

  changePasswordApi = async() => {
    const token = await getStorageData("token");
    const endPoint = this.state.role === "company" ?  configJSON.changePasswordEndPoint : configJSON.hangePasswordCApiEndPoint
    const header = {
      "Content-Type": configJSON.contentTypeApiAddDetail,
      token: token,
    };

    const data = {
      old_password: this.state.oldPassword,
      password:this.state.newPassword,
      password_confirmation:this.state.confirmPassword
    };
    const httpBody = {
      data,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.changePasswordApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
      // configJSON.changePasswordEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.callTypeApiValidateMobileNo
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  
  handlePaymentDialogBox = () =>{
    this.setState({ isDialogOpen:true });     
  }
  handleSuccessDialogBox = () =>{
    this.setState({ successDialogOpen:true,failDialogOpen:false, });
  }
  handleFailDialogBox = () =>{
    this.setState({ failDialogOpen:true ,isDialogOpen:false});
  }
  handleCancel = () => {
    this.setState({ 
        anchorEl: null,
        isDialogOpen: false,
        failDialogOpen: false,
        successDialogOpen: false,
        editSuccessDialogOpen: false
    }, () => {
        this.goToScreen("UserProfileBasicWeb");
    });
};
capitalizeUserName = (name: string | null | undefined): string => {
  if (!name) return ''; 

  return name
    .toLowerCase()
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
};
  // Customizable Area End
}

