import React from "react";



export const LazyLoader = () => {
    return (
      <div className="loader">
      <div className="sphere">
<style>
    {`
    .loader {
  display: flex;
  justify-content: center;
  align-items: center; 
  position:relative;
}

.sphere {
  width: 20px; 
  height: 20px; 
  border-radius: 50%; 
  background-color: rgb(0, 255, 0); 
  animation: bounce 1s infinite cubic-bezier(.36,.07,.19,.97); 
  transform-origin: bottom center; 
}

@keyframes bounce {
  0%, 100% {
    transform: translateY(0); 
  }
  50% {
    transform: translateY(-50px); 
  }
}
    
    `}
</style>


</div>


    </div>
  
 
    )
  }



