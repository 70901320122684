import React, { Component } from 'react';
import { Dialog, DialogActions, DialogTitle, Button } from '@material-ui/core';
import { styled } from '@material-ui/styles';

interface ConfirmDialogProps {
  open: boolean;
  title: string;
  btnText?: string
  onCancel: () => void;
  onConfirm: () => void;
}

export default class ConfirmDialog extends Component<ConfirmDialogProps> {
  render() {
    const { open, title, onCancel, onConfirm, btnText } = this.props;
    
    return (
      <StyledDialog
        open={open}
        onClose={onCancel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
       ><img onClick={onCancel} style={{padding:"16px 16px 8px 8px",width: "24px",height: "24px", alignSelf: "end" ,cursor:"pointer"}} src={require("../src/cross_icon.png")} />
        <StyledDialogTitle id="alert-dialog-title">{title}</StyledDialogTitle>
        <StyledDialogActions>
          <StyledButton style={{ color:"#00FF00"}} onClick={onCancel} color="primary">
            Cancel
          </StyledButton>
          <StyledButton style={{backgroundColor:"#00FF00",color:"#242424",border: "none"}} onClick={onConfirm} color="primary" autoFocus>
            {btnText}
          </StyledButton>
        </StyledDialogActions>
      </StyledDialog>
    );
  }
}

const StyledDialog = styled(Dialog)({
    "& .MuiDialog-paperWidthSm": {
        background:"#505050",
        minHeight:"220px",
        minWidth:"560px",
        padding:"35px, 0px, 0px, 0px",
        gap:"2px"
    }
  });

const StyledDialogTitle = styled(DialogTitle)({
  color: '#ffffff',
  marginBottom: "24px",
  padding:"0px",
  "& .MuiTypography-h6":{
    fontFamily:"Lato",
    fontWeight:700,
    fontSize:"24px",
    lineHeight:"32px",
    letterSpacing:"-0.5%",
    margin: "0px 40px",
    width: "85%",
    wordWrap: "break-word"
  }
});

const StyledDialogActions = styled(DialogActions)({
    height: "96px",
});

const StyledButton = styled(Button)({
    color: '#ffffff',
    padding: '12px 20px',
    border: "2px solid #FFFFFF",
    height: "48px",
    width: "162px",
    gap: "8px",
    fontFamily: "Lato",
    textTransform: "capitalize",
    fontSize:"16px",
    lineHeight:"16px",
    alignItems: "center",
    '&:hover': {
        backgroundColor: '#555555',
    },
});
