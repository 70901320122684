// Customizable Area Start
export const upArrow = require("../assets/up.png");
export const downArrow = require("../assets/down.png");
export const approvedVerification = require("../assets/group_icon.png");
export const pendingVerification = require("../assets/group_timer.png");
export const rejectedVerification = require("../assets/group_icon (1).png");
export const clearVerification = require("../assets/group_icon (2).png");
export const downloadIcon = require("../assets/image_.png");
export const pdfIcon = require("../assets/image_texts.png");
export const calanderIcon = require("../assets/image_icon.png")
// Customizable Area End