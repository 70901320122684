import React from "react";

// Customizable Area Start
import { Box, Button, Grid, Typography, styled } from "@material-ui/core";
import { LazyLoadImage } from "react-lazy-load-image-component";
import {BlurSubscription} from "./assets";
// Customizable Area End

import EmailAccountRegistrationController, {
  Props,
} from "./EmailAccountRegistrationController.web";
import { FeaturedIcon, SelectSubscriptionImg, check,arrowIconBack } from "./assets";

export default class SelectSubscription extends EmailAccountRegistrationController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  render() {
     return (
      <SelectSubscriptionStyling> 
        <div className="totalHeigth">
        <Box style={{ height: "100px" }}>  
        <img
          src={arrowIconBack}
          alt="Arrow Icon"
          data-test-id="btnback"
          style={{ cursor: "pointer",width:'28px',position:'absolute',left:100,top:78}}
          onClick={this.handleBackNavigation}  
          />           

               <LazyLoadImage
               
               src={SelectSubscriptionImg}
               placeholderSrc={BlurSubscription}
               style={
                {
                  width: "100%",
                  objectFit:"cover",
                  height:"170px",
                 }}
               effect="blur"
               alt="Subscription Title"
               wrapperClassName="subscription"
               data-test-id="loadingImageTest"
                />
              <style>
                {`
                .subscription{
                  width:100%;
                  height:170px;
                  objectFit:cover;
                }
                `}
              </style>
        </Box>
        <Box className="subsCard">
          <Box className="cardBox">
            <Grid container>
              <Grid item xs={12} lg={6} md={6} sm={12}>
                <Box className="leftsidecard">
                  <Box
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                     <img
                       src={FeaturedIcon} style={{ width: "46px" }} />
                     <Typography className="BusinessLable cardLabel">
                    {this.state.accountSubscription.name}
                    </Typography>
                  </Box>
                  <Box>
                    <Typography className="pricetag cardLabel">
                      ${this.formatPrice(this.state.accountSubscription.price)}/Month
                    </Typography>
                    <Typography className="lisTitle2">
                    Receive {this.state.accountSubscription.storeCreditId} free KYC credits!                      
                    </Typography>
                    <Typography className="lisTitle2">
                      {this.state.accountSubscription.description}
                    </Typography>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} lg={6} md={6} sm={12}>
                <Box style={{paddingTop:"28px",display:"flex",flexDirection:"column",justifyContent:"space-between",height:"80%"}}>  
                <div>                
                  <ul className="ulStyle">
                  {this.state.accountSubscription.subscriptionsPlanBenefitId.map((list, index) => {
                      return (
                     <li key={index} className="listBox">
                         <img src={check} alt="check" />
                           <Typography className="lisTitle">
                                 {list}
                            </Typography>
                      </li>
                     );
                   })}                
                  </ul>
                </div>
                  <Box
                    style={{ width: "80%", margin: "auto", display: "flex" }}
                  >
                    <Button
                      className="customSubscribeButton"
                      onClick={this.props.stepUpdate}
                    >
                      Continue
                    </Button>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
        </div>
      </SelectSubscriptionStyling>
    );
  }
}
const SelectSubscriptionStyling = styled(Box)({
  "& .customSubscribeButton": {
    width: "100%",
    height: "56px",
    textTransform: "none",
    marginTop: "4%",
    backgroundColor: "#00FF00",
    fontWeight: 600,
    margin: "auto",
    fontFamily: "Lato",
    fontSize: "16px",
    lineHeight: "24px",
  },
  "& .cardLabel": {
    color: "#FFFFFF",
  },
  "& .totalHeigth": {  
    "@media (max-width: 960px)": {
      height: "100%",
      overflowY:"auto"
    },  
  },
  "& .lisTitle": {
    fontWeight: 400,
    fontSize: "16px",
    color: "#FFFFFF",
    paddingLeft: "12px",
    fontFamily: 'Lato',
    lineHeight: '24px',
    textAlign: 'left',
  },
  "& .lisTitle2": {
    fontWeight: 400,
    fontSize: "16px",
    color: "#D3D3D3",
    paddingLeft: "12px",
    fontFamily: 'Lato',
    lineHeight: '24px',
    textAlign: 'center',
  },
  "& .ulStyle": {
    listStyleType: "none",
  },
  "& .listBox": {
    display: "flex",
    paddingBottom: "10px",
  },
  "& .subsCard": {
    backgroundColor: "black",
    minHeight: "calc(100vh - 170px)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    overflowY:"auto",
    boxSizing:"border-box",
    "@media (max-width: 960px)": {
    height:"100%",
    },  

  },
  "& .cardBox": {
    backgroundColor: "#1A1D1F",
    width: "50%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    paddingTop: "1.25%",
    paddingBottom: "1.25%",
    border: "1px solid #FBD400",
    minWidth:"350px"
  },
  "& .leftsidecard": {
    width: "fit-content",
    margin: "auto",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    paddingTop: "38px",
    paddingBottom: "0px",
    paddingLeft: "32px",
    paddingRight: "32px",
    gap: "8px",
  },
  "& .pricetag": {
    fontWeight: 600,
    fontFamily: "Lato",
    fontSize: "48px",
    lineHeight: "60px",
    letterSpacing: "-0.02em",
    textAlign: "center",
    "@media (max-width: 700px)": {
      fontSize: "30px",
    },
  },
  "& .BusinessLable": {
    fontWeight: 700,
    fontSize: "20px",
    fontFamily: "Inter",
    lineHeight: "30px",
    color: "#D3D3D3",
  },
  "& .popularplan": {
    fontSize: "0.8vw",
    fontWeight: 400,
    width: "fit-content",
    margin: "auto",
  },
  "& .loading-text": {   
    fontWeight: "bold",
    paddingTop: "20px",
    display:'flex',
    justifyContent:"center"
  },
});
