import React from 'react';
import jsPDF from 'jspdf';


const handleConvert = async (imageUrl: string, setDownloadPdfLoader: (loading: boolean) => void) => {
  try {
    setDownloadPdfLoader(true); 
    const response = await fetch(imageUrl);
    const blob = await response.blob();

    const imgUrl = URL.createObjectURL(blob);
    const img = new Image();
    img.src = imgUrl;

    img.onload = () => {
      const pdf = new jsPDF({
        orientation: 'portrait',
        unit: 'px',
        format: [img.width, img.height],
      });

      pdf.addImage(img, 'PNG', 0, 0, img.width, img.height);
      setDownloadPdfLoader(false); 
      pdf.save('converted-image.pdf');

      URL.revokeObjectURL(imgUrl);
    };
  } catch (error) {
    setDownloadPdfLoader(false); 
    console.error('Error converting image to PDF:', error);
  }
};


interface DownloadPdfProps {
  downloadIcon: string;
  document: string;
  setDownloadPdfLoader: (loading: boolean) => void;
}


const DownloadPdf: React.FC<DownloadPdfProps> = ({ downloadIcon, document, setDownloadPdfLoader }) => {
  return (
    <>
      <img
        src={downloadIcon}
        className="downloadBtn"
        data-testid="pdfDownload" 
        alt="Download PDF"
        onClick={() => handleConvert(document, setDownloadPdfLoader)} 
        style={{cursor:"pointer"}}
      />
    </>
  );
};

export default DownloadPdf;
