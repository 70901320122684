import React from "react";
// Customizable Area Start
import {
    Box,
    Typography,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TableContainer,
    Button,
    Input,
    IconButton,
    Menu,
    MenuItem,
    Grid
} from "@material-ui/core";
import { styled } from "@material-ui/styles";
import Pagination from '@mui/material/Pagination';
import ClearIcon from '@material-ui/icons/Clear';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import SearchIcon from '@material-ui/icons/Search';
import CustomFormController, {
    Props,
    configJSON,

} from "./CustomFormController.web";
import Headers from '../../../components/src/Headers.web';
import ConfirmDialog from '../../../components/src/CustomDialogBox.web';
import SuccessDialog from '../../../components/src/SuccessDailogox.web';
import Skeleton from '@material-ui/lab/Skeleton';

// Customizable Area End

export default class PeopleManagement extends CustomFormController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  renderSkeletonLoader3=()=>{

    const Arr=[1,2,3,4,5];
    return(
    
  <TableBody>
        {
  
        Arr.map((i,ind)=>(
        <TableRow key={ind}>
  {
    Arr.map((i,ind1)=>(
      <TableCell key={ind1}>
      <Skeleton width={200} style={{background: ' rgb(113, 108, 108)', borderRadius: '6px'}}    variant="text" />
      </TableCell>
    ))
  }
        </TableRow>
        ))
  }
  </TableBody>
    )
  }

  renderConditionally=()=>{
    const { loading, noDataFoundPeople } = this.state;

  if (loading) {
    return this.renderSkeletonLoader3();
  }

  return noDataFoundPeople
    ? this.renderNoDataFoundEmployee()
    : this.renderEmployeeBody();
  }

  renderNoDataFoundEmployee=()=>{
    return(
      <TableBody>
<TableCell colSpan={5}>
              <div style={{display:'flex',alignItems:'center',flexDirection:'column',justifyContent:'center',padding:"50px 0px",
              background: '#242424'}}>


              <img src={require("../assets/image_.svg")} style={{width:'25%'}}  />
  <p style={{color:'white',fontWeight:'bold', fontSize:'20px'}}>No Data Found</p>
    
         </div> 
         </TableCell>
         </TableBody>
        )

  }

renderEmployeeBody = () =>{
    return(
        <TableBody>
        {
        
        this.state.peopleManagementData.length > 0 && this.state.peopleManagementData.map((singleData) => {
                return (
                    <TableRow data-testId={singleData.id} key={singleData.id}>
                        <TableCell className="dobTableTxt">{singleData.email}</TableCell>
                        <TableCell className="nationalityText">
                       {singleData?.employee_name
                         ? (singleData.employee_name.length > 8
                             ? singleData.employee_name.charAt(0).toUpperCase() + singleData.employee_name.slice(1, 8).toLowerCase() + '...'
                             : singleData.employee_name.charAt(0).toUpperCase() + singleData.employee_name.slice(1).toLowerCase())
                         : ""}
                     </TableCell>
                     <TableCell className="countryTableText">{singleData?.full_phone_number}</TableCell>
                     <TableCell className="countryTableText">{singleData?.activated  ? "Active":"Pending"}</TableCell>
                        <TableCell className="countryTableText">
                           <IconButton
                                data-testId = "IconButton_test"
                                aria-label="more"
                                aria-controls="long-menu"
                                aria-haspopup="true"
                                onClick={(event) => this.handleClickID(event, singleData.id)}
                            >
                                <MoreVertIcon />
                            </IconButton>
                            <MenuStyle
                                id="long-menu"
                                anchorEl={this.state.anchorEl}
                                keepMounted
                                open={Boolean(this.state.anchorEl && this.state.selectedId === singleData.id)}
                                onClose={this.handleClose}
                            >
                                {singleData?.activated ?<MenuItem style={{color:"white",background:"#505050",fontFamily: "Lato",fontSize: "14px",fontWeight: 400,
                                    lineHeight: "22px",
                                    textAlign: "left"}} data-testId = "editScreenTet_id" onClick={() => this.goToEditEmployee("",singleData.id,"EmployeeEditScreen")}  
                                    > <img style={{padding:"0px 16px 0px 8px"}} src={require("../assets/edit_.png")} />Edit</MenuItem>:null}

                                {singleData?.activated ?<MenuItem style={{color:"white",background:"#505050",fontFamily: "Lato",fontSize: "14px",fontWeight: 400,
                                    lineHeight: "22px",
                                    textAlign: "left"}} data-testId = "viewkycchecklist_test" onClick={() => this.goToEditEmployee(singleData.email,singleData.id,"ViewKycChecklist")} ><img style={{padding:"0px 16px 0px 8px"}} src={require("../assets/view_.png")} />View kyc checklist</MenuItem>:null}
                                
                                <MenuItem style={{color:"white",background:"#505050",fontFamily: "Lato",fontSize: "14px",fontWeight: 400,
                                    lineHeight: "22px",
                                    textAlign: "left"}} data-testId = "deleteButton_test" onClick={() => this.handleDialogBox(singleData.id)}><img style={{padding:"0px 16px 0px 8px"}} src={require("../assets/delete.png")} />Delete</MenuItem>
                            </MenuStyle>
                        </TableCell>
                    </TableRow>
                )
            })
        }
    </TableBody>
    )
}

renderEmployeForm = () => {    
    return (
        <div style={{height: "100%"}}>
        <MainContent style={{overflowY:"auto", height: "calc(100% - 71px)",boxSizing:"border-box"}}>
                <PageTop className="pageMain">
                    <Typography className="headerText" >{configJSON.employeeInformation}  ({this.state.peopleManagementData.length})</Typography>
                    <Box className="searchContainer">
                    <Input
                                className="searchInputfield"
                                fullWidth
                                placeholder="Search"
                                disableUnderline
                                name="cardNumber"
                                data-testId="cardNumberTestId"
                                onChange={this.handleChangeEmployee}
                                value={this.state.searchEmpValue}
                                startAdornment={
                                    <SearchIcon className="searchIcon" />
                                }
                                endAdornment={
                                    this.state.searchEmpValue &&
                                    <ClearIcon  onClick={this.clearDataEmployee} className="crossIcon" />
                                }
                            />

                        <Button data-testId = "inviteButtonTest" onClick={this.navigateInviteScreen} className="searchBtn" variant="contained">{configJSON.createEmployee}</Button>
                    </Box>
                </PageTop>
                <StyledTableContainer>
                    <StyledTable>
                        <TableHead>
                            <TableRow>
                                <TableCell className="emailTableCell">{configJSON.emailId}</TableCell>
                                <TableCell className="dobTableCell">{configJSON.contactName}</TableCell>
                                <TableCell className="contactNameTableCell">{configJSON.phoneNumber}</TableCell>
                                <TableCell className="phoneNumTableCell">Status</TableCell>
                                <TableCell className="phoneNumTableCell">{configJSON.action}</TableCell>
                            </TableRow>
                        </TableHead>
                        {this.renderConditionally()}

                    </StyledTable>
                </StyledTableContainer>
                {
                    this.handleLoaderPm() &&
                    <PagginationBox>
                        <Pagination data-testId="paginationTestId" count={Math.ceil(this.state.totalEmployeeDataCount / 10)} variant="outlined" shape="rounded" onChange={this.handleEmployeePageChange} page={this.state.empPageCount} />
                    </PagginationBox>
                }
        </MainContent>
        </div>
    )
}
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <Headers sliderButton={this.state.sideBarClose}
      logOutToogle={this.logOutBtn} 
      data-testId="logoutTestId"
      onToggleClick={this.handleSliderButton}
      goToScreen = {this.goToScreen}
      isRouteData="PeopleManagement"
      >
             {this.renderEmployeForm()}
            <ConfirmDialog
                data-testId="confirmDialog_test"
                open={this.state.isDialogOpen}
                title={configJSON.popUpData}
                onCancel={this.handleCancel}
                onConfirm={this.handleConfirm}
                btnText="Delete"
            />
            <SuccessDialog
                disc={configJSON.sucessMsg}
                open={this.state.successDialogOpen}
                onClose={this.handleCancel}
            />
        </Headers>
      // Customizable Area End
    );
  }
}
// Customizable Area Start
const StyledTableContainer = styled(TableContainer)({
    width: "Fixed (1,264px)px",
    height: "75vh",
    gap: "24px",
    opacity: "0px",
  '&::-webkit-scrollbar': {
    width: '0.1px', 
  },
  '&::-webkit-scrollbar-track': {
    boxShadow: "inset 0 0 5px grey",
    borderRadius: "10px",
    background:"rgb(24,25,27)"
  },
  '&::-webkit-scrollbar-thumb': {
    background:"rgb(0,255,0)",
    borderRadius: "10px"
  },
  '&::-webkit-scrollbar-thumb:hover': {
    backgroundColor: 'rgb(41,253,50);',
  },

  '&': {
    scrollbarWidth: 'thin',
    scrollbarColor: 'rgb(0,255,0) rgb(24,25,27)', // thumb color and track color
  },
    "& .userImg": {
        width: "48px",
        height: "48px",
        borderRadius: "48px"
    },
    "& .peopleIcon":{
        fontFamily: "Lato",
        fontSize: "14px",
        fontWeight: 400,
        lineHeight: "22px",
        textAlign: "left"
    }
});

const StyledTable = styled(Table)({
    backgroundColor: "#18191b",
    "& .MuiMenu-list":{
        background: "red"
      },
    "& .MuiTableCell-root": {
        borderBottom: "1px solid #272B30",
    },
    "& .MuiIconButton-root":{
        color :"#A7A7A7"
    },
    "& .MuiTableCell-head": {
        color: "#fff",
        width: "max-content",
    },
    "& .MuiTableCell-body": {
        color: "#6F767E",
        fontSize: "14px",
        fontWeight: 600
    },
    "& .dobTableTxt": {
        fontWeight: 600,
        color: "#6F767E",
        fontSize: "14px",
        fontFamily: 'Lato',
        height: "81px",
        width: "24px"
    },
    "& .countryTableText": {
        color: "#6F767E",
        fontSize: "14px",
        fontWeight: 600,
        // width: "84px",
        height: "24px",
        fontFamily: 'Lato',
    },
    "& .nationalityText": {
        width: "80px",
        color: "#6F767E",
        fontSize: "14px",
        fontWeight: 600,
        fontFamily: 'Lato',
        height: "24px",
        textTransform: "capitalize"
    },
    "& .lato": {
        fontFamily: 'Lato'
    },
    "& .dobTableCell": {
        width: "30%",
        fontFamily: 'Lato',
        color: 'rgba(211, 211, 211, 1)',
        height: '16px',
        gap: '0px',
        opacity: "0px",
        fontWeight: 600
    },
    "& .emailTableCell":{
        width: "40%",
        fontFamily: 'Lato',
        color: 'rgba(211, 211, 211, 1)',
        height: '16px',
        gap: '0px',
        opacity: "0px",
        fontWeight: 600
    },
    "& .contactNameTableCell":{
        color: 'rgba(211, 211, 211, 1)',
        width: "20%",
        fontFamily: 'Lato',
        height: '16px',
        gap: '0px',
        opacity: "0px",
        fontWeight: 600
    },
    "& .phoneNumTableCell":{
        fontFamily: 'Lato',
        color: 'rgba(211, 211, 211, 1)',
        height: '16px',
        width: "10%",
        gap: '0px',
        opacity: "0px",
        fontWeight: 600
    },
    "& .peopleIcon":{
        fontFamily: "Lato",
        fontSize: "14px",
        fontWeight: 400,
        lineHeight: "22px",
        textAlign: "left"
    }
})

const PageTop1 = styled(Box)({
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    height: "44px",
    paddingBottom: "20px",
    "& .searchContainer": {
        display: "flex",
        justifyContent: "flex-start",
        gap: "15px",
        outline: "none",
        alignItems: "center",
        border: "none",
        borderRadius: "0px"
    },
    "& .searchInputfield": {
        backgroundColor: "#505050",
        color: "#fff",
        height: "44px",
        fontSize: "16px",
        fontWeight: 400,
        fontFamily: "Lato",
        width: "320px",
        outline: "none",
        border: "none",
        borderRadius: "0",
        padding: "10px",
        "& .MuiInputBase-input": {
            width: "272px",
            height: "24px",
            fontFamily: "Lato",
        }
    },
    "& .MuiOutlinedInput-inputAdornedStart": {
        color: "#A7A7A7",
        height: "7px",
    },
    "& .searchIcon": {
        color: "#A7A7A7",
        marginRight: "20px",
        width: "17.05px",
        height: "17.06px"
    },
    "& .searchBtn": {
        height: "44px",
        width: "181px",
        backgroundColor: "#00FF00",
        color: "#242424",
        borderRadius: "0px",
        textTransform: "none",
    },
    "& .pageMain": {
        height: "44px",
        width: "1333px",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center"
    },

})

const PageTop = styled(Box)({
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    paddingBottom: "20px",
    flexWrap:"wrap",
    "& .searchContainer": {
        display: "flex",
        justifyContent: "flex-start",
        gap: "15px",
        outline: "none",
        alignItems: "center",
        border: "none",
        borderRadius: "0px",  
        flexWrap:"wrap",
        margin:"10px 0px",
        "& .searchInputContainer":{
            position: "relative",
            "& .searchStatusContainer":{
                position: "absolute",
                padding: "16px",
                display: "flex",
                justifyContent: "space-between",
                gap: "10px",
                backgroundColor: "rgba(36, 36, 36, 1)",
                width: "100%",
                listStyle: "none",
                margin: 0,
                height:"10px",
                "& .searchStatus":{
                    padding: "12px",
                    fontSize: "8px",
                    fontWeight: 600,
                    lineHeight: "20px",
                    fontFamily: "lato",
                    textTransform: "capitalize",
                    cursor: "pointer",
                    display:"flex",
                    justifyContent:"center",
                    alignItems:"center"
                },
                "& .searchApprovedStatus":{
                    border: "1px solid rgba(0, 255, 0, 1)",
                    color: "rgba(0, 255, 0, 1)",
                },
                "& .searchPendingStatus":{
                    border: "1px solid rgba(251, 212, 0, 1)",
                    color: "rgba(251, 212, 0, 1)",
                },
                "& .searchRejectedStatus":{
                    border: "1px solid rgba(255, 25, 56, 1)",
                    color: "rgba(255, 25, 56, 1)",
                },
                "& .searchClarificationStatus":{
                    border: "1px solid rgba(255, 122, 1, 1)",
                    color: "rgba(255, 122, 1, 1)",
                },
            }
        }
    },
    "& .searchInputfield": {
        backgroundColor: "#505050",
        color: "#fff",
        height: "44px",
        fontSize: "16px",
        fontWeight: 400,
        fontFamily: "Lato",
        width: "320px",
        outline: "none",
        border: "none",
        borderRadius: "0",
        padding: "10px",
        "@media (max-width: 550px)": {
            width: "220px",
            }, 
        "& .MuiInputBase-input": {
            width: "272px",
            height: "24px",
            fontFamily: "Lato",
        }
    },
    "& .MuiOutlinedInput-inputAdornedStart": {
        color: "#A7A7A7",
        height: "7px",
    },
    "& .searchIcon": {
        color: "#A7A7A7",
        marginRight: "20px",
        width: "17.05px",
        height: "17.06px",
        cursor:"pointer"
    },
    "& .crossIcon":{
        color: "#A7A7A7",
        marginRight: "20px",
        width: "18.05px",
        height: "18.06px",
        cursor:"pointer"
    },
    "& .searchBtn": {
        height: "44px",
        width: "181px",
        backgroundColor: "#00FF00",
        color: "#242424",
        borderRadius: "0px",
        textTransform: "none",
    },
    "& .pageMain": {
        height: "44px",
        width: "1333px",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center"
    },

})

const MainContent = styled(Box)({
    padding: "20px",
    height:"100vh",
    backgroundColor: "rgba(26, 29, 31, 1)",
    fontFamily: 'Lato',
    "& .MuiPopover-paper":{
        background:"red"
    },
    "& .headerText": {
        display: "flex",
        color: "rgba(252, 252, 252, 1)",
        fontSize: "40px",
        fontWeight: 600,
        fontFamily: 'Lato',
        width: "510px",
        height: "32px",
        marginBottom: "20px",
        "@media (max-width: 1200px)": {
            fontSize: "30px",
          },
          "@media (max-width: 800px)": {
            fontSize: "20px",
          },
    },
    "& .loadMoreBtnBox": {
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginTop: "16px",
        height:"80px",
        color:"#6F767E"
    },
    "& .loadMoreBtn": {
        textTransform: "none",
        color: "white",
        height: "48px",
        border: "2px solid rgba(39, 43, 48, 1)",
        fontSize: "15px",
        fontWeight: 700,
        borderRadius: "12px",
        fontFamily: 'Lato',
    },
})

const MenuStyle = styled(Menu)({
    "& .MuiPopover-paper":{
        background: "#505050"
      },
})

const UserName = styled(Box)({
    display: "flex",
    alignItems: "center",
    gap: "12px"
})

const TableDocWrapper = styled(Box)({
    "& .singleDoc": {
        display: "flex",
        alignItems: "center",
        gap: "8px",
    },
    "& .downloadBtn": {
        width: "16px",
        height: "16px"
    }
})

const StatusWrapper = styled(Box)({
    display: "flex",
    alignItems: "center",
    gap: "8px",
    "& .approvedBtn": {
        backgroundColor: '#41d613',
        color: 'rgba(36, 36, 36, 1)',
        width: "76px",
        height:"28px",
        textTransform: "none",
        borderRadius: "0px",
        fontFamily: "Lato",
        fontWeight: 600,
        fontSize: "12px",
        lineHeight:"24px"
    },
    "& .pendingBtn": {
        backgroundColor: 'yellow',
        color: 'rgba(36, 36, 36, 1)',
        width: "80px",
        height:"28px",
        extTransform: "none",
        marginLeft: "33px",
        textTransform: "none",
        borderRadius: "0px",
        fontFamily: "Lato",
        fontWeight: 600,
        fontSize: "12px",
        lineHeight:"24px"
    },
    "& .rejectedBtn": {
        backgroundColor: 'red',
        color: 'rgba(36, 36, 36, 1)',
        width: "98px",
        height:"28px",
        textTransform: "none",
        borderRadius: "0px",
        fontFamily: "Lato",
        fontWeight: 600,
        fontSize: "12px",
        lineHeight:"24px"
    },
    "& .needClarificationBtn": {
        backgroundColor: "#FF7A01",
        color: "rgba(36, 36, 36, 1)",
        width: "200px",
        height:"36px",
        textTransform: "none",
        borderRadius: "0px",
        fontFamily: "Lato",
        fontWeight: 400,
        fontSize: "14px",
        lineHeight:"24px"
    },
    "& .downloadBtn": {
        width: "16px",
        height: "16px"
    },
    "& .downloadLargeBtn": {
        width: "24px",
        height: "24px"
    }
})

const customStyles = {
    noDataFoundDiv: {
      textAlign: 'center'
    } as React.CSSProperties,
    noDataFoundBox: {
      display: "flex", 
      justifyContent: "center", 
      margin: "30px 0px",
    },
    noDataFoundText: {
      fontSize: "16px",
      fontWeight: 500,
      fontFamily: "Open Sans",
      color: "#390689"
    },
  }
const PagginationBox = styled(Box)({
    marginLeft: "auto",
    marginTop: "20px",
    width: "max-content",
    "& .MuiSvgIcon-root": {
        fill: "grey"
    },
    "& .MuiPaginationItem-page": {
        backgroundColor: "unset",
        color: "rgba(0, 255, 0, 1)",
        borderBottom: "unset !important",
    },
    "& .MuiPaginationItem-page.Mui-selected": {
        border: "1px solid grey !important"
    },
    "& .MuiPaginationItem-page.Mui-selected::before": {
        backgroundColor: "transparent !important",
    },
    "& .MuiPagination-ul .MuiButtonBase-root": {
        position: "relative",
        borderRadius: "50%",
        border: "none"
    },

    "& .MuiPagination-ul .MuiButtonBase-root::before": {
        content: "",
    },
    "& .MuiPagination-ul li:first-child .MuiButtonBase-root::before": {
        backgroundColor: "transparent !important",
    },
    "& .MuiPagination-ul li:last-child .MuiButtonBase-root::before": {
        backgroundColor: "transparent !important",
    },
    "& .showingTxt": {
        fontFamily: "Plus Jakarta Sans",
        fontSize: "14px",
        color: "#212121"
    }
});
// Customizable Area End
