import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import { Message } from "../../../framework/src/Message";
import { getStorageData, setStorageData,removeStorageData } from "../../../../packages/framework/src/Utilities";
import { toast } from "react-toastify";
import { setTimeout } from "timers";
type InitialFalseTrue = "initial" | "false" | "true";
interface ErrorPasswordObject {
  alphabet: InitialFalseTrue;
  minimumChar: InitialFalseTrue;
  capitalChar: InitialFalseTrue;
  specialSign: InitialFalseTrue;
  number: InitialFalseTrue;
}
interface ResendResponse {
  meta: {
      token: string;
      message: string;
  }
};

interface AccountSubscriptionAPi {
  data: {
    id: string;
    type: string;
    attributes: {
      name: string;
      price: string;
      description: string;
      store_credit_id: number;
      subscriptions_plan_benefit_id: [];
      image_link: string;      
    };
  }[];
}

interface SuccessResponse {
    message: string;
}
interface ConfirmResponse {
  message: string;
  token:string
  meta:{token:string}
}

interface ErrorResponse {
  errors: Array<{ [key: string]: string }>
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    // Customizable Area Start  
  navigation: any;
  id: string;
//   stepUpdate: any;
  // Customizable Area End
}

export interface S {
  // Customizable Area Start  
    email: string;   
    otpData : {otp: string;};
    Loader:boolean;
    countTriger:boolean;
    disableLink:boolean;
    timer: number;
    count: number;
    enablePasswordField: boolean;
    enableReTypePasswordField: boolean;
    validPass: boolean;
    confirmPass: boolean;
    isCheckPassword : boolean;
    step: number;
    imageLoading: boolean;
    PersonEmail:string;
    disabledBtn: boolean;
    openModal:boolean;
    navigateCheck :number;
    otpSetCheck :boolean;
    emailError: string;
    enableBtn: boolean;
    employeErrorData: {
      empPassword: ErrorPasswordObject;
    };
    formData: {
      password: string;
      reTypePassword: string;
    };
    employeeData: {
      companyId: string;
      contactNumber: string | number;
      dailNumber: string;
      employeeName: string;
      employeeEmail: string;
      empPassworrd: string;
      empConfirmPass: string;
      PhoneNumberValidationCheck:number;
    };
    formErrorData: {
      firstName: string;
      lastName: string;
      email: string;
      password: ErrorPasswordObject;
      reTypePassword: string;
    };
    forgetEmail: string;
    inputData: {
      email: string;  
    };
    countTimer:number;
    showTimer:boolean;
    accountSubscription: {
      name: string,
      price: string,
      description: string,
      storeCreditId: number,    
      imageLink: string,
      subscriptionsPlanBenefitId: []
     }
  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class EmailAccountRegistrationWebController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiOtpCallId: string = "";
  apiResendOtpCallId:string = "";
  resendApiCallId : string ="";
  forgetPasswordAccountApiCallId : string = "";
  accountSubscriptionApiCallId: string="";
  checkAlphabet: RegExp;
  checkCapital: RegExp;
  checkDigit: RegExp;
  checkSpecialChar: RegExp;
  // Customizable Area End

  constructor(props: Props) {
    super(props);  
     // Customizable Area Start
    // Customizable Area End
    this.subScribedMessages = [
       // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
        
       // Customizable Area End    
    ]; 
    this.receive = this.receive.bind(this);    
    runEngine.attachBuildingBlock(this, this.subScribedMessages);
    this.checkAlphabet = /[a-zA-Z]/;
    this.checkCapital = /[A-Z]/;
    this.checkDigit = /\d/;
    this.checkSpecialChar = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
    this.state = {
      // Customizable Area Start    
      email: "",
      otpData: { otp: "" },
      Loader: false,
      countTriger: false,
      imageLoading: false,
      step: 1,
      PersonEmail: "",
      disableLink: true,
      timer: 0,
      count: 1,
      disabledBtn: true,
      enablePasswordField: true,
      emailError: "",
      navigateCheck: 0,
      enableBtn: true,
      isCheckPassword: false,
      openModal: false,
      confirmPass: false,
      otpSetCheck: false,
      enableReTypePasswordField: true,
      validPass: true,
      employeErrorData: {
        empPassword: {
          alphabet: this.state?.employeErrorData?.empPassword?.alphabet
            ? "false"
            : "true",
          capitalChar: this.state?.employeErrorData?.empPassword?.capitalChar
            ? "false"
            : "true",
          minimumChar:
            this.state?.formData?.password.length >= 8 ? "false" : "true",
          number: this.state?.employeErrorData?.empPassword?.number
            ? "false"
            : "true",
          specialSign: this.state?.employeErrorData?.empPassword?.specialSign
            ? "false"
            : "true",
        },
      },
      formData: {
        password: "",
        reTypePassword: "",
      },
      formErrorData: {
        firstName: "",
        lastName: "",
        email: "",
        password: {
          alphabet: this.state?.formErrorData?.password?.alphabet
            ? "false"
            : "true",
          capitalChar: this.state?.formErrorData?.password?.capitalChar
            ? "false"
            : "true",
          minimumChar:
            this.state?.formData?.password.length >= 8 ? "false" : "true",
          number: this.state?.formErrorData?.password?.number
            ? "false"
            : "true",
          specialSign: this.state?.formErrorData?.password?.specialSign
            ? "false"
            : "true",
        },
        reTypePassword: "",
      },
      employeeData: {
        companyId: "",
        contactNumber: "",
        dailNumber: "",
        employeeName: "",
        employeeEmail: "",
        empPassworrd: "",
        empConfirmPass: "",
        PhoneNumberValidationCheck: 100,
      },
      accountSubscription: {
        name: "",
        price: "",
        description: "",
        storeCreditId: 0,               
        imageLink: "",
        subscriptionsPlanBenefitId:[]
},
      forgetEmail: "",
      inputData: {
        email: "",
      },
      countTimer:300,
      showTimer:false,
      // Customizable Area End
    };
    // Customizable Area Start
    // Customizable Area End
  }
  async receive(from: string, message: Message) {
    // Customizable Area Start
    this.setState({ Loader: true });
  
  if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    let responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    
    if (apiRequestCallId === this.apiOtpCallId) {
      this.handleOtpResponse(responseJson);
    } else if (apiRequestCallId === this.apiResendOtpCallId) {
      this.handleResendOtpResponse(responseJson);
    }else if (apiRequestCallId === this.resendApiCallId){
      this.resendApiSuccessCallBack(responseJson)
    }
     else if (apiRequestCallId === this.forgetPasswordAccountApiCallId) {
      this.handleForgetPasswordResponse(responseJson);
    }
    else if (apiRequestCallId === this.accountSubscriptionApiCallId)
    {
      this.handleAccountSubscriptionResponseAdminInvite(responseJson)
    }
    
    this.setState({ Loader: false });
  }
    // Customizable Area End
  }
  
  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
    const PersonEmail =await getStorageData("resetEmail");
    const invitedBycompanyCheck = await getStorageData("invite")
    const companyInvitedByAdmin = await getStorageData("invite_admin")
    if(invitedBycompanyCheck=="true"){ 
      const PersonEmail =await getStorageData("email");
      await setStorageData("resetEmail", PersonEmail);
    this.setState({ navigateCheck: 1 ,PersonEmail: PersonEmail}); 
    }
    else if(companyInvitedByAdmin == 'true')
    {
      this.getAccountSubscription()
      const PersonEmail =await getStorageData("email");
      await setStorageData("resetEmail", PersonEmail);
    this.setState({ navigateCheck: 1 ,PersonEmail: PersonEmail}); 
    }
    else{
      this.setState({ PersonEmail: PersonEmail})
    }
    const countNo = await getStorageData("count");
    setInterval(this.timeIncrement,1000);
    if(countNo >= 3){
      this.setState({
        countTriger:true
      })     }
    
  }

  async componentDidUpdate(prevProps: unknown, prevState: { countTriger: boolean }) {
    if (!prevState.countTriger && this.state.countTriger) {
      setTimeout(async () => {
          await removeStorageData("count");
          this.setState({ countTriger: false, countTimer: 0});
      }, 60000*5); 
  }
  }
  handleOtpResponse(responseJson:ResendResponse & ErrorResponse ) {
    if (responseJson.meta) {
      setStorageData("token", responseJson.meta.token);
      this.setState({ navigateCheck: 1 });
    } else {
      this.showErrorMessage(responseJson.errors[0].otp);
    }
  }
  
  async handleResendOtpResponse (responseJson: ConfirmResponse & ErrorResponse) {
    if (!responseJson.errors) {
      const invitedBycompanyCheck = await getStorageData("invite")
      const companyInvitedByAdmin = await getStorageData("invite_admin")

    if(invitedBycompanyCheck=="true" || companyInvitedByAdmin=='true'){ 
      await setStorageData("token", responseJson.meta.token);
    }else{
      await setStorageData("token", responseJson.token);
    }
      this.setState({ navigateCheck: 2 });
    } else {
      this.showErrorMessage(responseJson.errors[0].otp);
    }
  }

  resendApiSuccessCallBack = (responseJson: ResendResponse & ErrorResponse) => {
    if (!responseJson.errors) {
      setStorageData("token", responseJson.meta.token)
    }else {
      this.showErrorMessage(responseJson.errors[0].token);
    }
  }
  
  handleForgetPasswordResponse(responseJson:SuccessResponse) {
    if (responseJson) {
      this.setState({ navigateCheck: 2, openModal: true });
    }
  }

 async handleAccountSubscriptionResponseAdminInvite(responseJson:any){
    const attributes = responseJson.data[0].attributes;
    await setStorageData("payment_name",responseJson.data[0].attributes.name);
    await setStorageData("payment_id",responseJson.data[0].id)
    await setStorageData("payment_type",responseJson.data[0].type)
    await setStorageData("payment_price",attributes.price)
      this.setState({
      accountSubscription: {
        ...this.state.accountSubscription,
        name: attributes.name,
        price: attributes.price,
        description: attributes.description,
        storeCreditId: attributes.store_credit_id,        
        imageLink: attributes.image_link,
        subscriptionsPlanBenefitId:attributes.subscriptions_plan_benefit_id
      },
    });
  }
  
  showErrorMessage(error: string) {
    toast.error(error, { 
      hideProgressBar: true, 
      position: "top-center", 
      style: { backgroundColor: '#003111', color: 'white' } 
    });
  }


  getOtp = (e: string) => {
    this.setState({
      otpData: {
        ...this.state.otpData,
        otp: e,
      },
    });
  };

  handleBackButtonClick = async() => {
    const msg: Message = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationTargetMessage), "EmailAccountLoginWeb");
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  };

  changeFileRender = async () => {
    const invitedBycompanyCheck = await getStorageData("invite")
    const companyInvitedByAdmin = await getStorageData("invite_admin")
    if(invitedBycompanyCheck=="true" || companyInvitedByAdmin == 'true' ){ 
     this.goToEmailAccountLoginWeb()
      return
    }

    this.setState({ navigateCheck: 0 });
  };

  timeIncrement = () => {
    const state = this.state;
    {
      if (state.timer > 0) {
        this.setState({ timer: state.timer - 1 });
        this.setState({disableLink : true })
      }
      else{
        this.setState({disableLink : false})
      }
      if (state.countTimer > 0 && state.count >= 3 && state.countTriger) {
        this.setState({ countTimer:state.countTimer-1 });
        this.setState({disableLink : true })
      }
    }

  };

  goToEmailAccountLoginWeb=()=>{
    const msg: Message = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationTargetMessage), "EmailAccountLoginWeb");
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  otpLogin = async () => {
    this.setState({ Loader: true })
    const token = await getStorageData("token")
    const invitedBycompanyCheck = await getStorageData("invite")
    const companyInvitedByAdmin=await getStorageData('invite_admin')
    if (token) {
      const header = {
        "Content-Type": configJSON.loginApiContentType,
        'token': token,
      };

      const data = {
        otp: this.state.otpData.otp
      };

      const httpBody = {
        data,
      };

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );


      let otpApiEndpoint;

      if (invitedBycompanyCheck === "true") {
        otpApiEndpoint = configJSON.otpApiEndpointEmployee;
      } else if (companyInvitedByAdmin === "true") {
        otpApiEndpoint = configJSON.otpApiEndPointAdminCompany;
      } else {
        otpApiEndpoint = configJSON.otpEndpoint;
      }

      this.apiResendOtpCallId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),otpApiEndpoint
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.loginAPiMethod
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
    };
    this.setState({ Loader: false })  
  };


  getAccountSubscription = () => {   
    const header = {
      "Content-Type": "application/json",
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.accountSubscriptionApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.accountSubscriptionEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'GET'
    );

      runEngine.sendMessage(requestMessage.id, requestMessage);
  };



  naviagateLogin =async () => {
    const msg: Message = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationTargetMessage), "EmailAccountLoginWeb");
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
    await removeStorageData('count');
  };

  restartTimer = async () => {
    const countNo = await getStorageData("count");
    this.setState({
      showTimer:true,
      timer: 20,
      count: Number(countNo) + 1,
    });
    await setStorageData("count", this.state.count)
    if (countNo >= 3) {
      this.setState({
        countTriger: true
      })
    }
    const getEmail = await getStorageData("email")

    const token = await getStorageData("token")
    if (token) {
      const header = {
        "Content-Type": configJSON.loginApiContentType,
        'token': token,
      };

      const httpBody = {
        email: getEmail
      };

      const requestMessageRestart = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.resendApiCallId = requestMessageRestart.messageId;
      requestMessageRestart.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.resendApiEndPoint
      );

      requestMessageRestart.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessageRestart.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
      );

      requestMessageRestart.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.loginAPiMethod
      );

      runEngine.sendMessage(requestMessageRestart.id, requestMessageRestart);
    }
  };

  createAccount = async () => {
    this.setState({ Loader: true })
    const token = await getStorageData("token")
    if (token) {
      const header = {
        "Content-Type": configJSON.loginApiContentType,
        'token': token,
      };

      const data = {
        password: this.state.formData.password,
        password_confirmation: this.state.formData.reTypePassword,
      };

      const httpBody = {
        data,
      };

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.forgetPasswordAccountApiCallId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.confirmPasswordEndPoint
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.loginAPiMethod
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
    };
    this.setState({ Loader: false })
  };

  emailSend = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;

    this.setState(prevState => ({
      inputData: {
        ...prevState.inputData,
        [name]: value
      }
    }), () => {
      let error = false;
      if (name === "email") {
        error = this.validateEmail(value);
      }
      error = error ? error : this.state.emailError != ""

      const { email, } = this.state.inputData;

      if (email.length !== 0 && !error) {
        this.setState({ disabledBtn: false });
      } else {
        this.setState({ disabledBtn: true });
      }
    });

  };

  validateEmail = (email: string) => {
    let error = false;
    const emailRegex = configJSON.emailRegex;
    if (!emailRegex.test(email)) {
      this.setState({ emailError: "Invalid email" });
      error = true;
    } else {
      this.setState({ emailError: "" });
    }
    return error;
  };


  handlePasswordValidtion = (password: string) => {
    if (!password) {
      this.setState({
        validPass: false,
        isCheckPassword: false,
        enableBtn: true
      });
    } else {
      this.setState({
        validPass: true,
        isCheckPassword: false,
        enableBtn: true
      });
    }
    if (password === this.state.employeeData.empConfirmPass) {
      this.setState({
        enableBtn: false
      })
    }
  };

  handleOpenModal = () => {
    this.setState({ openModal: true });
  };

  forgetPassword = async () => {
    { this.setState({ Loader: true,PersonEmail:this.state.inputData.email}) }
    const header = {
      "Content-Type": configJSON.loginApiContentType,
    };

    const data = {
      email: this.state.inputData.email,
    };
    await setStorageData("resetEmail", this.state.inputData.email);
    const httpBody = {
      data,
    };

    const requestMessageForget = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiOtpCallId = requestMessageForget.messageId;
    requestMessageForget.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.forgetPasswordEndPoint
    );

    requestMessageForget.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessageForget.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessageForget.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.loginAPiMethod
    );

    runEngine.sendMessage(requestMessageForget.id, requestMessageForget);

  };
  forgetPassword2 = async () => {
    const invitedBycompanyCheck = await getStorageData("email")
    const header = {
      "Content-Type": configJSON.loginApiContentType,
    };

    const data = {
      email: invitedBycompanyCheck,
    };
    await setStorageData("resetEmail", invitedBycompanyCheck);
    const httpBody = {
      data,
    };

    const requestMessageForget = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiOtpCallId = requestMessageForget.messageId;
    requestMessageForget.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.forgetPasswordEndPoint
    );

    requestMessageForget.addData(  getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessageForget.addData(  getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessageForget.addData(  getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.loginAPiMethod
    );

    runEngine.sendMessage(requestMessageForget.id, requestMessageForget);

  };


  confirmPasswordValidation = (cpass: string, pass: string) => {
    this.setState({
      formData: {
        ...this.state.formData,
        reTypePassword: cpass,
      },
    });
    if (cpass && pass && pass !== cpass) {
      this.setState({
        confirmPass: true,
        isCheckPassword: false
      });
    } else if (cpass && pass && cpass.length > 0) {
      this.setState({
        confirmPass: false,
        isCheckPassword: true
      });
    } else {
      this.setState({ isCheckPassword: false })
    }
  };
  handleFormData = (e: { target: { name: string, value: string } }) => {
    if (e?.target?.name === "password") {
      this.handlePasswordError(e.target.value);
      this.handlePasswordValidtion(e.target.value);
    } else if (e?.target?.name === "reTypePassword") {
      this.confirmPasswordValidation(
        e.target.value,
        this.state.formData.password
      );
    } else {
      this.setState(
        (prev) => ({
          ...prev,
          formData: {
            ...prev.formData,
            [e?.target?.name]: e?.target?.value,
          },
        }),
      );
    }
  };

  handleBackClick = () => {
    this.setState({navigateCheck : 1})
  }


  handlePasswordError = (password: string) => {
    let formErrorData: any = this.state.formErrorData;
    if (password.length > 0 && password) {
      let alphabet = this.checkAlphabet.test(password);
      let capitalChar = this.checkCapital.test(password);
      let number = this.checkDigit.test(password);
      let specialSign = this.checkSpecialChar.test(password);
      formErrorData = {
        ...formErrorData,
        password: {
          alphabet: alphabet ? "false" : "true",
          capitalChar: capitalChar ? "false" : "true",
          minimumChar: password.length >= 8 ? "false" : "true",
          number: number ? "false" : "true",
          specialSign: specialSign ? "false" : "true",
        },
      };
    } else {
      formErrorData = {
        ...formErrorData,
        password: {
          alphabet: "true",
          capitalChar: "true",
          minimumChar: "true",
          number: "true",
          specialSign: "true",
        },
      };
    }
    this.setState({
      ...this.state,
      formData: { ...this.state.formData, password: password },
      formErrorData: formErrorData,
    });
  };

  handleChecked = (
    type: "alphabet" | "number" | "capitalChar" | "specialSign" | "minimumChar"
  ) => {
    return this.state?.formErrorData?.password?.[type] === "false"
      ? true
      : false;
  };
  handlePasswordShow = () => {
    this.setState({
      enablePasswordField: !this.state.enablePasswordField,
    });
  };
  handleConfirmPasswordShow = () => {
    this.setState({
      enableReTypePasswordField: !this.state.enableReTypePasswordField,
    });
  };

    
  // Customizable Area End
  }