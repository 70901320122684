import * as React from "react";
import  { useEffect, useState, useRef,ChangeEvent } from "react";
import { ViewProps } from "./StripeWrapper.web";
import {
  Elements,
  useElements,
  useStripe,
  CardNumberElement, CardExpiryElement, CardCvcElement,
} from "@stripe/react-stripe-js";
import { Message } from "../../framework/src/Message";
import MessageEnum, {
  getName,
} from "../../framework/src/Messages/MessageEnum";
import {useRunEngine } from "../../blocks/utilities/src/hooks/useRunEngine";
import { useBlockHelpers } from "../../blocks/utilities/src/hooks/useBlockHelpers";
import { getStorageData } from "../../framework/src/Utilities";
import { Dialog, Box, Typography, Button, IconButton, TextField, } from '@material-ui/core';
import { styled } from '@material-ui/styles';
import { Close } from "@mui/icons-material";
import { toast } from "react-toastify";

const subscribedMessages = [
  MessageEnum.RestAPIResponceMessage,
  MessageEnum.SessionResponseMessage,
];

const StripePaymentsView: React.FC<ViewProps> = ({
  stripePromise,
  open,
  onClose,
  payAmount,
  payId,
  payType,
  payPlan,
  goToLoginScreen,
  email,
  payment_id,
  type,
  price,
  handleFailDialogBox,
  handleSuccessDialogBox,
  handleSuccessDialogBox2,
}) => {
  const Render: React.FC<ViewProps> = ({
  }) => {
    const {
      sendBlockMessage,
      sendNetworkRequest,
      setReceiveCallback,
      subscribe,
      unsubscribeFromMessage,
    } = useRunEngine();

    const { extractNetworkResponse } = useBlockHelpers();
    const stripe = useStripe();
    const stripeElements = useElements();
    const addstripeCallId = useRef<string>("");
    const [errorMessage, setErrorMessage] = useState(null);
    const [isProcessing, setProcessing] = useState(false);
    const [cardNumberComplete, setCardNumberComplete] = useState(false);
    const [cardExpiryComplete, setCardExpiryComplete] = useState(false);
    const [cardCvcComplete, setCardCvcComplete] = useState(false);
    const [checkoutError, setCheckoutError] = useState("");
    const [userAppAuthenticationToken, setUserAppAuthenticationToken] = useState<string | undefined >(undefined);
  const [cardholderName, setCardholderName] = useState(""); 

  const handleInputChange = (event:ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    if (name === "cardholderName") setCardholderName(value);
  };

    const getToken = () => {
      const message: Message = new Message(
        getName(MessageEnum.SessionRequestMessage)
      );
      sendBlockMessage(message);
    };
  
    const restoreSessionFromLocalStorage = () => {
      const persistedAuthToken = getStorageData("Token", false);
      if (persistedAuthToken) {
        const messsage: Message = new Message(
          getName(MessageEnum.SessionSaveMessage)
        );
        messsage.addData(
          getName(MessageEnum.SessionResponseToken),
          persistedAuthToken
        );
        sendBlockMessage(messsage);
      }
    };

    const receive = async(from: string, message: Message) => {
      if (getName(MessageEnum.SessionResponseMessage) === message.id) {
        let resToken = message.getData(getName(MessageEnum.SessionResponseToken));
        if (resToken) {
          setUserAppAuthenticationToken(resToken);
        } else {
          restoreSessionFromLocalStorage();
          let resToken = message.getData(
            getName(MessageEnum.SessionResponseToken)
          );
          setUserAppAuthenticationToken(resToken);
        }
      } else if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
        const { apiRequestCallId, responseJson } = extractNetworkResponse(message);
        if(responseJson.message === "Payment successful."){
          setProcessing(false);
          handleSuccessDialogBox()
          if (handleSuccessDialogBox2) {
            handleSuccessDialogBox2(responseJson.buycredit_subscription_id);
          }
          localStorage.setItem("buycredit_subscription_id",responseJson.buycredit_subscription_id)
        }else if(responseJson.error){
          setProcessing(false);
          handleFailDialogBox()
        }
        if (apiRequestCallId === addstripeCallId) {
        }
      }
    };
    const handleSubmit = async (event:any) => {
      event.preventDefault();
      setProcessing(true);

      if (!stripe || !stripeElements) {
          // Stripe.js hasn't yet loaded.
          return;
      }
      // Retrieve card details from stripeElements
      const cardElement:any = stripeElements.getElement(CardNumberElement);
      try {
          const { token, error } = await stripe.createToken(cardElement);
          
          if (error) {
              // setCheckoutError(error.message);
              toast.error(error.message)
              setProcessing(false);
              return;
          }
          const stripeAuthToken = token?.id
          if(stripeAuthToken ){
            sendNetworkRequest(
              addstripeCallId,
              'POST',
              'bx_block_stripe_integration/payment_intents/create_checkout_session',
              {
                "Content-Type": "application/json",
              },
              {
                type:payType,
                subscription_id: payId ?payId: payment_id ,
                email: email,
                data: {
                token_card: stripeAuthToken 
                }
              }
            );
          }
        } catch (err) {    
        //@ts-ignore
          toast.error(err.message)
      }

    };
    const handleCardDetailChange = (event:any) => {
      if (event.error) {
          setErrorMessage(event.error.message);
      } else {
          setErrorMessage(null);
      }

      if(event.elementType === 'cardNumber') {
          setCardNumberComplete(event.complete);
      }

      if(event.elementType === 'cardExpiry') {
          setCardExpiryComplete(event.complete);
      }

      if(event.elementType === 'cardCvc') {
          setCardCvcComplete(event.complete);
      }
    };
    const formatPrice = (price: string) => {
      const numericPrice = parseFloat(price);
      const [integerPart, decimalPart] = price.split(".");
    
      if (numericPrice % 1 === 0) {
        return `${Math.floor(numericPrice)}`;
      } else if (decimalPart && decimalPart.length === 1) {    
        return numericPrice.toFixed(1);
      } else {   
        return numericPrice.toFixed(2);
      }
    };

    useEffect(() => {
      setReceiveCallback(receive);
      subscribedMessages.forEach((message) => subscribe(message));
        getToken();
        return () => {
          subscribedMessages.forEach((message) => unsubscribeFromMessage(message));
        };
    }, []);
   
    return (
      <StyledDialog
      open={open}
      onClose={onClose}
      aria-labelledby="success-dialog-title"
      aria-describedby="success-dialog-description"
    >
      <form onSubmit={handleSubmit}>
      <ContainerPayOne>
        <Box className="PaymentDetails">Payment Details</Box>
        <IconButton className="closeButton" onClick={onClose}>
          <Close />
        </IconButton>
      </ContainerPayOne>
      <PlanAmount>
        <div>
          <Typography variant="body1">Plan</Typography>
          <Typography variant="body2">{payPlan}</Typography>
        </div>
        <Typography variant="h6">
        {formatPrice(payAmount)}
        </Typography>
      </PlanAmount>
      <ContainerPaytwo>
        <Box className="formBox">
          <StyledLabel>Card Number</StyledLabel>
          <CardNumberElement 
            onChange={handleCardDetailChange}/>
          <StyledLabel>Cardholder Name</StyledLabel>
          <StyledTextField
            type="text"
            name="cardholderName"
            value={cardholderName}
            onChange={handleInputChange}
            required
            fullWidth
            placeholder="Cardholder Name"
          />
          <Box display="flex" justifyContent="space-between">
            <Box width="48%">
              <StyledLabel>Expiry Date</StyledLabel>
              <CardExpiryElement 
              onChange={handleCardDetailChange}/>
            </Box>
            <Box width="48%">
              <StyledLabel>CVV</StyledLabel>
              <CardCvcElement
                onChange={handleCardDetailChange}
              />
            </Box>
          </Box>
        </Box>
        <StyledLineHorizontal />
        <TotalAmount>
          <Typography variant="body1">Total Amount</Typography>
          <Typography variant="h6">
            {formatPrice(payAmount)}
          </Typography>
        </TotalAmount>
      </ContainerPaytwo>
      <ContainerPaythree>
        <Box className="payNowButton">
          <Button type="submit" disabled={!stripe || isProcessing} className="ButtonStyle" >
          {isProcessing ? "Processing..." : "Pay"}
          </Button>
        </Box>
      </ContainerPaythree>
        </form>
      </StyledDialog>
    );
  };

  const viewProps: ViewProps = {
    stripePromise: stripePromise,
    open: open,
    goToLoginScreen:goToLoginScreen,
    onClose:onClose,
    payAmount:payAmount,
    payId:payId,
    payType:payType,
    payPlan:payPlan,
    email:email,
    payment_id:payment_id,
    type:type,
    price:price,
    handleFailDialogBox:handleFailDialogBox,
    handleSuccessDialogBox: handleSuccessDialogBox,
    handleSuccessDialogBox2: handleSuccessDialogBox2, 
  };

  return (
    <Elements
      stripe={stripePromise||null}
    >
      <Render {...viewProps} />
    </Elements>
  );
};

const StyledDialog = styled(Dialog)({
  "& .MuiDialog-paperWidthSm": {
    background: "#525252",
    width: "32vw",
    padding: "12px",
    height:"74vh",
    overflowX:"hidden"
  },
  "& .StripeElement" :{
    display: "block",
    margin: "10px 0 20px 0",
    padding: "16px 14px",
    fontSize: "1em",
    fontFamily: "Lato",
    borderBottom: "2px solid #e2e8f0",
    outline: 0,
    background: "transparent",
    border: "1px solid rgba(211, 211, 211, 1)",
    borderRadius: "4px",
  },
  "& .InputElement.is-invalid.Input": {
    color: "white"
  }
});

const ContainerPayOne = styled(Box)({
  padding: "30px",
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  borderBottom: "1px solid rgb(124, 124, 124, 1)",
  margin: "0px -11px",
  "& .PaymentDetails": {
    width: "462px",
    height: "28px",
    fontFamily: "Lato",
    color: "rgba(255, 255, 255, 1)",
    fontSize: "20px",
    fontWeight: 700,
    lineHeight: "28px",
  },
  "& .closeButton": {
    width: "32px",
    height: "32px",
    color: "white",
  },
});
const ContainerPaytwo = styled(Box)({
  padding: "30px 30px 0px 30px",
  alignItems: "center",
});

const ContainerPaythree = styled(Box)({
  "& .payNowButton": {

    justifyContent: "center",
    display: "flex",
    alignItems: "center",
    margin: "15px",
  },
  "& .ButtonStyle": {
    height: "50px",
    width:"100%",
    backgroundColor: "rgba(0, 255, 0, 1)",
    fontFamily: "Lato",
    color: "rgba(36, 36, 36, 1)",
    fontSize: "16px",
    lineHeight: "24px",
    fontWeight: 700,
  },
});

const PlanAmount = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  padding: "15px 30px",
  borderBottom: "1px solid rgb(124, 124, 124, 1)",
  paddingBottom: "10px",
  "& .MuiTypography-body1": {
    color: "rgba(211, 211, 211, 1)",
    fontFamily: "Lato",
    fontSize: "18px",
    fontWeight: 400,
    lineHeight: "26px",
  },
  "& .MuiTypography-body2": {
    color: '#FFF',
    fontFamily: 'Lato',
    fontSize: '22px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '18px'
  },
  "& .MuiTypography-h6": {
    color: "rgba(255, 255, 255, 1)",
    fontFamily: "Lato",
    fontSize: "20px",
    fontWeight: 700,
    lineHeight: "28px",
  },
});
const StyledLineHorizontal = styled(Box)({
  backgroundColor: "rgba(124, 124, 124, 1)",
  width: "100%",
  height: "1px",
  marginBottom: "20px"
});

const StyledTextField = styled(TextField)({
  marginBottom: "20px",
  color: '#D3D3D3',
  fontFamily: 'Lato',
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: 600,
  "& .MuiInputBase-root": {
    backgroundColor: "rgba(80, 80, 80, 1)",
    border: "1px solid rgba(211, 211, 211, 1)",
    borderRadius: "4px",
    padding: "10px",
    color: '#D3D3D3',
    fontFamily: 'Lato',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 600,
    "& .MuiInputBase-input::placeholder": {
      color: '#D3D3D3',
      fontFamily: 'Lato',
      fontSize: '14px',
      fontStyle: 'normal',
      fontWeight: 600,
    }
  }
});

const StyledLabel = styled(Typography)({
  color: "rgba(211, 211, 211, 1)",
  fontFamily: "Lato",
  fontSize: "16px",
  fontWeight: 700,
  lineHeight: "20px",
  marginBottom: "5px",
});

const TotalAmount = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  marginTop: "20px",
  "& .MuiTypography-body1": {
    color: "rgba(211, 211, 211, 1)",
    fontFamily: "Lato",
    fontSize: "18px",
    fontWeight: 400,
    lineHeight: "26px",
  },
  "& .MuiTypography-h6": {
    color: "rgba(255, 255, 255, 1)",
    fontFamily: "Lato",
    fontSize: "20px",
    fontWeight: 700,
    lineHeight: "28px",
  },
});
export default StripePaymentsView;
