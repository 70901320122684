import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData } from "../../../../packages/framework/src/Utilities";
// Customizable Area End

export const configJSON = require("./config");

// Customizable Area Start
export interface ITermsConds {
  id: string;
  description: string;
  is_accepted: boolean;
  created_at: string;
}

interface ITCcondApi {
  data: {
    id: string;
    type: string;
    attributes: {
      description:string,
      title: string,
      created_at: string
    };
  }[];
}

// Customizable Area End
export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  token: string;
  isAdminUser: boolean;
  accountId: number;
  termsConds: ITermsConds | null;
  termsCondsList: ITermsConds[];
  isTermsCondsAccepted: boolean;
  isLoading: boolean;
  checked:boolean;
  termsConstionsList: {
    description: string,
    title: string,
    created_at: string
   },
   role:string;
   isDialogOpen:boolean;
   anchorEl: HTMLButtonElement | null;
   paymentPrice:string;
   successDialogOpen:boolean;
  failDialogOpen:boolean;
  paymentType:string;
  subscriptionName:string;
  // Customizable Area End
}

interface SS {
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export default class TermsConditionsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getTermsCondsCallId: string = "";
  getTermsCondsListCallId: string = "";
  getAccountGroupsCallId: string = "";
  setAcceptanceOfTermsCondsId: string = "";
  getTermsConsditionsId:string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      token: "",
      accountId: -1,
      termsCondsList: [],
      isAdminUser: true,
      termsConds: null,
      isTermsCondsAccepted: false,
      isLoading: false,
      checked:false,
      termsConstionsList: {
        description: "",
        title: "",
        created_at: ""
       },
       role:"",
       isDialogOpen:false,
       anchorEl: null,
       paymentPrice:"",
       successDialogOpen:false,
       failDialogOpen:false,
       paymentType:"",
       subscriptionName:""
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
    this.getTermsConditions();
    const roleType = (await getStorageData("user_type")) || (await getStorageData("role"));
    const paymentPrice = await getStorageData("payment_price");
    const paymentType = await getStorageData("payment_type");   
    const tokenId = await getStorageData("token");
    const subscriptionName = await getStorageData("payment_name");

    this.setState({role : roleType,paymentPrice:paymentPrice,paymentType:paymentType,subscriptionName:subscriptionName})
    this.getToken();
    if (!this.isPlatformWeb()) {
      this.props.navigation.addListener("willFocus", () => {
        this.getToken();
      });
    }
  }

  getToken = () => {
    const message: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(message);
  };



  goToLoginScreen = () => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), "LandingPage");
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  };

  handlePaymentDialogBox = () =>{
    this.setState({ isDialogOpen:true });     
  }

  handleCancel = () => {
    this.setState({ anchorEl: null,isDialogOpen:false,failDialogOpen:false,successDialogOpen:false});
  };

  handleSuccessDialogBox = () =>{
    this.setState({ isDialogOpen:false,successDialogOpen:true,failDialogOpen:false });
  }
  handleFailureDialogBox = () =>{
    this.setState({ isDialogOpen:true,successDialogOpen:false,failDialogOpen:false });
  }
  handleFailDialogBox = () =>{
    this.setState({ failDialogOpen:true ,isDialogOpen:false});
  }

  handleButtonClick = () => {
    this.naviagateLogin()
  };

  naviagateLogin=()=>{
    const msg: Message = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationTargetMessage), "EmailAccountLoginWeb");
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  navigateToTermsCondsDetail = (termsCondsId: string) => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "TermsConditionsDetail"
    );

    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);

    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationPayLoadMessage)
    );
    raiseMessage.addData(getName(MessageEnum.SessionResponseData), {
      termsCondsId: termsCondsId,
    });
    message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);

    this.send(message);
  };

  navigateToTermsCondsEdit = () => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "TermsConditionsEdit"
    );

    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);

    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationPayLoadMessage)
    );
    raiseMessage.addData(getName(MessageEnum.SessionResponseData), null);
    message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);

    this.send(message);
  };

  getAccountGroups = async (token: string) => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getAccountGroupsCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getAccountGroupsApiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    this.setState({ isLoading: true });
  };

  getTermsCondsList = async (token: string) => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getTermsCondsListCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getAllTermsCondsApiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getTermsConds = async (token: string) => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getTermsCondsCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getTermsCondsApiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getTermsConditions = async () => {
    const role_type = (await getStorageData("user_type")) || (await getStorageData("role"));
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getTermsConsditionsId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getTermsCondsApiEndPoint}?role_name=${role_type}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleCheckBoxChange = (value: boolean) => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: this.state.token,
    };
    const body = {
      id: this.state.termsConds?.id,
      is_accepted: value,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.setAcceptanceOfTermsCondsId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.setTermsCondsAcceptanceApiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleCheckboxClick = (e: any) => {
    this.setState({
      ...this.state,
      checked: !this.state.checked,
    });
  };
  handleBackButtonClick = () => {
    this.props.navigation.goBack()
}
getTermsCondsCallIdAPIResponse = (responseJson: ITCcondApi) => {
  const attributes = responseJson.data[0]?.attributes;  
  this.setState({
    termsConstionsList: {
      ...this.state.termsConstionsList,
      description: attributes?.description,
      title: attributes?.title,
      
    },
  });
};

handleErrorResponse=(responseJson:any)=>{
  if (responseJson?.errors) {
    this.handleFailDialogBox()
  }
}


  // Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    const errorResponse = message.getData(
      getName(MessageEnum.RestAPIResponceErrorMessage)
    );
    if (errorResponse) this.handleFailDialogBox()
      this.handleErrorResponse(responseJson)

    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      const token: string = message.getData(
        getName(MessageEnum.SessionResponseToken)
      );
      if (1) {
        runEngine.debugLog("TOKEN", token);
        const messageData = JSON.parse(
          message.getData(getName(MessageEnum.SessionResponseData))
        );
        const accountId: number = messageData?.meta?.id;
        this.setState({ accountId });

        this.setState({ token, accountId }, () => this.getAccountGroups(token));
      } else {
        this.props.navigation.goBack();
        return alert("Please Login");
      }
    }

    if (
      responseJson &&
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getTermsCondsListCallId.length > 0 &&
      apiRequestCallId === this.getTermsCondsListCallId
    ) {
      this.getTermsCondsListCallId = "";
      this.setState({
        termsCondsList: responseJson.data,
        isLoading: false,
      });
    }

    if (
      responseJson &&
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getTermsCondsCallId.length > 0 &&
      apiRequestCallId === this.getTermsCondsCallId
    ) {
      this.getTermsCondsCallId = "";
      this.setState({
        termsConds: responseJson,
        isTermsCondsAccepted: responseJson.is_accepted,
        isLoading: false,
      });
    }

    if (apiRequestCallId === this.getTermsConsditionsId ) {
      { this.getTermsCondsCallIdAPIResponse(responseJson) }
    }

    if (
      responseJson &&
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.setAcceptanceOfTermsCondsId.length > 0 &&
      apiRequestCallId === this.setAcceptanceOfTermsCondsId
    ) {
      this.setAcceptanceOfTermsCondsId = "";
      this.setState({
        isTermsCondsAccepted: !this.state.isTermsCondsAccepted,
      });
    }

    if (
      responseJson?.data &&
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getAccountGroupsCallId.length > 0 &&
      apiRequestCallId === this.getAccountGroupsCallId
    ) {
      this.getAccountGroupsCallId = "";
      const isAdminUser = responseJson.data.some(
        (group: { attributes: { accounts: [] } }) =>
          group.attributes.accounts.some(
            (account: { id: number; role_id: number | null }) =>
              account.id === this.state.accountId && account.role_id === 1
          )
      );

      this.setState({ isAdminUser: isAdminUser });
      if (isAdminUser) {
        this.getTermsCondsList(this.state.token);
      } else {
        this.getTermsConds(this.state.token);
      }
    }
    // Customizable Area End
  }
}
